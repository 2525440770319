<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="poi-modal">
      <div class="poi-modal--header font-weight-light">
        <span v-if="enabled">Sortir du mode POI </span>
        <span v-else>Activer le mode POI</span>
      </div>
      <div class="poi-modal--content">
        <div class="poi-modal--content-header">
          (Attention)
        </div>
        <div v-if="!enabled" class="poi-modal--content-text">
          <p>
            Vous allez passer <strong>{{ siteName }}</strong> en mode POI :
          </p>
          <ul>
            <li>Toutes les installations physiques vont être débloquées</li>
            <li>
              Les personnes présentes n’auront pas besoin d’utiliser leur badge pour valider leur sortie
            </li>
          </ul>
        </div>
        <div v-if="enabled" class="poi-modal--content-text">
          <p>
            Vous allez sortir du mode POI pour <strong>{{ siteName }}</strong
            >:
          </p>
          <ul>
            <li>
              Les agents devront à nouveau utiliser leur badge pour valider leur entrée ou sortie
            </li>
          </ul>
        </div>
        <v-alert v-if="!enabled" type="info" class="poi-modal--content-text">
          <p>
            Suite à l'activation du mode POI, un mail contenant la liste des personnes présentes sur ce chantier sera envoyé aux adresses emails paramètrées
          </p>
        </v-alert>
      </div>
      <div class="poi-modal--buttons d-flex justify-end">
        <v-btn class="poi-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="poi-modal--buttons-save" color="#38BB00" dark depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'POIModal',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    enabled() {
      return this.modal.payload.enabled;
    },
    siteName() {
      return this.modal.payload.siteName;
    }
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit();

        this.close();
      }
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.poi-modal {
  .theme--light.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate) .v-icon {
    color: #3dd598 !important;
  }

  &--header {
    text-align: center;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 343px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-header {
      color: #ff3612;
      margin-top: 15px;
    }

    &-text {
      text-align: left;
      margin: 25px 30px 0 30px;
    }

    &-checkbox {
      margin: 40px 30px 0 30px;
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
