<template>
  <div>
    <v-data-table
      fixed-header
      :headers="headers"
      :items="logs"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [50, 100, 200, 500, -1]
      }"
      height="600"
      class="logs-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:item.createdAt="{ item }">
        {{ formatDateHourMinute(item.createdAt) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatValues } from '@/mixins/formatValues.mixin';

export default {
  name: 'LogsTable',
  mixins: [formatValues],
  props: {
    logs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sortBy: ['createdAt'],
      sortDesc: true
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Date",
          divider: true,
          align: 'start',
          sortable: true,
          width: '150px',
          value: 'createdAt'
        },
        {
          text: "Utilisateur",
          divider: true,
          align: 'start',
          sortable: true,
          width: '150px',
          value: 'User.email'
        },
        {
          text: "Action",
          divider: true,
          align: 'start',
          sortable: true,
          width: '100%',
          value: 'action'
        }
      ];
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.logs-table {
  table-layout: fixed;

  .show-modal {
    color: #0094ff;
    text-decoration: underline;
  }

  .v-data-table-header__icon {
    display: none;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
