<template>
  <div class="agents-page">
    <div style="width: 100%">
      <v-text-field v-model="searchQuery" class="filterSearch" prepend-icon="mdi-magnify" label="Rechercher" clearable></v-text-field>
    </div>
    <AgentsTable
      v-if="list.length"
      :data="filteredList"
      :profiles="sortedProfiles"
      :enterprises="sortedEnterprises"
      @add="addItem($event)"
      @edit="changeItemById($event)"
      @remove="removeItems($event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { ROUTES_NAME } from '@/common/constants';
import AgentsTable from '@/components/common/tables/AgentsTable';

export default {
  name: ROUTES_NAME.agents,
  components: {
    AgentsTable
  },
  data() {
    return {
      searchQuery: null
    };
  },
  computed: {
    ...mapGetters('agents', ['list', 'profiles', 'enterprises']),
    sortedProfiles() {
      return [...this.profiles].sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedEnterprises() {
      return [...this.enterprises].sort((a, b) => a.name.localeCompare(b.name));
    },
    filteredList() {
      if (this.list?.length > 0) {
        if (this.searchQuery) {
          return [...this.list].filter(
            agent =>
              agent.firstname.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
              agent.lastname.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
              agent.enterpriseName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
              agent.profileName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
              agent.badge?.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        }
        return this.list;
      }
      return [];
    }
  },
  mounted() {
    this.fetchList();
    this.getAgentProfiles();
    this.getAgentEnterprises();
  },
  methods: {
    ...mapActions('agents', [
      'fetchList',
      'removeItems',
      'addItem',
      'changeItemById',
      'getAgentProfiles',
      'getAgentEnterprises'
    ]),
  }
};
</script>

<style lang="scss">
.agents-page {
  padding: 0 10px;
}
</style>
