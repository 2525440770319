<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="change-access-modal">
      <div class="change-access-modal--header font-weight-light">
        {{ $t(`modals.access.${modalType}.title`) }}
      </div>
      <div class="change-access-modal--content">
        <div class="change-access-modal--content-input d-flex justify-center">
          <v-form class="change-access-modal--content-input-form">
            <v-container>
              <v-text-field v-model="formData.beginDate" :label="'Date d’obtention'" type="date" :rules="beginDateRules" outline>
              </v-text-field>
              <v-text-field v-model="formData.endDate" :label="'Date de fin de validité'" type="date" :rules="endDateRules" outline> </v-text-field>
            </v-container>
          </v-form>
        </div>
      </div>
      <div class="change-access-modal--buttons d-flex justify-end">
        <v-btn class="change-access-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="change-access-modal--buttons-save" color="#38BB00" :disabled="isDisabled" dark depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import moment from 'moment';

import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'ChangeAccessModal',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        beginDate: null,
        endDate: null
      },
      type: 1
    };
  },
  computed: {
    title() {
      return this.modal.payload.text.title;
    },
    oldValues() {
      return this.modal.payload.oldValues;
    },
    modalType() {
      const modal = ['edit', 'new'];
      return modal[this.type];
    },
    beginDateRules() {
      return [
        v =>
          this.formData.beginDate === null
            ? true
            : moment(v).format('YYYYMMDD') < moment(this.formData.endDate).format('YYYYMMDD') || "La date de début est invalide"
      ];
    },
    endDateRules() {
      return [v => new Date(v) > new Date(this.formData.beginDate) || "La date de fin est invalide"];
    },
    isDisabled() {
      if (this.formData.beginDate === null) {
        return true;
      }

      if (this.formData.endDate === null) {
        return true;
      }

      if (moment(this.formData.beginDate).format('YYYYMMDD') >= moment(this.formData.endDate).format('YYYYMMDD')) {
        return true;
      }

      return false;
    }
  },
  mounted() {
    if (this.oldValues) {
      this.type = 0;
      this.formData.beginDate = moment(this.oldValues.beginDate).format('YYYY-MM-DD');
      this.formData.endDate = moment(this.oldValues.endDate).format('YYYY-MM-DD');
    }
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit({
          beginDate: this.formData.beginDate,
          endDate: this.formData.endDate
        });

        this.close();
      }
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.change-access-modal {
  &--header {
    text-align: center;
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 490px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
