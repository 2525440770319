import { TOKEN_STORAGE_KEY } from '@/common/constants';

export function requestHandler(request) {
  const accessToken = localStorage.getItem(TOKEN_STORAGE_KEY.accessToken);
  const siteId = localStorage.getItem('siteId');
  // siteId qui permet de définir le site sélectionné.
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  if (siteId) {
    request.headers['siteId'] = siteId;
  }
  return request;
}
