<template>
  <div class="pageContainer pa-6 ma-2">
    <div id="controlsContainer" class="d-flex flex-row justify-center mb-2">
      <v-select v-model="professionId" :items="[{ id: '', label: 'Toutes les professions' }, ...list]" item-value="id" item-text="label"> </v-select>
    </div>
    <div v-show="!loading">
      <div v-show="datasets[0].data.length > 2" id="radarChartContainer">
        <canvas id="radarChart" ref="radarChart"></canvas>
      </div>

      <p v-show="datasets[0].data.length < 3" class="mx-auto mt-6 text-center notEnough">
        Pas assez de données disponibles pour afficher un graphe <br />
        <small>Essayez de changer les dates, quiz, ou formations sélectionnés</small>
      </p>
    </div>
    <div v-show="loading">
      <div class="d-flex justify-center ">
        <v-progress-circular :indeterminate="true" class="mx-auto"></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';
import { mapGetters, mapActions } from 'vuex';

import { getProfessions } from '../../../services/statsFormations.service';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

export default {
  props: {
    generalParams: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      professionId: '',
      loading: false,
      chart: null,
      labels: [],
      datasets: [
        {
          type: 'radar',
          label: 'Moyenne des notes',
          data: [],
          fill: true,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgb(255, 99, 132)',
          pointBackgroundColor: 'rgb(255, 99, 132)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(255, 99, 132)'
        }
      ]
    };
  },
  computed: {
    professionParams() {
      return this.professionId ? this.generalParams.concat(`professionId=${this.professionId}`) : this.generalParams;
    },
    ...mapGetters('professions', ['list'])
  },
  watch: {
    professionParams(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== '') {
        this.fetchProfessionStats();
      }
    },
    datasets: {
      immediate: true,
      deep: true,
      handler() {
        this.updateChart();
      }
    }
  },
  mounted() {
    this.fetchProfessionStats();
    this.fetchList();
    // this.updateChart();
  },
  methods: {
    ...mapActions('professions', ['fetchList']),
    async fetchProfessionStats() {
      this.loading = true;
      const professions = await getProfessions(this.professionParams);
      this.labels = professions.map(
        profession =>
          ` ${profession.nbAgent} ${profession.label} (${((profession.nbAgent * 100) / professions.reduce((sum, { nbAgent }) => sum + nbAgent, 0)).toFixed(
            2
          )}%)`
      );
      this.datasets[0].data = professions.map(profession => profession.averagePerc);
      this.loading = false;
    },
    updateChart() {
      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(this.$refs.radarChart, {
        data: {
          datasets: this.datasets,
          labels: this.labels
        },
        options: {
          scales: {
            r: {
              min: 0,
              max: 100
            }
          }
        }
      });
    }
  }
};
</script>

<style>
#radarChart {
  max-height: 50vh;
  height: 100%;
}

.notEnough {
  width: max-content;
}
</style>
