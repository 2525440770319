import request from '@/services/helpers/request';
import { API_ROUTES } from '@/config';

export const getAllAgents = async () => (await request.getInstance()).get(API_ROUTES.sessionAgents.getAllAgents);
export const getBySessionId = async id => (await request.getInstance()).get(`${API_ROUTES.sessionAgents.getBySessionId}/${id}`);
export const getDeviceTokenById = async id => (await request.getInstance()).get(API_ROUTES.sessionAgents.getDeviceToken.replace(':sessionAgentId', id));
export const getAgentAnswers = async id => (await request.getInstance()).get(API_ROUTES.sessionAgents.getAgentAnswers.replace(':sessionAgentId', id));
export const setBadge = async (id, data) => (await request.getInstance()).post(API_ROUTES.sessionAgents.setBadge.replace(':sessionAgentId', id), data);
export const addComment = async (id, data) => (await request.getInstance()).post(API_ROUTES.sessionAgents.addComment.replace(':id', id), data);
export const saveFinalScore = async (id, data) => (await request.getInstance()).post(API_ROUTES.sessionAgents.saveFinalScore.replace(':id', id), data);
