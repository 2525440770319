<template>
  <div id="statsContainer">
    <v-data-table
      id="statsTable"
      fixed-header
      :headers="defaultHeaders"
      :items="formattedAnomalies"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      }"
      height="600"
    >
      <template v-slot:item.fixAnomalies="{ item }">
        <a class="text-decoration-underline" @click.stop.prevent="fixAnomaly(item)">
          Corriger
        </a>
      </template>
      <template v-slot:item.enterDate="{ item }">
        <span :class="item.wrongedField === 'enterDate' ? 'red--text' : ''">
          {{ item.enterDate }}
        </span>
      </template>
      <template v-slot:item.leaveDate="{ item }">
        <span :class="item.wrongedField === 'leaveDate' ? 'red--text' : ''">
          {{ item.leaveDate }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { json2excel } from 'js2excel';
import moment from 'moment';

export default {
  props: {
    anomalies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchQuery: ''
    };
  },
  computed: {
    formattedAnomalies() {
      if (this.anomalies?.length > 0) {
        return this.anomalies.map(anomalie => ({
          ...anomalie,
          enterDate: moment(anomalie.enterDate).format('HH:mm DD/MM/YYYY'),
          leaveDate: moment(anomalie.leaveDate).format('HH:mm DD/MM/YYYY'),
          wrongedField: this.getWrongedField(anomalie)
        }));
      }
      return [];
    },
    defaultHeaders() {
      return [
        {
          text: 'Nom',
          divider: true,
          align: 'start',
          sortable: true,
          width: '13%',
          value: 'Agent.lastname'
        },
        {
          text: 'Prénom',
          divider: true,
          align: 'start',
          sortable: true,
          width: '13%',
          value: 'Agent.firstname'
        },
        {
          text: 'Site',
          divider: true,
          align: 'start',
          sortable: true,
          width: '13%',
          value: 'Site.name'
        },
        {
          text: 'Entreprise',
          divider: true,
          align: 'start',
          width: '13%',
          sortable: true,
          value: 'Agent.Enterprise.name'
        },
        {
          text: 'Entrée à',
          divider: true,
          align: 'start',
          width: '13%',
          sortable: true,
          value: 'enterDate'
        },
        {
          text: 'Sortie à',
          divider: true,
          align: 'start',
          width: '13%',
          sortable: true,
          value: 'leaveDate'
        },
        {
          text: '',
          divider: true,
          align: 'start',
          width: '8%',
          sortable: true,
          value: 'fixAnomalies'
        }
      ];
    }
  },
  methods: {
    getWrongedField(anomalie) {
      if (moment(anomalie.enterDate).isSame(moment(anomalie.enterDate).startOf('D'), 'm')) {
        return 'enterDate';
      }
      if (moment(anomalie.leaveDate).isSame(moment(anomalie.leaveDate).endOf('D'), 'm')) {
        return 'leaveDate';
      }
      return false;
    },
    exportData() {
      const hoursList = this.hours.map(item => {
        const hour = [
          ['Entreprise', item.entreprise.name],
          ['Site', item.site.name],
          ['Heures Passées', item.totalTimeSpent / 3600]
        ];
        return Object.fromEntries(hour);
      });

      try {
        json2excel({
          data: hoursList,
          name: 'hours-list'
        });
      } catch (e) {
        console.error('export error', e);
      }
    },

    fixAnomaly(e) {
      this.$modals.open({
        name: 'FixAnomalyModal',

        payload: {
          text: { title: 'Corriger une anomalie' },
          oldValues: { ...e },
          submit: data => {
            this.$emit('fixAnomaly', { data, id: data.id });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
#statsContainer {
  margin-top: 5px;
  display: flex;
  flex-flow: column nowrap;
}
#statsTable {
  border-top: 1px solid #c4c4c4;
}
</style>
