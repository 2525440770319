import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import sessions from './modules/sessions';
import sessionAgents from './modules/sessionAgents';
import form from './modules/form';
import questionsThemes from './modules/questionsThemes';
import enterprise from './modules/enterprise';
import profile from './modules/profile';
import professions from './modules/professions';
import formManagement from './modules/formManagement';
import agents from './modules/agents';
import accounts from './modules/accounts';
import interviewer from './modules/interviewer';
import ui from './modules/ui';
import agentAccess from './modules/agentAccess';
import site from './modules/site';
import logs from './modules/logs';
import statistics from './modules/statistics';
import roles from './modules/roles';
import bannedIP from './modules/bannedIP';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accounts,
    agentAccess,
    agents,
    auth,
    bannedIP,
    enterprise,
    form,
    formManagement,
    interviewer,
    logs,
    professions,
    profile,
    questionsThemes,
    roles,
    sessionAgents,
    sessions,
    site,
    statistics,
    ui
  }
});
