<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="interview-finish-modal">
      <div class="interview-finish-modal--header font-weight-light">
        Edit
      </div>
      <div class="interview-finish-modal--content">
        <div class="interview-finish-modal--content-input d-flex justify-center">
          <v-form class="interview-finish-modal--content-input-form">
            <v-container>
              <v-text-field v-model="formData.date" label="Date de l’entretien" type="date" outline> </v-text-field>
              <v-text-field v-model="formData.interviewers" label="Évaluateurs" :rules="textRules" outline> </v-text-field>
              <v-textarea v-model="formData.comment" autocomplete="comment" label="Commentaire" :counter="{ max: 256 }" :rules="textRules"> </v-textarea>
            </v-container>
            <v-radio-group v-model="formData.result" row>
              <v-radio label="Accés obtenu" :value="true"></v-radio>
              <v-radio label="Accés refusé" :value="false"></v-radio>
            </v-radio-group>
          </v-form>
        </div>
      </div>
      <div class="interview-finish-modal--buttons d-flex justify-end">
        <v-btn class="interview-finish-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="interview-finish-modal--buttons-save" color="#38BB00" :disabled="isDisabled" dark depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'InterviewFinishModal',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        date: new Date().toISOString().slice(0, 10),
        interviewers: '',
        comment: '',
        result: false
      }
    };
  },
  computed: {
    isDisabled() {
      return !(this.formData.interviewers && this.formData.comment && this.formData.comment.length < 257);
    },
    textRules() {
      return [
        v =>
          !!v.match(/^[a-z0-9 `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/i) || "Caractères invalides",
        v => v.length > 0 || 'Trop court',
        v => v.length < 257 || 'Trop long'
      ];
    }
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit({
          interviewers: this.formData.interviewers,
          comment: this.formData.comment,
          result: this.formData.result,
          state: 1,
          closeInterviewDate: this.formData.date
        });

        this.close();
      }
    }
  }
};
</script>

<style lang="scss">
textarea {
  resize: none;
}

.modals-container {
  border: 1px solid #ffffff;
}

.interview-finish-modal {
  &--header {
    text-align: center;
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 490px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
