import request from '@/services/helpers/request';
import { API_ROUTES } from '@/config';

export const fetchAll = async () => (await request.getInstance()).get(API_ROUTES.site.list);
export const fetchListPermission = async () => (await request.getInstance()).get(API_ROUTES.site.listToPermission);
export const newItem = async data => (await request.getInstance()).post(API_ROUTES.site.new, data);
export const remove = async id => (await request.getInstance()).delete(`${API_ROUTES.site.byId}/${id}`);
export const getSite = async id => (await request.getInstance()).get(API_ROUTES.site.getSite.replace(':id', id));
export const changeItem = async (id, data) => (await request.getInstance()).put(`${API_ROUTES.site.byId}/${id}`, data);
export const changePoiValue = async (id, data) => (await request.getInstance()).put(`${API_ROUTES.poi.changePoi}/${id}`, data);
export const allowedSitesList = async ({ id, search = null }) =>
  (await request.getInstance()).get(API_ROUTES.site.allowedSitesList.replace(':id', id), {
    params: {
      search
    }
  });
export const siteMouvementsList = async ({ id, period = null, search = null }) =>
  (await request.getInstance()).get(API_ROUTES.site.siteMouvementsList.replace(':id', id), {
    params: {
      period,
      search
    }
  });
export const siteCommunicationsList = async ({ id }) => (await request.getInstance()).get(API_ROUTES.site.siteCommunicationsList.replace(':id', id));
export const siteAnomaliesList = async ({ id, period = null, search = null }) =>
  (await request.getInstance()).get(API_ROUTES.site.siteAnomaliesList.replace(':id', id), {
    params: {
      period,
      search
    }
  });
export const siteActualAgentList = async ({ id, search = null }) =>
  (await request.getInstance()).get(API_ROUTES.site.siteActualAgentList.replace(':id', id), {
    params: {
      search
    }
  });
export const addAllowSiteAccess = async data => (await request.getInstance()).post(API_ROUTES.site.addAllowSiteAccess, data);
export const removeAllowSiteAccess = async ids => (await request.getInstance()).post(`${API_ROUTES.site.allowSiteById}`, ids);
export const getAllowSiteAccessForAgent = async id => (await request.getInstance()).get(`${API_ROUTES.site.allowSiteById}/${id}`);
