import * as types from './mutation-types';

import {
  fetchAllRoles,
  fetchAllAccess,
  addNew,
  removeAccount,
  addRoleToAccount,
  fetchAllAccounts,
  updateAccount,
  changePassword,
  permissionToRole,
  addNewRole,
  addPermissionToUser,
  fetchAllPermissions,
  getPermissionsToAccount,
  getAccountRoles,
  fetchAll
} from '@/services/accounts.service';

export default {
  async fetchRoles({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAllRoles();
      commit(types.SET_ROLES, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async fetchPermissions({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAllPermissions();
      commit(types.SET_PERMISSIONS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async addPermissionToRole({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await permissionToRole(id, data);
      dispatch('fetchAccess');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async fetchAccess({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAllAccess();
      commit(types.SET_ALL_PERMISSIONS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async fetchAccounts({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAllAccounts();
      commit(types.SET_ACCOUNTS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async addNewUser({ commit, dispatch }, data) {
    commit(types.SET_ERRORS, null);
    try {
      await addNew(data);
      dispatch('fetchAccounts');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async addNewRoleAction({ commit, dispatch }, data) {
    commit(types.SET_ERRORS, null);
    try {
      await addNewRole(data);
      dispatch('fetchRoles');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async removeUser({ commit, dispatch }, id) {
    commit(types.SET_ERRORS, null);
    try {
      await removeAccount(id);
      dispatch('fetchAccounts');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async updateUser({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await updateAccount(id, data);
      dispatch('fetchAccounts');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async changeUserPassword({ commit }, { id, password }) {
    commit(types.SET_ERRORS, null);
    try {
      await changePassword(id, password);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async addNewRoleToAccount({ commit, dispatch }, { userId, role, siteId }) {
    commit(types.SET_ERRORS, null);
    try {
      await addRoleToAccount(userId, role, siteId);
      dispatch('fetchAccounts');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async addNewPermissionToAccount({ commit, dispatch }, { userId, permission, siteId }) {
    commit(types.SET_ERRORS, null);
    try {
      await addPermissionToUser(userId, permission, siteId);
      dispatch('fetchAccounts');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getListPermissionToAccount({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getPermissionsToAccount();
      commit(types.SET_PERMISSIONS_ACCOUNT, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getAccountRolesForTable({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getAccountRoles();
      commit(types.SET_ROLES_ACCOUNT_TABLE, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async fetchSites({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAll();
      commit(types.SET_ZONE_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getRoleStatus({ commit }, data) {
    commit(types.SET_ERRORS, null);
    try {
      commit(types.SET_CHANGE_ROLE, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getPermissionStatus({ commit }, data) {
    commit(types.SET_ERRORS, null);
    try {
      commit(types.SET_CHANGE_PERMISSION, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async setPermissionForTableAccount({ commit }, data) {
    commit(types.SET_ERRORS, null);
    try {
      commit(types.SET_PERMISSIONS_TABLE_ACCOUNT, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async setChangeStatusModal({ commit }, data) {
    commit(types.SET_ERRORS, null);
    try {
      commit(types.SET_CHANGE_MODAL, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
