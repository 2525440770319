<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="fix-anomaly-modal">
      <div class="fix-anomaly-modal--header font-weight-light">
        {{ title }}
      </div>
      <div class="fix-anomaly-modal--content">
        <div class="fix-anomaly-modal--content-input d-flex justify-center">
          <v-form class="fix-anomaly-modal--content-input-form">
            <v-container>
              <!-- name -->
              <v-text-field v-model="oldValues.Agent.lastname" :label="'Nom'" type="text" disabled outline> </v-text-field>
              <!-- firstname -->
              <v-text-field v-model="oldValues.Agent.firstname" :label="'Prénom'" type="text" disabled outline> </v-text-field>
              <!-- Entreprise -->
              <v-text-field v-model="oldValues.Agent.Enterprise.name" :label="'Entreprise'" type="text" disabled outline>
              </v-text-field>
              <!-- site -->
              <v-text-field v-model="oldValues.Site.name" :label="'Site'" type="text" disabled outline> </v-text-field>
              <!-- enter date -->
              <v-text-field
                v-model="formData.enterTime"
                :label="'Heure d\'entrée'"
                type="time"
                outline
                :color="oldValues.wrongedField === 'enterDate' ? 'red' : 'black'"
                :error="oldValues.wrongedField === 'enterDate'"
                :class="oldValues.wrongedField === 'enterDate' ? 'wrong' : ''"
              >
              </v-text-field>
              <!-- leave date -->
              <v-text-field
                v-model="formData.leaveTime"
                :label="'Heure de sortie'"
                type="time"
                outline
                :color="oldValues.wrongedField === 'leaveDate' ? 'red' : 'black'"
                :error="oldValues.wrongedField === 'leaveDate'"
                :class="oldValues.wrongedField === 'leaveDate' ? 'wrong' : ''"
              >
              </v-text-field>
            </v-container>
          </v-form>
        </div>
      </div>
      <div class="change-access-modal--buttons d-flex justify-end">
        <v-btn class="change-access-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="change-access-modal--buttons-save" color="#38BB00" :disabled="isDisabled" dark depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import moment from 'moment';

import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'FixAnomalyModal',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        enterTime: null,
        leaveTime: null
      },
      type: 0
    };
  },
  computed: {
    title() {
      return this.modal.payload.text.title;
    },
    oldValues() {
      return this.modal.payload.oldValues;
    },
    modalType() {
      const modal = ['edit', 'new'];
      return modal[this.type];
    },

    isDisabled() {
      if (this.formData.enterTime === null) {
        return true;
      }

      if (this.formData.leaveTime === null) {
        return true;
      }

      if (moment(this.formData.enterTime, 'HH:mm').isSameOrAfter(moment(this.formData.leaveTime, 'HH:mm'))) {
        return true;
      }

      return false;
    }
  },
  mounted() {
    if (this.oldValues) {
      this.type = 0;
      this.formData.enterTime = moment(this.oldValues.enterDate, 'HH:mm DD/MM/YYYY').format('HH:mm');
      this.formData.leaveTime = moment(this.oldValues.leaveDate, 'HH:mm DD/MM/YYYY').format('HH:mm');
    }
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit({
          id: this.oldValues.id,
          enterDate: moment(
            `${moment(this.oldValues.enterDate, 'HH:mm DD/MM/YYYY').format('DD/MM/YYYY')} ${this.formData.enterTime}`,
            'DD/MM/YYYY HH:mm'
          ).format('YYYY-MM-DD HH:mm'),
          leaveDate: moment(
            `${moment(this.oldValues.leaveDate, 'HH:mm DD/MM/YYYY').format('DD/MM/YYYY')} ${this.formData.leaveTime}`,
            'DD/MM/YYYY HH:mm'
          ).format('YYYY-MM-DD HH:mm')
        });

        this.close();
      }
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.wrong {
  color: red !important;
  caret-color: red !important;

  input {
    color: red !important;
    caret-color: red !important;
  }
}

.fix-anomaly-modal {
  &--header {
    text-align: center;
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 490px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
