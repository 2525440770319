<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="change-item-modal">
      <div class="change-item-modal--header font-weight-light">
        Ajout d'agent(s)
      </div>
      <div class="change-item-modal--content">
        <div class="change-item-modal--content-input d-flex justify-center">
          <v-form class="change-item-modal--content-input-form">
            <v-container>
              <v-autocomplete
                v-model="selectedAgents"
                :items="agents"
                item-value="id"
                filled
                :label="'Profil'"
                item-text="concatened"
                multiple
                chips
                clearable
              >
                <template v-slot:selection="{ props, item }">
                  <v-chip v-bind="props">
                    {{ item.concatened }}
                    <v-icon small @click="remove(item)">mdi-close</v-icon>
                  </v-chip>
                </template>
                <template v-slot:item="{ props, item }">
                  {{ item.concatened }}
                </template>
              </v-autocomplete>
            </v-container>
          </v-form>
        </div>
      </div>
      <div class="change-item-modal--buttons d-flex justify-end">
        <v-btn class="change-item-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="change-item-modal--buttons-save" color="#38BB00" :disabled="isDisabled" dark depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
/**
 * Popup affichée sur la page /sections, onglet "Personnel Autorisé", lors de l'ajout d'agents autorisés dans la zone
 */

import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'ChangeSiteModal',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedAgents: null
    };
  },
  computed: {
    title() {
      return this.modal.payload.text.title;
    },
    // pas utilisé ?
    stringRules() {
      return [v => this.validateString(v) || 'Valeur invalide'];
    },
    isDisabled() {
      return !this.selectedAgents;
    },
    agents() {
      return this.modal.payload.agents.map(agent => ({
        ...agent,
        concatened: `${agent.lastname} ${agent.firstname} - ${agent.enterpriseName} - ${agent.profileName}`
      }));
    }
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    // Pas utilisé ?
    validateString(value) {
      return !!value.match(/^[a-z0-9 `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/i) && value.length > 0 && value.length < 51;
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit({
          agentIds: this.selectedAgents
        });

        this.close();
      }
    },
    remove(item) {
      this.selectedAgents.splice(this.selectedAgents.indexOf(item), 1);
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.change-item-modal {
  &--header {
    text-align: center;
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 490px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
