var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"agent-details-interview-table",attrs:{"headers":_vm.headers,"items":_vm.interviews,"fixed-header":"","height":"500","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('TwoArrowSort',{staticClass:"agent-details-interview-table--sort-icon"})],1)]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.resultText[item.result])+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.createdAt))+" ")]}},{key:"item.form",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"agent-details-interview-table--form",on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(" Voir ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }