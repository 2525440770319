<template>
  <div>
    <Control @add="addAccess" @remove="removeAccess" @edit="editAccess" />
    <v-data-table
      v-model="selected"
      fixed-header
      :headers="headers"
      :items="zones"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      }"
      height="600"
      class="access-table"
      :single-select="true"
      show-select
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:header.firstname="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="access-table--sort-icon" />
        </div>
      </template>

      <template v-slot:item.nb="{ item }">
        {{ item.Agents.length }}
      </template>

      <template v-slot:item.poiEnabled="{ item }">
        {{ item.poiEnabled === 1 ? 'Oui' : 'Non' }}
      </template>

      <template v-slot:item.details="{ item }">
        <router-link
          :tag="'a'"
          :to="{
            name: 'SectionDetails',
            params: {
              id: item.id
            }
          }"
        >
          Détail
        </router-link>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { get } from 'lodash';

import { formatValues } from '@/mixins/formatValues.mixin';
import Control from '@/components/sessions/Control';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';

export default {
  name: 'SectionTable',
  components: {
    Control,
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    access: {
      type: Array,
      default: () => []
    },
    mouvementsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: [],
      sortBy: ['name'],
      sortDesc: false
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'Zones',
          divider: true,
          align: 'start',
          sortable: true,
          width: '60%',
          value: 'name'
        },
        {
          text: 'Personnes présentes',
          divider: true,
          align: 'center',
          width: '20%',
          sortable: true,
          value: 'nb'
        },
        {
          text: 'POI',
          divider: true,
          align: 'center',
          width: '10%',
          sortable: true,
          value: 'poiEnabled'
        },
        {
          text: 'Détails',
          divider: true,
          align: 'center',
          width: '10%',
          sortable: true,
          value: 'details'
        }
      ];
    },
    zones() {
      return this.access;
    },
    selectedAccess() {
      return this.selected[0] || {};
    },
    selectedId() {
      return get(this.selectedAccess, 'id', null);
    }
  },
  methods: {
    addAccess() {
      this.$modals.open({
        name: 'ChangeSectionModal',
        payload: {
          submit: data => {
            this.$emit('add', data);
          }
        }
      });
    },
    removeAccess() {
      if (!this.selectedId) {
        return;
      }
      this.$modals.open({
        name: 'RemoveItem',
        payload: {
          text: {
            title: 'Supprimer la zone',
            subTitle: 'Voulez-vous vraiment supprimer la zone?'
          },
          submit: () => {
            this.$emit('remove', this.selectedId);
          }
        }
      });
    },
    editAccess() {
      if (!this.selectedId) {
        return;
      }
      this.$modals.open({
        name: 'ChangeSectionModal',
        payload: {
          oldValues: {
            name: this.selectedAccess.name,
            antipassback: this.selectedAccess.antipassback,
            alias: this.selectedAccess.alias
          },
          submit: data => {
            this.$emit('edit', { data, id: this.selectedId });
            this.selected = [];
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.sessions-control-icon {
  padding: 0 4px;
}

.theme--light.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate) .v-icon {
  color: #3dd598 !important;
}

.access-table {
  .v-data-table-header__icon {
    display: none;
  }

  &--form {
    color: #0094ff !important;
    text-decoration: underline;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
