<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" @click.stop="handleClick">
        <svg style="max-width:50px" viewBox="0 0 32 32" version="1.0" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <polyline
              class="st0"
              points="22,9 29,9 29,24 3,24 3,9 10,9 "
              :style="'fill:none; stroke:' + color + ' ; stroke-width:1; stroke-miterlimit:5;'"
            ></polyline>
            <rect class="st1" height="6" width="4" x="7" y="14" :style="'fill:' + color + ';'"></rect>
            <line class="st0" x1="14" x2="23" y1="15" y2="15" :style="'fill:none; stroke:' + color + '; stroke-width:1; stroke-miterlimit:5;'"></line>
            <line class="st0" x1="14" x2="25" y1="19" y2="19" :style="'fill:none; stroke:' + color + '; stroke-width:1; stroke-miterlimit:5;'"></line>
            <rect class="st1" height="4" width="8" x="12" y="7" :style="'fill:' + color + ';'"></rect>
            <g>
              <path class="st2" d="M11,7" style="fill:none; stroke:#FFFFFF; stroke-width:1; stroke-miterlimit:5;"></path>
              <path class="st2" d="M21,10" style="fill:none; stroke:#FFFFFF; stroke-width:1; stroke-miterlimit:5;"></path>
            </g>
            <g></g>
          </g>
        </svg>
      </span>
    </template>
    <span> {{ agent.badge ? agent.badge : 'Aucun badge confirmé' }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    agent: {
      type: Object,
      required: true
    },
    click: {
      type: Function,
      required: true
    }
  },
  computed: {
    color() {
      return this.agent.badge === null ? 'lightcoral' : '#152730';
    }
  },
  methods: {
    handleClick() {
      this.click(this.agent);
    }
  }
};
</script>
