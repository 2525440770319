export default {
  bannedIP: state => state.bannedIP,
  list: state => state.list,
  bannedList: state => state.bannedList,
  whitedList: state => state.whitedList,
  address: state => state.address,
  errors: state => state.errors,

  changeModal: state => state.changeModal
};
