import * as types from './mutation-types';

export default {
  [types.SET_HOURS_LIST](state, hours) {
    state.hours = hours;
  },
  [types.SET_ANOMALIES_LIST](state, anomalies) {
    state.anomalies = anomalies;
  },
  [types.SET_FORMATIONS_LIST](state, formations) {
    state.formations = formations;
  },
  [types.SET_STATS_LIST](state, stats) {
    state.stats = stats;
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  }
};
