<template>
  <div class="sessions-control d-flex justify-space-between align-center">
    <div class="sessions-control--icons d-flex justify-space-between align-center">
      <AddButton v-if="showAdd" class="sessions-control-icon add" @click.native="$emit('add')" />
      <EditButton v-if="showEdit" class="sessions-control-icon edit" @click.native="$emit('edit')" />
      <RemoveButton v-if="showRemove" class="sessions-control-icon remove" @click.native="$emit('remove')" />
      <LockButton v-if="showLock" class="sessions-control-icon lock" @click.native="$emit('lock')" />
      <RoleUp v-if="showRole" class="sessions-control-icon change" @mouseover="hover = true" @mouseleave="hover = false" @click.native="$emit('change')" />
    </div>
    <div v-if="nextButtonText" class="sessions-control--next" @click.stop.prevent="$emit('next')">
      <div class="d-flex justify-space-between align-center font-weight-medium">
        {{ nextButtonText }}
      </div>
    </div>
    <div v-if="isExport" class="sessions-control--export" @click.stop.prevent="$emit('export')">
      <div class="d-flex justify-space-between align-center font-weight-medium">
        Exporter
      </div>
    </div>
  </div>
</template>

<script>
import AddButton from '@/components/common/icons/AddButton';
import EditButton from '@/components/common/icons/EditButton';
import RemoveButton from '@/components/common/icons/RemoveButton';
import LockButton from '@/components/common/icons/Lock';
import RoleUp from '@/components/common/icons/RoleUp';

export default {
  components: {
    AddButton,
    EditButton,
    RemoveButton,
    LockButton,
    RoleUp
  },
  props: {
    nextButtonText: {
      type: String,
      default: ''
    },
    isExport: {
      type: Boolean,
      default: false
    },
    showAdd: {
      type: Boolean,
      default: true
    },
    showEdit: {
      type: Boolean,
      default: true
    },
    showRemove: {
      type: Boolean,
      default: true
    },
    showLock: {
      type: Boolean,
      default: false
    },
    showRole: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss">
.sessions-control {
  height: 36px !important;

  .sessions-control-icon {
    padding: 0 4px;
    cursor: pointer;
    margin: 6px 0 0 0;
  }

  border: 1px solid #e4e6e8;
  border-radius: 5px 4px 0px 0px;

  &--next,
  &--export {
    cursor: pointer;
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    margin-right: 5px;
    font-size: 14px;
  }

  &--next {
    padding: 0 42px;
    width: 212px;
  }

  &--export {
    padding: 0 20px 0 20px;
  }

  &--icons,
  &--next {
    height: 24px !important;
  }
}
</style>
