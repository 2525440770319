<template>
  <v-form ref="form" v-model="valid" class="form-group form-group--main d-flex align-center flex-column py-10" lazy-validation>
    <div class="form-group--header d-flex px-5 flex-column">
      <span class="form-group--title">Se connecter à {{ appName }}</span>
      <span class="form-group--sub-title">Renseigner vos informations de connexion pour vous connecter</span>
    </div>

    <!-- Cet h1 n'apparait que si l'application détecte une adresse IP suspecte et bannie-->
    <h1 class="h1-banned-message" v-if="isBan">
      SUITE A TROP DE TENTATIVES DE CONNEXION, VOTRE ADRESSE IP A ETE BANNIE MERCI DE VOUS RAPPROCHER DE VOTRE ADMINISTRATEUR
    </h1>
    <div class="d-flex px-5 flex-column">
      <v-text-field
        :disabled="isBan"
        v-model="formData.email"
        class="form-input-login"
        :rules="emailRules"
        dense
        required
        filled
        single-line
        outlined
        type="text"
        width="420px"
        height="56px"
        :messages="errors"
        name="login"
        :placeholder="'email@example.com'"
      >
        <template v-slot:message="{ message }">
          <span class="red--text">{{ message }}</span>
        </template>
      </v-text-field>
    </div>
    <div class="d-flex px-5 pb-6 flex-column">
      <v-text-field
        :disabled="isBan"
        v-model="formData.password"
        type="password"
        dense
        :rules="passwordRules"
        required
        single-line
        outlined
        filled
        width="420px"
        height="56px"
        class="form-input-password"
        name="password"
        :placeholder="'•••••••••'"
      />
    </div>
    <div class="d-flex px-5 pb-6 flex-column">
      <v-btn :disabled="isBan" class="form-group--submit form-input-submit" type="submit" color="#3e7eff" width="420px" height="54px" @click.prevent="submit">
        Connexion
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LoginForm',
  props: {
    errors: {
      type: String,
      default: ''
    },
    isBan: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      formData: {
        email: '',
        password: ''
      }
    };
  },
  computed: {
    emailRules() {
      return [v => !!v || "Une adresse email est requise", v => /.+@.+\..+/.test(v) || "E-mail non valide"];
    },
    passwordRules() {
      return [v => !!v || "Mot de passe requis"];
    },
    appName() {
      if (this.config !== null && typeof this.config.APP_TITLE === 'string') {
        return this.config.APP_TITLE;
      }

      return 'GreenStep';
    },
    ...mapGetters('ui', ['config'])
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit('submit', this.formData);
      }
    }
  }
};
</script>

<style lang="scss">
.h1-banned-message {
  color: red;
  text-align: center;
}

.form-group {
  margin-top: 70px;

  .v-btn--is-elevated {
    color: white !important;
  }

  .v-input__slot {
    background-color: white !important;
  }

  &--header {
    margin-bottom: 42px;
  }

  &--title,
  &--sub-title {
    font-style: normal;
    text-align: center;
  }

  &--title {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
  }

  &--sub-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .form-input-login,
  .form-input-password,
  .form-input-submit {
    width: 420px;
  }

  .form-input-login,
  .form-input-password {
    height: 56px;
    border-radius: 4px;
  }

  .form-input-login {
    margin-bottom: 23px !important;
  }

  .form-input-submit {
    height: 54px;
    color: #3e7eff;
    background-color: #3e7eff;
    border-radius: 10px;
  }

  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #3e7eff !important;
  }

  .theme--light.v-btn.v-btn--disabled {
    color: white !important;
  }

  &--restore {
    cursor: pointer;
    display: inline-block !important;

    &--restore {
      font-weight: 700;
      font-size: 12px;
    }
  }

  &--forgot {
    font-weight: 400;
    font-size: 14px;
    padding-right: 14px;
  }
}
</style>
