import * as types from './mutation-types';

import { fetchAll, remove, newItem, editItem } from '@/services/profile.service';

export default {
  async fetchList({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAll();
      commit(types.SET_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async removeItem({ commit, dispatch }, id) {
    commit(types.SET_ERRORS, null);
    try {
      await remove(id);
      dispatch('fetchList');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async addItem({ commit, dispatch }, data) {
    commit(types.SET_ERRORS, null);
    try {
      await newItem(data);
      dispatch('fetchList');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async changeItemById({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await editItem(id, data);
      dispatch('fetchList');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
