import * as types from './mutation-types';

export default {
  [types.SET_ROLES](state, roles) {
    state.roles = roles;
  },
  [types.SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions;
  },
  [types.SET_PERMISSIONS_ACCOUNT](state, permissionsToAccount) {
    state.permissionsToAccount = permissionsToAccount;
  },
  [types.SET_ACCOUNTS](state, accounts) {
    state.accounts = accounts;
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [types.SET_ALL_PERMISSIONS](state, allPermissions) {
    state.allPermissions = allPermissions;
  },
  [types.SET_ALL_ROLES](state, allPermissions) {
    state.rolesList = allPermissions.rolesList;
  },
  [types.SET_ROLES_ACCOUNT_TABLE](state, allRolesUserList) {
    state.allRolesUserList = allRolesUserList;
  },
  [types.SET_ZONE_LIST](state, zoneList) {
    state.zoneList = zoneList;
  },
  [types.SET_CHANGE_ROLE](state, changeRoles) {
    state.changeRoles = changeRoles;
  },
  [types.SET_CHANGE_PERMISSION](state, changePermission) {
    state.changePermission = changePermission;
  },
  [types.SET_PERMISSIONS_TABLE_ACCOUNT](state, permissionForTable) {
    state.permissionForTable = permissionForTable;
  },
  [types.SET_CHANGE_MODAL](state, changeModal) {
    // <-
    state.changeModal = changeModal;
  }
};
