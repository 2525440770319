<template>
  <div class="accesss-table-page">
    <Control :is-export="true" @add="addAccess" @remove="removeAccess" @export="exportToCSV" />
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="access"
      :items-per-page="-1"
      fixed-header
      height="500"
      class="access-table"
      show-select
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template #header.data-table-select></template>
      <template v-slot:header.firstname="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="access-table--sort-icon" />
        </div>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ formatDateHourMinute(item.createdAt) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { get } from 'lodash';

import { formatValues } from '@/mixins/formatValues.mixin';
import Control from '@/components/sessions/Control';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';

export default {
  name: 'AllAccessTable',
  components: {
    Control,
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    access: {
      type: Array,
      default: () => []
    },
    agents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      statusText: ["Refusé", "Entretien", "Validé"],
      selected: [],
      sortBy: ['Agent.lastname'],
      sortDesc: false
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Nom",
          divider: true,
          align: 'start',
          width: '17%',
          sortable: true,
          value: 'Agent.lastname'
        },
        {
          text: "Prénom",
          divider: true,
          align: 'start',
          sortable: true,
          width: '17%',
          value: 'Agent.firstname'
        },
        {
          text: "Profil",
          divider: true,
          align: 'start',
          width: '24%',
          sortable: true,
          value: 'Agent.AgentProfile.name'
        },
        {
          text: "Entreprise",
          divider: true,
          align: 'start',
          width: '24%',
          sortable: true,
          value: 'Agent.Enterprise.name'
        },
        {
          text: "Ajouté le",
          divider: true,
          align: 'start',
          width: '18%',
          sortable: true,
          value: 'createdAt'
        }
      ];
    },
    selectedAccess() {
      return this.selected || [];
    },
    selectedIds() {
      return this.selectedAccess.map(access => access.id);
    }
  },
  methods: {
    addAccess() {
      this.$modals.open({
        name: 'ChangeSiteModal',
        payload: {
          agents: this.agents,
          submit: data => {
            this.$emit('add', data);
          }
        }
      });
    },
    removeAccess() {
      const number = this.selectedIds.length;

      // Aucun accès sélectionné, on ne fait rien
      if (!this.selectedIds) {
        return;
      }

      // Change le message selon si singulier ou pluriel
      var text = {};
      if (number === 1) {
        text = {
          title: `Attention`,
          subTitle: "Êtes-vous sur de vouloir retirer l'accès de cet agent ?"
        };
      } else {
        text = {
          title: `Attention`,
          subTitle: `Êtes-vous sur de vouloir retirer l'accès de ces ${number} agents ?`
        };
      }

      // Pop-up de confirmation
      this.$modals.open({
        name: 'RemoveItem',
        payload: {
          text: text,
          submit: () => {
            // SectionDetails.vue
            this.$emit('remove', this.selectedIds);
          }
        }
      });
    },
    exportToCSV() {
      const access = this.selectedAccess.length !== 0 ? this.selectedAccess : this.access;
      const items = access.map(row => ({
        lastname: row.Agent.lastname,
        firstname: row.Agent.firstname,
        profile: row.Agent.AgentProfile.name,
        entreprise: row.Agent.Enterprise.name,
        createdAt: formatValues.methods.formatDateHourMinute(row.createdAt)
      }));

      const headers = this.headers.map(header => header.text);
      let csvContent = '\ufeff'; // Add BOM character at the beginning for Excel
      csvContent += `${headers.join(';')}\n`;

      items.forEach(item => {
        const row = Object.values(item).join(';');
        csvContent += `${row}\n`;
      });

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', 'data.csv');
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>

<style lang="scss">
.sessions-control-icon {
  padding: 0 4px;
}

.accesss-table-page {
  .edit {
    display: none;
  }
}

.access-table {
  .v-data-table-header__icon {
    display: none;
  }

  &--form {
    color: #0094ff !important;
    text-decoration: underline;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
