<template>
  <div class="statistics-page">
    <!-- HEADER -->
    <h2>Statistiques chantiers</h2>
    <div id="general-stats" class="d-flex flex-row py-4">
      <div class="d-flex mx-2 pa-2 text-center flex-column stats-block">
        <b>Entrée par agent unique</b> <span>{{ stats.nbUniqSiteMoves }}</span>
      </div>
      <div class="d-flex mx-2 pa-2 text-center flex-column stats-block">
        <b>Entrées</b> <span>{{ stats.nbMoveIn }}</span>
      </div>
      <div class="d-flex mx-2 pa-2 text-center flex-column stats-block">
        <b>Sorties</b> <span>{{ stats.nbMoveOut }}</span>
      </div>
      <div class="d-flex mx-2 pa-2 text-center flex-column stats-block">
        <b>Anomalies</b> <span>{{ stats.nbAnormalMoves }}</span>
      </div>
      <div class="access-page--header filterSearch mr-6 flex-column">
        <div class=" d-flex justify-space-between align-center">
          <div class=" d-flex justify-start align-center">
            <div class="filterHeader">
              <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dates" label="Période d'affichage" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="dates" @change="refreshData" range></v-date-picker>
              </v-menu>
            </div>
          </div>
        </div>
      </div>
      <v-text-field id="searchBox" v-model="searchQuery" class="filterSearch mr-6" prepend-icon="mdi-magnify" label="Rechercher" clearable></v-text-field>
    </div>

    <!-- TABS -->
    <v-card>
      <v-tabs v-model="tab">
        <v-tab href="#site">Sites</v-tab>
        <v-tab href="#anomalies">Anomalies</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="site">
          <StatistiquesTable :hours="hours" :search-query="searchQuery" />
        </v-tab-item>
        <v-tab-item value="anomalies">
          <StatistiqueAnomaliesTable :anomalies="anomalies" :search-query="searchQuery" @fixAnomaly="correctAnomaly"></StatistiqueAnomaliesTable>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import StatistiquesTable from '../../components/common/tables/StatistiquesTable.vue';
import { ROUTES_NAME } from '../../common/constants';
import StatistiqueAnomaliesTable from '../../components/common/tables/StatistiqueAnomaliesTable.vue';
// import StatistiqueFormationsTable from '../../components/common/tables/StatistiqueFormationsTable.vue';

export default {
  name: ROUTES_NAME.statistics,
  components: {
    StatistiquesTable,
    StatistiqueAnomaliesTable
    // StatistiqueFormationsTable
  },

  data() {
    return {
      tab: null,
      searchQuery: '',
      dates: [
        moment().startOf("week").format('YYYY-MM-DD'),
        moment().endOf("week").format('YYYY-MM-DD')
      ]
    };
  },

  computed: {
    hoursParams() {
      let params = '';
      if (this.dates[0]) {
        params = params.concat(
          `?startPeriod=${moment(this.dates[0]).startOf('D').format('YYYY-MM-DD HH:mm:ss')}`
        );
        if (this.dates[1]) {
          params = params.concat(
            `&endPeriod=${moment(this.dates[1]).endOf('D').format('YYYY-MM-DD HH:mm:ss')}`
          );
        }
      }
      return params;
    },
    ...mapGetters('statistics', ['hours', 'anomalies', 'formations', 'stats'])
  },

  watch: {
    dates: {
      immediate: true,
      deep: true,
      handler(newDates) {
        if (newDates[1]) {
          if (moment(newDates[1]).isBefore(moment(newDates[0]))) {
            const temp = newDates[0];
            this.dates[0] = newDates[1];
            this.dates[1] = temp;
          }
        }
      }
    },

    hoursParams() {
      // On ne déclenche les updates que si il y a bien 2 dates sélectionnées,
      // pour éviter d'envoyer seulement une date de début à l'API
      if (this.dates[1])
        this.refreshData();
    }
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.fetchHours(this.hoursParams);
      this.fetchAnomalies(this.hoursParams);
      this.fetchStats(this.hoursParams);
    },
    ...mapActions('statistics', ['fetchHours', 'fetchAnomalies', 'fetchStats', 'correctAnomaly'])
  }
};
</script>

<style>
.statistics-page {
  padding: 5px;
}

.stats-block {
  border: 1px solid #e4e6e8;
  border-radius: 4px;
}
</style>
