export default {
  sites: state => state.sites,
  allowedSitesList: state => state.allowedSitesList,
  mouvementsList: state => state.mouvementsList,
  communicationsList: state => state.communicationsList,
  actualAgentsList: state => state.actualAgentsList,
  anomaliesList: state => state.anomaliesList,
  allowedSites: state => state.allowedSites,
  errors: state => state.errors,
  site: state => state.site,
  listPermission: state => state.listPermission,
  selectZone: state => state.selectZone
};
