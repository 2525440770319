<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="interview-finish-modal">
      <div class="interview-finish-modal--header font-weight-light">
        Edit
      </div>
      <div class="interview-finish-modal--content">
        <div class="interview-finish-modal--content-input d-flex justify-center">
          <v-form class="interview-finish-modal--content-input-form">
            <v-container>
              <v-text-field v-model="formData.createdAt" label="Date" type="date" disabled outline> </v-text-field>
              <v-text-field v-model="formData.formName" label="Questionnaire" disabled outline> </v-text-field>
              <v-text-field v-model="formData.interviewers" label="Évaluateurs" disabled outline> </v-text-field>
              <v-text-field v-model="formData.result" label="Résultat" disabled outline> </v-text-field>
              <v-text-field v-model="formData.comment" label="Commentaire" disabled outline> </v-text-field>
            </v-container>
          </v-form>
        </div>
      </div>
      <div class="interview-finish-modal--buttons d-flex justify-end"></div>
    </div>
  </BasePopupModal>
</template>

<script>
import moment from 'moment';

import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'InterviewDetailsModal',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        createdAt: null,
        formName: '',
        interviewers: '',
        result: 0,
        comment: ''
      }
    };
  },
  computed: {
    oldValues() {
      return this.modal.payload.oldValues;
    }
  },
  mounted() {
    this.formData = this.oldValues;
    this.formData.createdAt = moment(this.oldValues.createdAt).format('YYYY-MM-DD');
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
textarea {
  resize: none;
}

.modals-container {
  border: 1px solid #ffffff;
}

.interview-finish-modal {
  &--header {
    text-align: center;
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 490px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
