<template>
  <div class="themes-page">
    <ThemesTable :themes="questionsThemes" @add="newTheme($event)" @edit="editTheme($event)" @remove="removeTheme($event)" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { ROUTES_NAME } from '@/common/constants';
import ThemesTable from '@/components/common/tables/ThemesTable';

export default {
  name: ROUTES_NAME.qustionThemes,
  components: {
    ThemesTable
  },

  computed: {
    ...mapGetters('questionsThemes', ['questionsThemes'])
  },
  mounted() {
    this.getAllThemes();
  },
  methods: {
    ...mapActions('questionsThemes', ['getAllThemes', 'newTheme', 'editTheme', 'removeTheme'])
  }
};
</script>

<style lang="scss">
.themes-page {
  padding: 0 10px;
}
</style>
