import request from '@/services/helpers/request';
import { API_ROUTES } from '@/config';

export const fetchAll = async () => (await request.getInstance()).get(API_ROUTES.agents.fetchAll);
export const get = async id => (await request.getInstance()).get(API_ROUTES.agents.get.replace(':id', id));
export const remove = async id => (await request.getInstance()).delete(`${API_ROUTES.agents.change}/${id}`);
export const removeAgents = async ids => (await request.getInstance()).post(API_ROUTES.agents.deleteAgentList, ids);
export const newItem = async data => (await request.getInstance()).post(API_ROUTES.agents.new, data);
export const editItem = async (id, data) => (await request.getInstance()).put(`${API_ROUTES.agents.change}/${id}`, data);
export const getProfiles = async () => (await request.getInstance()).get(API_ROUTES.agents.getProfiles);
export const getEnterprises = async () => (await request.getInstance()).get(API_ROUTES.agents.getEnterprises);
