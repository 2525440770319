<template>
  <div>
    <v-data-table
      v-model="selected"
      fixed-header
      :headers="headers"
      :items="listAddress"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      }"
      height="600"
      :single-select="true"
      show-select
      class="accounts-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:header.email="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="accounts-table--sort-icon" />
        </div>
      </template>

      <template v-slot:item.lastConnexion="{ item }">
        {{ formatDateHourMinute(item.lastConnexion) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatValues } from '@/mixins/formatValues.mixin';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';
import { mapGetters } from 'vuex';

export default {
  name: 'BanIPTable',
  components: {
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    listAddress: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      singleSelect: true,
      selected: [],
      sortBy: 'address',
      sortDesc: false
    };
  },
  computed: {
    ...mapGetters('bannedIP', ['changeModal']),
    headers() {
      return [
        {
          text: "Adresses IP bannies",
          divider: true,
          align: 'start',
          sortable: true,
          value: 'address'
        },
        {
          text: "Dernière connexion",
          divider: true,
          align: 'start',
          sortable: true,
          value: 'lastConnexion'
        }
      ];
    }
  },

  watch: {
    changeModal: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.changeModal) {
          this.selected = [];
        }
      }
    },
    selected() {
      this.$emit('set-selected-id', this.selected);
    }
  }
};
</script>

<style lang="scss">
.accounts-table {
  table-layout: fixed;

  .v-data-table-header__icon {
    display: none;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }
  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
