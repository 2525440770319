export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export const VUE_APP_LANG = process.env.VUE_APP_LANG || 'fr';
export const ACCESS_INVALIDITY = '2026-01-01';
export const API_ROUTES = {
  auth: {
    login: '/auth/login'
  },
  accounts: {
    // Fetch All Access and account
    fetchAllRoles: 'accountManager/rolesList',
    fetchAllPermissions: 'accountManager/permissionsList',
    fetchAllRolePermissions: 'accountManager/rolePermissionList',
    fetchAllAccess: 'accountManager/allAccessList',
    fetchAllAccounts: 'accountManager/usersList',

    // Gestion des Permissions et des rôles des utilisateur
    addRoleToAccount: 'accountManager/:id/:siteId/addRoleToUser',
    addPermissionToUser: 'accountManager/:id/:siteId/addPermissionToUser',
    getPermissionsToAccount: 'accountManager/getPermissionsToAccount',
    getRolesToAccount: 'accountManager/:id/:siteId/getRolesToAccount', // Non utilisé

    // Paramétrages des rôles et permissions
    addNewRole: 'accountManager/addNewRole',
    addNewPermission: 'accountManager/addNewPermission',
    permissionToRole: 'accountManager/:id/permissionToRole',

    // Gestion des comptes
    removeAccount: 'accountManager/:id',
    updateAccount: 'accountManager/:id',
    changePassword: 'accountManager/:id/password',
    selfChangePassword: 'accountManager/reset-password',
    addNew: 'accountManager/register',

    // Récupération des role/permission d'un utilisateur
    getAccountRoles: 'accountManager/getUserRoles',
    getAccountPermissions: 'accountManager/:id/getUserPermissions', // <- non utilisé
    getAccountRolesAndPermissions: 'accountManager/:id/getUserRolesAndPermission' // <- non utilisé
  },
  agents: {
    fetchAll: 'agent/agentsList',
    change: 'agent',
    get: 'agent/:id',
    new: 'agent/newAgent',
    getProfiles: 'agent/profiles',
    getEnterprises: 'agent/enterprises',
    deleteAgentList: 'agent/agentsList/delete'
  },
  agentAccess: {
    fetchAll: 'agentAccess/agentAccessList',
    byId: 'agentAccess',
    newAccess: 'agentAccess/giveAccess',
    agentAccess: 'agentAccess/accesses'
  },
  enterprise: {
    fetchAll: 'enterprise/enterprisesList',
    change: 'enterprise',
    new: 'enterprise/newEnterprise'
  },
  form: {
    getAllForms: 'form/formsList'
  },
  interviewer: {
    fetchAll: 'interviewer/listOfInterviews',
    new: 'interviewer/newInterview/:agentId',
    byId: 'interviewer/:id',
    closeInterview: 'interviewer/closeInterview/:id'
  },
  profile: {
    fetchAll: 'agentProfile/agentProfilesList',
    change: 'agentProfile',
    new: 'agentProfile/newAgentProfile'
  },
  profession: {
    fetchAll: 'profession',
    create: 'profession',
    change: 'profession/:id',
    remove: 'profession/:id'
  },
  sessions: {
    fetchAll: '/session/sessions',
    session: 'session',
    getSessionInfo: 'session/getSessionInfo',
    new: 'session/newSession',
    addAgent: 'session/:id/addAgent',
    addAgents: 'session/:id/addAgents',
    removeAgent: 'session/:id/removeAgent',
    removeAgents: 'session/:id/removeAgents',
    setState: 'session/:id/setState',
    getAnswers: 'session/:id/sessionAnswers',
    getQuestions: 'session/:id/questions',
    getCurrentQuestion: 'session/:id/currentQuestion',
    // setQuestion: 'session/:id/currentQuestion',
    setNextQuestion: 'session/:id/nextQuestion',
    setUserResult: 'session/:id/result',
    sessionEnd: 'session/:id/end'
  },
  sessionAgents: {
    getAllAgents: 'sessionAgent/sessionAgentsList',
    getBySessionId: 'sessionAgent/sessionAgents',
    getDeviceToken: 'sessionAgent/:sessionAgentId/deviceToken',
    getAgentAnswers: 'sessionAgent/:sessionAgentId/answers',
    setBadge: 'sessionAgent/:sessionAgentId/badge',
    addComment: 'sessionAgent/:id/comment',
    saveFinalScore: 'sessionAgent/:id/setFinalScore'
  },
  site: {
    list: 'site/sitesList',
    new: 'site/newSite',
    byId: 'site',
    getSite: 'site/:id',
    allowedSitesList: 'site/:id/allowedAgents', // TODO: la route API 'allowedSitesList' existe mais elle n'est pas utilisée ici
    siteMouvementsList: 'site/:id/mouvements',
    siteCommunicationsList: 'site/:id/communications',
    siteAnomaliesList: 'site/:id/anomalies',
    siteActualAgentList: 'site/:id/actualAgents',
    addAllowSiteAccess: 'site/allowSiteAccess',
    allowSiteById: 'site/allowedSites',
    listToPermission: 'site/sitesListToPermission'
  },
  formManagement: {
    getFormsList: 'form/formsList',
    addForm: 'form/addNewForm',
    formById: 'form/:id',
    questionsList: 'formQuestion/questionsList',
    questionById: 'formQuestion/:id',
    createQuestion: 'formQuestion/newFormQuestion'
  },
  questionsThemes: {
    getAllThemes: 'questionTheme/themesList',
    getThemeById: 'questionTheme/:id',
    addTheme: 'questionTheme/addQuestionTheme'
  },
  poi: {
    changePoi: 'poi'
  },
  logs: {
    fetchAll: '/logs/logsList'
  },
  statistics: {
    getHours: 'synthese/hours',
    getStats: 'synthese/stats',
    getAnomalies: 'synthese/anomalies',
    fixAnomaly: 'synthese/anomalie/:id'
  },
  statsFormation: {
    getHeader: 'statsFormation/header',
    getStats: 'statsFormation/stats',
    getResponses: 'statsFormation/responses',
    getCandidates: 'statsFormation/candidates',
    getProfessions: 'statsFormation/professions'
  },
  roles: {
    fetchAllRoles: 'roles/rolesList',
    fetchAllPermissions: 'roles/permissionsList',
    fetchAllRolePermissions: 'roles/rolePermissionList',
    fetchAllAccess: 'roles/allAccessList',
    permissionToRole: 'roles/:id/permissionToRole'
  },
  bannedIP: {
    fetchAllBannedIp: 'bannedIP/banList',
    fetchAllWhitedIp: 'bannedIP/whiteList',
    getBannedIP: 'bannedIP/:address',
    removeBannedIP: 'bannedIP/:id',
    newBannedIP: 'bannedIP/newBannedIP'
  }
};
