<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="change-item-modal">
      <div class="change-item-modal--header font-weight-light">
        {{ title }}
      </div>
      <div class="change-item-modal--content">
        <div class="change-item-modal--content-input d-flex justify-center">
          <v-form
            class="change-item-modal--content-input-form"
            @submit="
              e => {
                e.preventDefault();
                submit();
              }
            "
          >
            <v-container>
              <v-text-field ref="label" v-model="formData.name" :label="'Libellé'" :rules="nameRules" outline> </v-text-field>
              <v-select v-model="formData.professionId" :items="list" item-text="label" item-value="id"></v-select>
            </v-container>
          </v-form>
        </div>
      </div>
      <div class="change-item-modal--buttons d-flex justify-end">
        <v-btn class="change-item-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="change-item-modal--buttons-save" color="#38BB00" :disabled="isDisabled" dark depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'ChangeEnterprise',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        name: '',
        professionId: ''
      }
    };
  },
  computed: {
    title() {
      return this.modal.payload.text.title;
    },
    oldValues() {
      return this.modal.payload.oldValues;
    },
    modalType() {
      const modal = ['edit', 'new'];
      return modal[this.type];
    },
    nameRules() {
      return [
        v =>
          !!v.match(/^[a-z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]*$/i) || "Caractères invalides",
        v => v.length > 0 || "Nom trop court",
        v => v.length < 51 || "Nom trop long"
      ];
    },
    isDisabled() {
      const disabled =
        !!this.formData.name.match(/^[a-z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]*$/i) &&
        this.formData.name.length > 0 &&
        this.formData.name.length < 51;

      return !disabled;
    },

    ...mapGetters('professions', ['list'])
  },
  mounted() {
    if (this.oldValues) {
      this.formData = this.oldValues;
    }
    this.fetchList();
    // On auto focus le champs title quand la modale est montée
    const el = this.$refs.label;
    setTimeout(() => el.focus(), 100);
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit(this.formData);

        this.close();
      }
    },
    ...mapActions('professions', ['fetchList'])
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.change-item-modal {
  &--header {
    text-align: center;
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 236px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
