<template>
  <div>
    <v-data-table
      v-model="selected"
      fixed-header
      :headers="headers"
      :items="roles"
      show-select
      hide-default-footer
      height="600"
      :single-select="true"
      class="roles-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:header.name="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="agents-table--sort-icon" />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import { get } from 'lodash';

import { formatValues } from '@/mixins/formatValues.mixin';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';

export default {
  name: 'RolesAdminTable',
  components: {
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    roles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      singleSelect: true,
      selected: [],
      sortBy: 'name',
      sortDesc: false
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Roles",
          divider: true,
          align: 'start',
          sortable: true,
          width: '100%',
          value: 'description'
        }
      ];
    }
  },
  watch: {
    selected() {
      this.$emit('set-selected-id', this.selected);
    }
  },
  mounted() {
    // this.updateRoles();
  },
  methods: {
    updateRoles() {
      if (this.roles.some(role => role.status)) {
        this.selected = this.roles.filter(role => role.status);
      } else {
        this.selected = [];
      }
    }
  }
};
</script>

<style lang="scss">
.roles-table {
  table-layout: fixed;

  .theme--light.v-input--selection-controls.v-input--is-disabled.v-input--is-label-active .v-icon {
    color: #3dd598 !important;
  }

  .v-data-table-header__icon {
    display: none;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  .mdi-checkbox-blank-outline {
    color: red !important;
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
