import * as types from './mutation-types';

export default {
  [types.SET_USER](state, user) {
    state.user = user;
  },
  [types.SET_ROLES](state, roles) {
    state.roles = roles;
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [types.SET_ISBAN](state, isBan) {
    state.isBan = isBan;
  }
};
