<template>
  <div class="access-page">
    <div class="access-page--header">
      <div class="access-page--header-name">
        {{ siteName }}
      </div>
      <div class="sessions-agents-header--sub-list d-flex justify-space-between align-center">
        <div class="sessions-agents-header--sub-list-status d-flex justify-start align-center">
          <div v-for="(item, index) in headerValues" :key="index" class="sessions-agents-header--sub-list-status-item align-start flex-column">
            <div class="sessions-agents-header--sub-list-status-item-title">
              {{ item.text }}
            </div>
            <div class="sessions-agents-header--sub-list-status-item-value">
              {{ item.value }}
            </div>
          </div>

          <v-text-field
            v-model="search"
            class="filterSearch"
            prepend-icon="mdi-magnify"
            label="Rechercher"
            clearable
            @keydown="refreshData"
            @change="refreshData"
            @click:clear="refreshData"
          ></v-text-field>
          <div class="filterHeader">
            <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dates" label="Période d'affichage" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dates" range @change="refreshData"></v-date-picker>
            </v-menu>
          </div>
        </div>

        <div class="d-flex justify-end align-center">
          <div class="sessions-agents-header--sub-list-terrain d-flex justify-start align-center">
            <div
              class="sessions-agents-header--sub-list-next-item d-flex justify-space-between align-center"
              :class="`section--communications-${siteUnits.state}`"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    Communication 4G
                    <span> {{ siteUnits.available }}/{{ siteUnits.all }} </span>
                  </div>
                </template>
                <ul>
                  <li v-for="communcation in communicationsList" :key="communcation.id">
                    {{ communcation.name }}: <b>{{ communcation.isAvailable ? 'OK' : 'KO' }}</b> - Dernier changement:
                    <i>{{ dateFormatter(communcation.isAvailableDate) }}</i>
                  </li>
                </ul>
              </v-tooltip>
            </div>
          </div>
          <div class="sessions-agents-header--sub-list-next d-flex justify-start align-center" :class="{ active: !site.poiEnabled }" @click="poiButton">
            <div class="sessions-agents-header--sub-list-next-item d-flex justify-space-between align-center">
              <div>
                <span class="POI_switch_text" v-if="this.site.poiEnabled">Désactiver</span>
                <span class="POI_switch_text" v-else>Activer</span>
                <span id="mode_POI">Mode POI</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab href="#currentUsers">Présence en temps réel</v-tab>
        <v-tab href="#mouvements">Entrées / Sorties</v-tab>
        <v-tab href="#anomalies">Anomalies</v-tab>
        <v-tab href="#allowedUsers">Personnel autorisé</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="currentUsers">
          <AccessTable :access="currentAgents" />
        </v-tab-item>
        <v-tab-item value="mouvements">
          <MouvementTable :access="this.mouvementAgents" />
        </v-tab-item>
        <v-tab-item value="anomalies">
          <AnomaliesTable :access="this.anomaliesAgents" />
        </v-tab-item>
        <v-tab-item value="allowedUsers">
          <AllAccessTable
            :access="allowedAgents"
            :agents="freeAgents"
            @add="addAllowedSite({ siteId: siteId, ...$event })"
            @remove="deleteAllowedSite($event)"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { get, find } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import { ROUTES_NAME } from '@/common/constants';
import AccessTable from '@/components/common/tables/AccessTable';
import AllAccessTable from '@/components/common/tables/AllAccessTable';
import MouvementTable from '@/components/common/tables/MouvementTable';
import AnomaliesTable from '@/components/common/tables/AnomaliesTable';

export default {
  name: ROUTES_NAME.sectionDetails,
  components: {
    AllAccessTable,
    AccessTable,
    MouvementTable,
    AnomaliesTable
  },

  data() {
    return {
      currentAgent: null,
      dates: [
        moment().startOf("week").format('YYYY-MM-DD'),
        moment().endOf("week").format('YYYY-MM-DD')
      ],
      search: null,
      nextLoadingTask: null,
      tab: null
    };
  },

  computed: {
    ...mapGetters('site', ['allowedSitesList', 'mouvementsList', 'communicationsList', 'anomaliesList', 'actualAgentsList', 'site']),
    ...mapGetters('agents', { agentList: 'list' }),

    currentAgents() {
      return this.actualAgentsList;
    },

    mouvementAgents() {
      return this.mouvementsList;
    },

    anomaliesAgents() {
      return this.anomaliesList;
    },

    allowedAgents() {
      return this.allowedSitesList;
    },

    freeAgents() {
      // Les agents que l'ont peut ajouter à une zone ne doivent pas déjà être autorisé,
      // et doivent avoir un accès valide
      return this.agentList.filter(
        item =>
          find(this.allowedAgents, { agentId: item.id }) === undefined &&
          item.accessBeginDate !== null &&
          item.accessEndDate !== null &&
          moment().isAfter(moment(item.accessBeginDate)) &&
          moment().isBefore(moment(item.accessEndDate))
      );
    },

    headerValues() {
      return [
        {
          value: this.siteParticipants,
          text: 'Présents sur site'
        },
        {
          value: this.siteEntrance,
          text: 'Entrées du jour'
        },
        {
          value: this.siteExits,
          text: 'Sorties du jour'
        },
        {
          value: this.siteAnomalies,
          text: 'Anomalies du jour'
        }
      ];
    },

    siteName() {
      return get(this.site, 'name', '');
    },

    siteEntrance() {
      return this.mouvementAgents.filter(item => moment(item.date).isSame(new Date(), 'day') && item.type === 0).length;
    },

    siteExits() {
      return this.mouvementAgents.filter(item => moment(item.date).isSame(new Date(), 'day') && item.type === 1).length;
    },

    siteParticipants() {
      return this.currentAgents.length;
    },

    siteAnomalies() {
      return this.anomaliesList.filter(item => {
        if (moment(item.date).isSame(new Date(), 'day')) {
          return true;
        }
        return false;
      }).length;
    },

    fetchParams() {
      return {
        id: this.siteId,
        period: this.dates,
        search: this.search
      };
    },

    siteUnits() {
      let state = 'allOn';
      if (this.communicationsList.length > 0 && this.communicationsList.some(comm => !comm.isAvailable)) {
        state = 'someOff';
      }
      if (this.communicationsList.length > 0 && this.communicationsList.every(comm => !comm.isAvailable)) {
        state = 'allOff';
      }

      return {
        all: this.communicationsList.length,
        available: [...this.communicationsList.filter(comm => comm.isAvailable)].length,
        state
      };
    }
  },

  watch: {
    dates: {
      immediate: true,
      deep: true,
      handler(newDates) {
        if (newDates[1]) {
          if (moment(newDates[1]).isBefore(moment(newDates[0]))) {
            const temp = newDates[0];
            // eslint-disable-next-line prefer-destructuring
            this.dates[0] = newDates[1];
            this.dates[1] = temp;
          }
        }
      }
    }
  },

  mounted() {
    this.siteId = get(this.$route, 'params.id', null);

    if (!this.siteId) {
      // this.$router.push(ROUTES_NAME.sections);
      this.siteId = localStorage.getItem('siteId');
    }
    this.getSite(this.siteId);
    this.refreshData();
    this.getAllowedSitesList(this.fetchParams);
    this.fetchList();
  },

  methods: {
    ...mapActions('site', [
      'changePoi',
      'newAllowedSites',
      'removeAllowSite',
      'getAllowedSitesList',
      'getMouvementsList',
      'getCommunicationsList',
      'getAnomaliesList',
      'getActualAgentList',
      'getSite'
    ]),
    ...mapActions('agents', ['fetchList']),
    ...mapActions('ui', ['toggleLoader']),

    poiButton() {
      this.$modals.open({
        name: 'PoiModal',
        payload: {
          enabled: this.site.poiEnabled,
          submit: async () => {
            await this.changePoi({
              id: this.site.id,
              value: !this.site.poiEnabled
            });
            this.getSite(this.siteId);
          },
          siteName: this.siteName
        }
      });
    },

    addAllowedSite(payload) {
      this.newAllowedSites(payload).then(() => {
        this.getAllowedSitesList(this.fetchParams);
      });
    },

    deleteAllowedSite(payload) {
      this.removeAllowSite(payload).then(() => {
        this.getAllowedSitesList(this.fetchParams);
      });
    },

    refreshData() {
      if (this.nextLoadingTask !== null) {
        clearTimeout(this.nextLoadingTask);
      }

      this.nextLoadingTask = setTimeout(async () => {
        this.toggleLoader();
        await this.getActualAgentList(this.fetchParams); // Pourrait être lancé 1 seule fois au onMounted()
        // On "ment" à l'utilisateur en supprimant l'icone de chargement dès qu'on a reçu les données pour l'onglet "Présence temps réel"
        // Evite de devoir attendre que les 4 onglets soient remplis pour désactiver l'icone de chargement
        this.toggleLoader();

        this.getMouvementsList(this.fetchParams);
        this.getAnomaliesList(this.fetchParams);
        this.getCommunicationsList(this.fetchParams); // Pourrait être lancé 1 seule fois au onMounted()
      }, 300);
    },

    dateFormatter(date) {
      if (date && moment(date)) {
        return moment(date).format('DD/MM/YYYY HH:mm');
      } else {
        return 'N/A';
      }
    }
  }
};
</script>

<style lang="scss">
.access-page {
  .sessions-agents-header--sub-list-status-item-value {
    padding-left: 0;
  }

  .sessions-agents-header--sub-list-status-item:nth-child(3) {
    margin-right: 12px;
  }

  .sessions-agents-header--sub-list-next {
    background-color: #ea9999;
  }

  .sessions-agents-header--sub-list-next.active {
    background-color: #b6d7a8;
  }

  .sessions-agents-header--sub-list {
    margin-top: 0 !important;
    text-align: center;
  }

  .sessions-agents-header--sub-list-status-item {
    font-size: 15px !important;
  }

  .sessions-agents-header--sub-list-status-item-title {
    margin-top: 10px !important;
    font-weight: 700 !important;
  }

  .sessions-agents-header--sub-list-terrain {
    margin-right: 20px;
    background-color: #b6d7a8;

    span {
      display: block;
      font-weight: 700;
    }
  }

  .POI_switch_text {
    display: block;
    font-weight: 700;
  }

  padding: 0 10px;

  &--header {
    &-name {
      font-size: 36px;
      font-weight: 700;
    }
  }

  .filterSearch {
    margin-top: 10px;
    margin-left: 20px;
  }

  .filterHeader {
    margin-top: 10px;
    margin-left: 20px;
    width: 210px;
  }
}

.section--communications-allOn {
  background-color: #b6d7a8;
}

.section--communications-someOff {
  background-color: #d7cc71;
}

.section--communications-allOff {
  background-color: #ea9999;
}
</style>
