<template>
  <v-navigation-drawer class="side-menu" app expand-on-hover permanent>
    <v-list :min-width="60" class="side-menu-logo text-center pa-0">
      <v-list-item to="/" link class="fill-height px-0 py-3">
        <v-layout row align-center justify-center fill-height>
          <Logo />
        </v-layout>
      </v-list-item>
    </v-list>

    <v-divider />

    <v-list nav class="mt-n2">
      <div v-for="item in menu" :key="item.name" class="side-menu-listitem">
        <v-list-item v-if="!item.items" :to="item.to" active-class="side-menu-primary-background" :ripple="false" link>
          <v-list-item-icon>
            <SiteIcon v-if="item.icon === 'site'" />
            <AgentsIcon v-if="item.icon === 'agents'" />
            <InterviewsIcon v-if="item.icon === 'interviews'" />
            <SessionsIcon v-if="item.icon === 'sessions'" />
          </v-list-item-icon>
          <v-list-item-title class="font-weight-medium">
            {{ $t(item.name) }}
          </v-list-item-title>
        </v-list-item>
        <v-list-group v-else>
          <template v-slot:activator>
            <v-list-item-icon>
              <StatisticIcon v-if="item.icon === 'statistics'" />

              <SettingsIcon v-if="item.icon === 'settings'" />
              <AdministrationIcon v-if="item.icon === 'administration'" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="side-menu-primary font-weight-medium">
                {{ $t(item.name) }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="sub in item.items" :key="sub.value" :to="sub.to" link>
            <span class="ml-15 side-menu-sub font-weight-medium">
              {{ $t(sub.name) }}
            </span>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';

import { ROUTES, ROUTES_ROLES } from '@/common/constants';
import Logo from '@/components/common/icons/Logo';
import SiteIcon from '@/components/common/icons/navigation/Site';
import AgentsIcon from '@/components/common/icons/navigation/Agents';
import InterviewsIcon from '@/components/common/icons/navigation/Interviews';
import SessionsIcon from '@/components/common/icons/navigation/Sessions';
import SettingsIcon from '@/components/common/icons/navigation/Settings';
import AdministrationIcon from '@/components/common/icons/navigation/Administration';
import StatisticIcon from '@/components/common/icons/navigation/Statistic';

export default {
  name: 'Navigation',
  components: {
    Logo,
    SiteIcon,
    AgentsIcon,
    InterviewsIcon,
    SessionsIcon,
    SettingsIcon,
    AdministrationIcon,
    StatisticIcon
  },
  props: {
    alertUnseenCount: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      showChild: false
    };
  },
  computed: {
    ...mapState('auth', { loggedUserRoles: state => state.roles }),
    menu() {
      const menuArray = [
        {
          name: 'Détails zones',
          requiredRole: ROUTES_ROLES.sectionDetails,
          icon: 'site',
          to: ROUTES.sectionDetails
        },
        {
          name: 'Agents',
          requiredRole: ROUTES_ROLES.agents,
          icon: 'agents',
          to: ROUTES.agents
        },
        {
          name: 'Sessions',
          requiredRole: ROUTES_ROLES.sessions,
          icon: 'sessions',
          to: ROUTES.sessions
        },
        {
          name: 'Entretiens',
          requiredRole: ROUTES_ROLES.interviews,
          icon: 'interviews',
          to: ROUTES.interviews
        },
        {
          name: 'Statistiques',
          icon: 'statistics',
          items: [
            {
              name: 'Chantiers',
              requiredRole: ROUTES_ROLES.statistics,
              to: ROUTES.statistics
            },
            {
              name: 'Formations',
              requiredRole: ROUTES_ROLES.statistics,
              to: ROUTES.statisticsFormation
            }
          ]
        },
        {
          name: 'Paramètres',
          icon: 'settings',
          items: [
            {
              name: 'Thèmes',
              requiredRole: ROUTES_ROLES.questionsThemes,
              to: ROUTES.questionsThemes
            },
            {
              name: 'Quiz',
              requiredRole: ROUTES_ROLES.formManagement,
              to: ROUTES.formManagement
            },
            {
              name: 'Entreprises',
              requiredRole: ROUTES_ROLES.enterprise,
              to: ROUTES.enterprise
            },
            {
              name: 'Corps de métier',
              requiredRole: ROUTES_ROLES.profession,
              to: ROUTES.profession
            },
            { name: 'Profils', requiredRole: ROUTES_ROLES.profile, to: ROUTES.profile }
          ]
        },
        {
          name: 'Administration',
          requiredRole: ROUTES_ROLES.administration,
          icon: 'administration',
          items: [
            {
              name: 'Comptes',
              requiredRole: ROUTES_ROLES.accounts,
              to: ROUTES.accounts
            },
            {
              name: 'Logs',
              requiredRole: ROUTES_ROLES.logs,
              to: ROUTES.logs
            },
            {
              name: 'Roles',
              requiredRole: ROUTES_ROLES.logs, // ROUTES_ROLES.roles
              to: ROUTES.roles
            },
            {
              name: 'Zones',
              requiredRole: ROUTES_ROLES.sites,
              // icon: 'site',
              to: ROUTES.sites
            },
            {
              name: 'IP Bannies',
              requiredRole: ROUTES_ROLES.banList,
              // icon: 'site',
              to: ROUTES.banIPList
            }
          ]
        }
      ];
      return menuArray.filter(item => {
        for (let i in this.loggedUserRoles) {
          if (this.loggedUserRoles[i].permissionSite === localStorage.getItem('siteId')) {
            // vérification PermissionSite ID === Au site ID sélectionné
            if (this.loggedUserRoles[i].permissionName === item.requiredRole) {
              return true;
            }
            if (item.items) {
              for (let x in item.items) {
                if (this.loggedUserRoles[i].permissionName === item.items[x].requiredRole) {
                  return true;
                }
              }
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.side-menu {
  color: #484848;

  .v-list-item--link:before {
    background-color: #484848 !important;
  }

  .v-list-item--active.theme--light {
    background-color: white !important;
  }

  &-logo {
    background-color: white !important;
  }

  .v-list-item {
    &--active {
      background-color: #f6f6f6 !important;
      color: #484848 !important;
    }
  }
}
</style>
