import * as types from './mutation-types';

import { fetchAll } from '@/services/logs.service';

export default {
  async fetchList({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAll();
      commit(types.SET_LOGS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
