<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="change-item-modal">
      <div class="change-item-modal--header font-weight-light">
        Changer de mot de passe
      </div>
      <div class="change-item-modal--content">
        <div class="change-item-modal--content-input d-flex justify-center">
          <v-form
            ref="form"
            :lazy-validation="true"
            class="change-item-modal--content-input-form"
            @submit="
              e => {
                e.preventDefault();
                submit();
              }
            "
          >
            <v-container>
              <v-text-field
                ref="label"
                v-model="formData.oldPassword"
                :label="'Ancien mot de passe'"
                outline
                :rules="[nonEmpty, validPassword]"
                type="password"
                autocomplete="current-password"
                @change="resetError"
              >
              </v-text-field>
              <v-text-field
                v-model="formData.newPassword"
                :label="'Nouveau mot de passe'"
                outline
                :rules="[nonEmpty, ...passwordRules()]"
                type="password"
                autocomplete="new-password"
              >
              </v-text-field>
              <v-text-field
                v-model="formData.newPasswordVerif"
                :label="'Confirmation du nouveau mot de passe'"
                outline
                :rules="[isSame, nonEmpty]"
                type="password"
                autocomplete="new-password"
              >
              </v-text-field>
            </v-container>
          </v-form>
        </div>
      </div>
      <div class="change-item-modal--buttons d-flex justify-end">
        <v-btn class="change-item-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="change-item-modal--buttons-save" color="#38BB00" dark depressed :disabled="isErrors()" @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import { mapGetters } from 'vuex';

import { selfChangePassword } from '../../services/accounts.service';

import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'ChangePassword',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      error: null,
      errors: null,
      formData: {
        oldPassword: '',
        newPassword: '',
        newPasswordVerif: ''
      }
    };
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  mounted() {
    // On auto focus le champs title quand la modale est montée
    const el = this.$refs.label;
    setTimeout(() => el.focus(), 100);
    this.errors = this.$refs.form.errorBag;
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    isErrors() {
      let res = true;
      if (this.$refs.form) {
        const errorBag = Object.values(this.errors);
        res = errorBag.some(errValue => errValue);
      }
      return res;
    },
    resetError() {
      this.error = null;
      this.$refs.form.validate();
    },
    validPassword() {
      return this.error === null || 'Mot de passe Incorrect';
    },

    passwordRules() {
      return [
        v => v.length > 5 || 'Le mot de passe doit contenir au moins 6 caractères',
        v => v.length <= 30 || 'Le mot de passe ne doit pas dépasser les 30 caractères',
        v => /^[a-zA-Z0-9]{6,30}$/.test(v) || 'Le mot de passe ne doit contenir que des chiffres ou lettres'
      ];
    },
    isSame(val) {
      return val === this.formData.newPassword || 'Les mots de passes doivent être identiques';
    },
    nonEmpty(val) {
      return (val !== '' && val !== null && val !== undefined) || 'Ce champ ne doit pas être vide';
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      try {
        const res = await selfChangePassword({ id: this.user.id, ...this.formData });
        if (res) {
          this.error = null;
        }
        this.close();
      } catch (err) {
        this.error = err;
        this.$refs.label.focus();
        this.$refs.label.blur();
        this.$refs.form.validate();
      }
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.change-item-modal {
  &--header {
    text-align: center;
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 236px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
