<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="qr-modal">
      <div class="qr-modal--header font-weight-light">
        Appareillage de la tablette à <span class="font-weight-medium">{{ agentName }}</span>
      </div>
      <div class="qr-modal--content">
        <div class="qr-modal--content-label font-weight-medium">
          Scanner le QrCode avec la tablette avant de la donner au stagiaire
        </div>
        <div class="qr-modal--content-qr">
          <qrcode-vue :value="agentSessionId" :size="150" level="H" />
        </div>
      </div>
      <div class="qr-modal--buttons d-flex justify-end">
        <v-btn class="qr-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'QrModal',
  components: {
    BasePopupModal,
    QrcodeVue
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    agentName() {
      return this.modal.payload.agentName;
    },
    agentSessionId() {
      return this.modal.payload.agentSessionId;
    }
  },
  beforeDestroy() {
    this.close();
  },
  mounted() {
    this.modal.payload.waitForQr(this.agentSessionId);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit(code);

        this.close();
      }
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.qr-modal {
  &--header {
    text-align: center;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 343px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-qr {
      margin-top: 50px;
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
