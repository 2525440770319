<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="permissions"
      hide-default-footer
      show-select
      fixed-header
      height="350"
      class="roles-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      disable-pagination
    >
      <template v-slot:header.name="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="agents-table--sort-icon" />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import { formatValues } from '@/mixins/formatValues.mixin';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';

export default {
  name: 'PermissionsTable',
  components: {
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    permissions: {
      type: Array,
      default: () => []
    },
    selectedId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      singleSelect: true,
      selected: [],
      sortBy: ['name'],
      sortDesc: false
    };
  },
  computed: {
    ...mapGetters('site', ['selectZone']),
    headers() {
      return [
        {
          text: "Permissions",
          divider: true,
          align: 'start',
          sortable: false,
          width: '100%',
          value: 'description'
        }
      ];
    }
  },
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (
          oldValue !== null &&
          newValue !== null &&
          oldValue !== undefined &&
          newValue !== undefined &&
          (newValue.some((value, index) => value !== oldValue[index]) || newValue.length !== oldValue.length)
        ) {
          // Check if not initial render or actual change inside arrays
          const permissions = newValue.map(item => item.id);
          const siteId = this.selectZone;
          this.getPermissionStatus({ permissions, siteId });
        }
      }
    },
    selectZone: {
      immediate: true,
      deep: false,
      handler() {
        this.updatePermissions();
      }
    },
    permissions: {
      immediate: true,
      deep: true,
      handler() {
        this.updatePermissions();
      }
    }
  },
  mounted() {
    this.updatePermissions();
  },
  methods: {
    ...mapActions('accounts', ['getPermissionStatus']),
    updatePermissions() {
      if (this.permissions.some(item => item.status)) {
        this.selected = this.permissions.filter(item => item.status);
      } else {
        this.selected = [];
      }
    }
  }
};
</script>

<style lang="scss">
.roles-table {
  table-layout: fixed;

  .theme--light.v-input--selection-controls.v-input--is-disabled.v-input--is-label-active .v-icon {
    color: #3dd598 !important;
  }

  .v-data-table-header__icon {
    display: none;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  .mdi-checkbox-blank-outline {
    color: red !important;
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
