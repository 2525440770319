import { getToken } from '@/services/helpers/token';

export const auth = {
  computed: {
    isAuth() {
      const invalidToken = [null, undefined, ''];
      return !invalidToken.includes(getToken().accessToken);
    }
  }
};
