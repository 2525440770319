import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { languages, messages } from './messages';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'fr',
  fallbackLocale: languages.fr,
  messages
});
