<template>
  <div class="profession-page">
    <ProfessionTable :data="list" :text="text" @add="addItem($event)" @edit="changeItemById($event)" @remove="removeItem($event)"></ProfessionTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ProfessionTable from '@/components/common/tables/ProfessionTable';

export default {
  name: 'ProfessionView',
  components: {
    ProfessionTable
  },
  data() {
    return {
      text: {
        table: 'Corps de métier',
        add: {
          title: "Ajout d'un corps de métier"
        },
        edit: {
          title: "Modification"
        },
        remove: {
          title: "Suppression",
          subTitle: "Êtes-vous sûr de vouloir supprimer le corps de métier ?"
        }
      }
    };
  },
  computed: {
    ...mapGetters('professions', ['list'])
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    ...mapActions('professions', ['fetchList', 'removeItem', 'addItem', 'changeItemById'])
  }
};
</script>

<style>
.profession-page {
  padding: 0 10px;
}
</style>
