export default {
  methods: {
    questionType(type) {
      switch (type) {
        case 0:
          return 'Choix multiple';
        case 1:
          return 'Reponse libre';
        case 2:
          return 'Support papier';
        default:
          break;
      }
    }
  }
};
