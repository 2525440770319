import * as types from './mutation-types';

export default {
  [types.SET_LIST](state, list) {
    state.list = list;
  },
  [types.SET_PROFILES](state, profiles) {
    state.profiles = profiles;
  },
  [types.SET_ENTERPRISES](state, enterprises) {
    state.enterprises = enterprises;
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [types.SET_AGENT](state, agent) {
    state.agent = agent;
  }
};
