<template>
  <div class="profile-page">
    <SimpleTable v-if="list.length" :data="list" :text="text" @add="addItem($event)" @edit="changeItemById($event)" @remove="removeItem($event)" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { ROUTES_NAME } from '@/common/constants';
import SimpleTable from '@/components/common/tables/SimpleTable';

export default {
  name: ROUTES_NAME.profile,
  components: {
    SimpleTable
  },
  data() {
    return {
      text: {
        table: 'profile',
        add: {
          title: "Ajout de profil"
        },
        edit: {
          title: "Modification"
        },
        remove: {
          title: "Suppression",
          subTitle: "Êtes-vous sûr de vouloir supprimer le profil ?"
        }
      }
    };
  },
  computed: {
    ...mapGetters('profile', ['list'])
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    ...mapActions('profile', ['fetchList', 'removeItem', 'addItem', 'changeItemById'])
  }
};
</script>

<style lang="scss">
.profile-page {
  padding: 0 10px;
}
</style>
