import * as types from './mutation-types';

import { getHours, getAnomalies, stat, fixAnomaly } from '@/services/statistics.service';

export default {
  async fetchHours({ commit }, params = '') {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getHours(params);
      commit(types.SET_HOURS_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async fetchAnomalies({ commit }, params = '') {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getAnomalies(params);
      commit(types.SET_ANOMALIES_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async fetchStats({ commit }, params = '') {
    commit(types.SET_ERRORS, null);
    try {
      const data = await stat(params);
      commit(types.SET_STATS_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async correctAnomaly({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await fixAnomaly(id, data);
      dispatch('fetchAnomalies');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
