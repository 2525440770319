<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="accounts-modal">
      <div class="accounts-modal--header font-weight-light">
        {{ "Roles et Permissions : " + this.nameUser }}
      </div>
      <div class="accounts-modal--content">
        <ZoneSelectTable :global-zone="false" />
        <div class="accounts-modal--role-permission d-flex justify-center ">
          <v-col cols="12" class="d-flex justify-space-between accounts-page--tables">
            <v-col cols="6" class="accounts-page--accounts">
              <RolesTable :key="roles.id" :selected-id="selected" :roles="roles" />
            </v-col>
            <v-col cols="6" class="accounts-page--roles">
              <PermissionsTable :key="permissions.id" :selected-id="selected" :permissions="permissions" />
            </v-col>
          </v-col>
        </div>
      </div>
      <div class="accounts-modal--buttons d-flex justify-end">
        <v-btn class="accounts-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="accounts-modal--buttons-save" color="#38BB00" dark depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import { get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import BasePopupModal from '@/components/modals/base/BasePopupModal';
import RolesTable from '@/components/common/tables/RolesTable';
import PermissionsTable from '@/components/common/tables/PermissionsTable';
import ZoneSelectTable from '@/components/common/tables/ZoneSelectTable';

export default {
  name: 'ChangeRolePermissionForUser',
  components: {
    BasePopupModal,
    RolesTable,
    PermissionsTable,
    ZoneSelectTable
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      type: 1,
      roleWithPermissions: []
    };
  },
  computed: {
    ...mapGetters('accounts', ['changeRoles', 'changePermission']),
    ...mapGetters('site', ['selectZone']),
    selected() {
      return this.modal.payload.selected;
    },
    nameUser() {
      return this.modal.payload.nameUser;
    },
    roles() {
      return this.modal.payload.roles;
    },
    permissions() {
      return this.modal.payload.permissions;
    },
    permissionByRole() {
      return this.modal.payload.permissionByRole;
    }
  },
  watch: {
    selectZone: {
      immediate: true,
      deep: false,
      handler(newValue) {
        this.changeRoleFromZone(newValue);
        this.changePermissionFromZone(newValue);
      }
    },
    changeRoles: {
      immediate: true,
      deep: false,
      handler(newValue, oldValue) {
        if (oldValue !== null && newValue !== null && oldValue !== undefined && newValue !== undefined) {
          this.changePermissionByRole();
        }
      }
    }
  },
  mounted() {
    this.defaultPermissionByRole();
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    ...mapActions('accounts', ['setPermissionForTableAccount', 'setChangeStatusModal']),
    close() {
      this.setChangeStatusModal(false);
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit({
          userId: this.selected,
          siteId: this.selectZone,
          roles: this.changeRoles.roles,
          permissions: this.changePermission.permissions
        });

        this.close();
      }
    },
    changeRoleFromZone(zone) {
      //changement de zone, remise a zero du selected
      this.roles.map(item => (item.status = false));
      const roleChange = this.roles;

      roleChange.forEach(item => {
        item.status = item.zoneId.includes(zone);
      });
      this.roles = roleChange;
    },
    changePermissionFromZone(zone) {
      const permissionChange = this.modal.payload.permissions;

      permissionChange.forEach(item => {
        item.status = item.zoneId.includes(zone);
      });
      this.modal.payload.permissions = permissionChange;
    },
    changePermissionByRole() {
      if (this.changeRoles.roles.length < 1) {
        this.permissions.map(item => (item.status = false));
      }

      // récupération des données BDD
      const checkingPerm = this.permissions.find(element => element.zoneId.includes(this.selectZone));

      if (!checkingPerm || this.changeRoles.roles) {
        const idRole = this.changeRoles.roles;
        idRole.map(element => {
          this.roleWithPermissions.forEach(item => {
            if (element === item.role) {
              this.permissions.map(value => {
                if (item.permission.includes(value.id)) {
                  value.status = true;
                }
              });
            }
          });
        });
      }
    },
    defaultPermissionByRole() {
      let tempRole = [];
      let setNumber = 0;

      this.roles.map((item, index) => {
        if (setNumber === index) {
          setNumber++;
          tempRole[tempRole.length] = {
            role: item.id,
            permission: []
          };
          this.permissionByRole.forEach(element => {
            let tempPermission = tempRole[index].permission;

            if (item.id === element.roleId) {
              tempPermission[tempPermission.length] = element.permissionId;
            }
          });
        }
      });
      this.roleWithPermissions = tempRole;
    }
  }
};
</script>

<style lang="scss">
.accounts-modal--role-permission {
  margin-top: 1rem !important;
}

.modals-container {
  border: 1px solid #ffffff;
}

.accounts-modal {
  &--header {
    text-align: center;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 436px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
