<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="themes-modal">
      <div class="themes-modal--header font-weight-light">
        {{ $t(`modals.questionsTheme.${modalType}.title`) }}
      </div>
      <div class="themes-modal--content">
        <div class="themes-modal--content-input d-flex justify-center">
          <v-form class="themes-modal--content-input-form">
            <v-container>
              <v-text-field ref="label" v-model="formData.label" :label="'Libellé'" :rules="labelRules" outline> </v-text-field>
            </v-container>
          </v-form>
        </div>
      </div>
      <div class="themes-modal--buttons d-flex justify-end">
        <v-btn class="themes-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="themes-modal--buttons-save" color="#38BB00" :disabled="isDisabled" dark depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import { get, find } from 'lodash';

import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'QuestionsThemeModal',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        label: ''
      },
      type: 1,
      maxNumber: 20,
      minNumber: 0
    };
  },
  computed: {
    modalType() {
      const modal = ['edit', 'new'];
      return modal[this.type];
    },
    labelRules() {
      return [
        v =>
          !!v.match(/^[a-z0-9 `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/i) ||
          "Le libellé n'est pas correct",
        v => v.length > 0 || "Le libellé n'est pas correct",
        v => v.length < 51 || "Le libellé n'est pas correct"
      ];
    },
    isDisabled() {
      const disabled =
        !!this.formData.label.match(/^[a-z0-9 `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/i) &&
        this.formData.label.length > 0 &&
        this.formData.label.length < 51;
      return !disabled;
    },
    oldValues() {
      return this.modal.payload.oldValues;
    }
  },
  mounted() {
    if (this.oldValues) {
      this.formData = this.oldValues;
      this.type = 0;
      this.formData.formId = get(find(this.forms, { name: this.oldValues.formId }), 'id', null);
    }

    // On auto focus le champs label quand la modale est montée
    const el = this.$refs.label;
    setTimeout(() => el.focus(), 100);
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit(this.formData);
        this.close();
      }
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.themes-modal {
  &--header {
    text-align: center;
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 250px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
