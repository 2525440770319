<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="free-question-modal">
      <div class="free-question-modal--header font-weight-light">
        Correction question {{ ` ${questionNumber} — ${agentName}` }}
      </div>
      <div class="free-question-modal--content">
        <div class="free-question-modal--content-label font-weight-medium">
          {{ questionLabel }}
        </div>
        <div class="free-question-modal--content-form">
          <div class="free-question-modal--content-item">
            <div class="free-question-modal--content-item-label">
              Réponse donnée:
            </div>
            <div class="free-question-modal--content-item-value">
              {{ answer }}
            </div>
          </div>
          <div v-if="goodAnswers" class="free-question-modal--content-item">
            <div class="free-question-modal--content-item-label">
              Réponse attendue:
            </div>
            <div class="free-question-modal--content-item-value">
              {{ goodAnswers }}
            </div>
          </div>
          <div class="free-question-modal--content-item">
            <div class="free-question-modal--content-item-label">
              Nombre de points obtenus:
            </div>
            <div class="free-question-modal--content-item-value">
              <v-text-field
                v-model="agentMark"
                class="free-question-modal--content-item-value-input"
                type="number"
                solo
                outline
                height="24"
                :change="checkMark()"
              ></v-text-field>
              <span class="free-question-modal--content-item-value-max">
                {{ `/${maxPoint}` }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="free-question-modal--buttons d-flex justify-end">
        <v-btn class="free-question-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="free-question-modal--buttons-save" color="#38BB00" dark depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'FreeQuestionModal',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      agentMark: 0
    };
  },
  computed: {
    agentName() {
      return this.modal.payload.agentName;
    },
    questionNumber() {
      return this.modal.payload.questionNumber;
    },
    questionLabel() {
      return this.modal.payload.question.label;
    },
    answer() {
      return this.modal.payload.question.answer;
    },
    goodAnswers() {
      return this.modal.payload.question.goodAnswers;
    },
    maxPoint() {
      return this.modal.payload.question.maxPoint;
    }
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit(this.agentMark);

        this.close();
      }
    },
    checkMark() {
      if (Number(this.agentMark) > Number(this.maxPoint)) {
        this.$nextTick(() => {
          this.agentMark = Number(this.maxPoint);
        });
      }
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.free-question-modal {
  &--header {
    text-align: center;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 343px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 46px;
      text-align: center;
    }

    &-form {
      margin-top: 30px;
      width: 80%;
      margin-left: 10%;
    }

    &-item {
      text-align: left;
      &-label,
      &-value {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
      }

      &-label {
        color: #17172580;
      }

      &-value {
        color: #171725;

        .v-text-field {
          width: 56px;
          height: 24px;
          float: left;
        }

        .v-text-field.v-text-field--solo .v-input__control {
          min-height: auto !important;
        }

        &-max {
          margin-left: 5px;
        }
      }
    }

    &-item:nth-child(2),
    &-item:nth-child(3) {
      margin-top: 27px;
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
