import request from '@/services/helpers/request';
import { API_ROUTES } from '@/config';

export const getbyId = async id =>
  (await request.getInstance()).get(`${API_ROUTES.sessions.session}/${id}`);
export const getInfo = async id =>
  (await request.getInstance()).get(`${API_ROUTES.sessions.getSessionInfo}/${id}`);
export const fetchAll = async () =>
  (await request.getInstance()).get(API_ROUTES.sessions.fetchAll);
export const remove = async id =>
  (await request.getInstance()).delete(`${API_ROUTES.sessions.session}/${id}`);
export const change = async (id, data) =>
  (await request.getInstance()).put(`${API_ROUTES.sessions.session}/${id}`, data);
export const add = async data =>
  (await request.getInstance()).post(API_ROUTES.sessions.new, data);
export const addAgent = async (id, agentId) =>
  (await request.getInstance()).post(`${API_ROUTES.sessions.addAgent.replace(':id', id)}/${agentId}`);
export const addAgents = async (id, agentIds) =>
  (await request.getInstance()).post(`${API_ROUTES.sessions.addAgents.replace(':id', id)}`, agentIds);
export const removeAgent = async (id, agentId) =>
  (await request.getInstance()).delete(`${API_ROUTES.sessions.removeAgent.replace(':id', id)}/${agentId}`);
export const removeAgents = async (id, agentIds) =>
  (await request.getInstance()).post(`${API_ROUTES.sessions.removeAgents.replace(':id', id)}`, {agentIds});
export const setState = async (id, data) =>
  (await request.getInstance()).put(API_ROUTES.sessions.setState.replace(':id', id), data);
export const getAnswers = async id =>
  (await request.getInstance()).get(API_ROUTES.sessions.getAnswers.replace(':id', id));
export const getQuestions = async id =>
  (await request.getInstance()).get(API_ROUTES.sessions.getQuestions.replace(':id', id));
export const getCurrentQuestion = async id =>
  (await request.getInstance()).get(API_ROUTES.sessions.getCurrentQuestion.replace(':id', id));
// export const setQuestion = async (sessionId, questionId) =>
//   (await request.getInstance()).put(`${API_ROUTES.sessions.setQuestion.replace(':id', sessionId)}/${questionId}`);
export const setNextQuestion = async (sessionId) =>
  (await request.getInstance()).post(`${API_ROUTES.sessions.setNextQuestion.replace(':id', sessionId)}`);
export const setUserResult = async (sessionAgentId, questionId, data) =>
  (await request.getInstance()).put(`${API_ROUTES.sessions.setUserResult.replace(':id', sessionAgentId)}/${questionId}`, data);
export const sessionEnd = async (sessionId, data) =>
  (await request.getInstance()).put(`${API_ROUTES.sessions.sessionEnd.replace(':id', sessionId)}`, data);
