import { LAST_ROUTE_STORAGE_KEY } from '@/common/constants';

export const setLastRoute = () => {
  localStorage.setItem(LAST_ROUTE_STORAGE_KEY, window.location.pathname);
};

export const getLastRoute = () => {
  localStorage.getItem(LAST_ROUTE_STORAGE_KEY);
};

export const clearLastRoute = () => {
  localStorage.setItem(LAST_ROUTE_STORAGE_KEY, '');
};
