import { pick, find, sortBy } from 'lodash';

import * as types from './mutation-types';

export default {
  [types.SET_SESSIONS](state, sessions) {
    state.sessions = sessions.map(item => {
      const { session } = item;
      return { ...pick(item, ['formName']), ...session };
    });
  },
  [types.SET_SESSION](state, sessionItem) {
    const { session } = sessionItem;
    state.session = {
      ...pick(sessionItem, ['formName', 'numberDeviceToPaire', 'numberOfAgents', 'numberOfPairedDevice']),
      ...session
    };
  },
  [types.SET_SESSIONS_QUESTIONS](state, questions) {
    state.questions = questions;
  },
  [types.SET_SESSIONS_CURRENT_QUESTIONS](state, question) {
    state.currentQuestion = question;
  },
  [types.SET_SESSIONS_ANSWERS](state, answers) {
    let questionsToCorrect = 0;

    const sortedQuestions = sortBy(state.questions, 'position');

    state.answers = answers.map(item => {
      const agent = `${item.agent.lastname} ${item.agent.firstname}`;

      const agentAnswer = [];

      sortedQuestions.forEach(question => {
        const questionId = question.id;

        const answer = find(item.answers, { formQuestionId: questionId });

        if (answer) {
          const { goodAnswers, type, maxPoint, label } = question;

          let freeQuestion = {};

          if (type === 2 || type === 1) {
            freeQuestion = { label, goodAnswers };
            if (answer.result === null) {
              questionsToCorrect += 1;
            }
          }

          agentAnswer.push({
            sessionAgentId: item.sessionAgent.id,
            formQuestionId: questionId,
            answer: answer.answer,
            result: answer.result,
            maxPoint,
            type,
            freeQuestion
          });
        } else {
          agentAnswer.push({ answer: null, result: null, maxPoint: 0 });
        }
      });

      return { agent, agentId: item.sessionAgent.id, agentAnswer };
    });

    if (questionsToCorrect === 0) {
      state.isCanFinish = true;
    } else {
      state.isCanFinish = false;
    }
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  }
};
