<template>
  <div>
    <select v-model="selected" class="selector">
      <option v-for="zone in zones" :key="zone.id" class="test" :value="zone.value">
        {{ zone.text }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ZoneSelectTable',
  props: {
    globalZone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      zones: [],
      selected: localStorage.getItem('siteId'),
      siteIdAccount: localStorage.getItem('siteId')
    };
  },
  computed: {
    ...mapGetters('site', ['listPermission'])
  },
  watch: {
    selected(newValue) {
      this.getSelectZoneForAccount(newValue);
      localStorage.setItem('siteIdAccount', newValue); // <- a supprimer
      if (this.globalZone) {
        localStorage.setItem('siteId', newValue);
        window.location.reload();
        this.siteIdAccount = localStorage.getItem('siteIdAccount'); // <- a supprimer ?
      }
    }
  },
  async mounted() {
    await this.fetchSites();
    await this.getListToPermission();
    this.redefineZone();
    await this.getSelectZoneForAccount(localStorage.getItem('siteIdAccount'));
  },
  methods: {
    ...mapActions('site', ['fetchSites', 'getSelectZoneForAccount', 'getListToPermission']),
    redefineZone() {
      const newSite = [];
      this.listPermission.forEach(site => {
        newSite.push({ id: site.id, text: `Zone ${site.name}`, value: site.id });
      });
      this.zones = newSite;
      localStorage.setItem('siteIdAccount', this.siteIdAccount);
    }
  }
};
</script>

<style lang="scss">
.selector {
  appearance: auto;
  margin: 0.5rem;
  padding: 0.5rem !important;
  border: 1px solid #3f3f44;
  border-radius: 1rem;
}
</style>
