import { groupBy } from 'lodash';

import * as types from './mutation-types';

export default {
  [types.SET_FORMS_LIST](state, list) {
    state.formsList = list;
  },
  [types.SET_QUESTIONS_LIST](state, list) {
    state.questionsByFormId = groupBy(list, 'formId');
  },
  [types.SET_CURRENT_FORM_ID](state, id) {
    state.currentFormId = id;
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  }
};
