export default {
  roles: state => state.roles,
  permissions: state => state.permissions,
  accounts: state => state.accounts,
  errors: state => state.errors,
  allPermissions: state => state.allPermissions,
  permissionsToAccount: state => state.permissionsToAccount,
  allRolesUserList: state => state.allRolesUserList,
  zoneList: state => state.zoneList,

  changeRoles: state => state.changeRoles,
  changePermission: state => state.changePermission,
  changeModal: state => state.changeModal
};
