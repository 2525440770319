export const ROUTES = {
  login: '/login',
  agents: '/agents',
  agentsDetails: '/agents/:id',
  interviews: '/interviews',
  sessions: '/sessions',
  sessionDetails: '/sessions/:id',
  settings: '/settings',
  accounts: '/accounts',
  logs: '/logs',
  questionsThemes: '/questions-themes',
  enterprise: '/enterprise',
  profession: '/profession',
  profile: '/profile',
  statistics: '/statistics',
  statisticsFormation: '/statistics-formations',
  formManagement: '/formManagement',
  sectionDetails: `/sections/${localStorage.getItem('siteId')}`,
  sections: '/sections',
  roles: '/roles', // AJOUT pour les nouvelles pages Roles & Sites
  sites: '/sites', // A SUPPRIMER
  banIPList: '/banIPList'
};

export const ROUTES_NAME = {
  login: 'Login',
  agents: 'Agents',
  interviews: 'Interviews',
  sessions: 'Sessions',
  sessionDetails: 'DetailSession',
  settings: 'Settings',
  accounts: 'Accounts',
  logs: 'Logs',
  questionsThemes: 'Questions Themes',
  enterprise: 'Enterprise',
  profession: 'Corps de métier',
  profile: 'Profile',
  statistics: 'Statistics',
  formManagement: 'FormManagement',
  agentsDetails: 'AgentsDetails',
  sectionDetails: 'SectionDetails',
  sections: 'Sections',
  roles: 'Roles', // AJOUT pour la nouvelle pages Roles
  sites: 'Sites', // AJOUT pour le déplacement de la page Section
  banList: 'BanIPList'
};

export const ROUTES_ROLES = {
  sectionDetails: 'Site View', // ZONE
  agents: 'Agent view', // AGENT
  sessions: 'Session management', // SESSION
  interviews: 'Interviewer', // INTERVIEWS
  statistics: 'Statistics Management', // statistiques
  questionsThemes: 'Questions themes management', // PARAMS - QUEST THEME
  formManagement: 'Form management', // PARAMS - FORM MANAG
  enterprise: 'Enterprise settings management', // PARAMS - INTERVIEW SETTINGS
  profession: 'Enterprise settings management', // PARAMS - INTERVIEW SETTINGS
  profile: 'Agent profile settings management', // PARAMS - PROFILE AGENT MANAG
  accounts: 'Account Management', // ADMIN - COMPTE
  logs: 'Log Management', // ADMIN - LOG
  roles: 'Roles Management', // ADMIN - ROLES
  sites: 'Sites Management', // ADMIN - SITES
  banList: 'Ban IP list Management' // ADMIN - BAN LIST
};

export const LAST_ROUTE_STORAGE_KEY = 'lastRoute';

export const VUETIFY_CUSTOM_THEME_COLORS = {
  primary: '#696974',
  success: '#8bc2aa',
  error: '#e4a0a0',
  warning: '#f1df8f',
  secondary: '#0F3859'
};

export const TOKEN_STORAGE_KEY = {
  accessToken: 'accessToken'
};

export const STATE_VALUE = ['En préparation', 'Démarrage', 'Quiz en cours', 'Quiz terminé', 'Session terminée'];
