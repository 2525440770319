import * as types from './mutation-types';

import { fetchAll, newItem, change, remove, agentAccess } from '@/services/agentAccess.service';

export default {
  async fetchAccess({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAll();
      commit(types.SET_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async fetchAgentAccess({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await agentAccess(id);
      commit(types.SET_AGENT_ACCESS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async newAccess({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await newItem(id, data);
      dispatch('fetchAgentAccess', id);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async changeAccess({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await change(id, data);
      dispatch('fetchAgentAccess', data.agentId);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async removeAccess({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      await remove(id);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
