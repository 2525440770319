<template>
  <div id="statsContainer">
    <div style="width: 100%; height:auto;" class=" d-flex justify-end align-center  pa-2 ">
      <div v-if="fullStats && fullStats.length">
        <div class="sessions-control--export" @click.stop.prevent="exportData">
          <div class="d-flex justify-end align-center font-weight-medium">
            Exporter
          </div>
        </div>
      </div>
    </div>
    <span v-if="!loading">
      <div v-for="(profession, index) in fullStats" :key="index" class="my-4">
        <h3 class="ml-2">{{ profession.label }}</h3>
        <v-data-table
          class="statsTable"
          :loading="loading"
          fixed-header
          :headers="headers"
          :items="profession.enterprises"
          :disable-pagination="true"
          :hide-default-footer="true"
          :footer-props="{
            'disable-pagination': true
          }"
        >
          <template v-slot:header="">
            <thead>
              <tr>
                <th colspan="1" class="top-headers"></th>
                <th colspan="5" class="top-headers">Formations</th>
                <th colspan="5" class="top-headers">Entretiens</th>
              </tr>
            </thead>
          </template>
        </v-data-table>
      </div>
    </span>
    <div v-if="loading">
      <v-data-table id="statsTable" :loading="loading" fixed-header :headers="headers"> </v-data-table>
    </div>
  </div>
</template>

<script>
import { json2excel } from 'js2excel';
// import moment from 'moment';

import { getStats } from '../../../services/statsFormations.service';

export default {
  props: {
    generalParams: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      stats: null,
      loading: false,
      headers: [
        {
          text: 'Entreprise',
          divider: true,
          align: 'start',
          sortable: true,
          value: 'name'
        },

        // FORMÉS
        {
          text: 'Total',
          divider: true,
          align: 'start',
          value: 'nbAgentsTrained'
        },

        // reçu
        {
          text: 'Reçus',
          divider: true,
          align: 'start',
          value: 'nbSuccessTraining'
        },
        {
          text: '%',
          divider: true,
          align: 'start',
          value: 'nbSuccessTrainingPercent'
        },

        // recalés
        {
          text: 'Recalés',
          divider: true,
          align: 'start',
          value: 'nbFailedTraining'
        },
        {
          text: '%',
          divider: true,
          align: 'start',
          value: 'nbFailedTrainingPercent'
        },

        // ENTRETIENS

        // en attentes
        {
          text: 'En attente',
          divider: true,
          align: 'start',
          value: 'nbInterviewsAwaiting'
        },

        // reçus
        {
          text: 'Reçus',
          divider: true,
          align: 'start',
          value: 'nbSuccessInterview'
        },
        {
          text: '%',
          divider: true,
          align: 'start',
          value: 'nbSuccessInterviewPercent'
        },

        // recalés
        {
          text: 'Recalés',
          divider: true,
          align: 'start',
          value: 'nbFailedInterview'
        },
        {
          text: '%',
          divider: true,
          align: 'start',
          value: 'nbFailedInterviewPercent'
        }
      ]
    };
  },
  computed: {
    fullStats() {
      if (this.stats) {
        return this.stats.map(profession => ({
          label: profession.label === 'noProfession' ? 'Autres' : profession.label,
          enterprises: profession.enterprises.map(enterprise => ({
            ...enterprise,
            name: enterprise.name,
            nbSuccessTrainingPercent: ((enterprise.nbSuccessTraining * 100) / enterprise.nbAgentsTrained || 0).toFixed(1),
            nbFailedTraining: enterprise.nbAgentsTrained - enterprise.nbSuccessTraining,
            nbFailedTrainingPercent: (((enterprise.nbAgentsTrained - enterprise.nbSuccessTraining) * 100) / enterprise.nbAgentsTrained || 0).toFixed(1),
            nbSuccessInterviewPercent: ((enterprise.nbSuccessInterview * 100) / enterprise.nbInterviewsTaken || 0).toFixed(1),
            nbFailedInterview: enterprise.nbInterviewsTaken - enterprise.nbSuccessInterview,
            nbFailedInterviewPercent: (((enterprise.nbInterviewsTaken - enterprise.nbSuccessInterview) * 100) / enterprise.nbInterviewsTaken || 0).toFixed(1)
          }))
        }));
      }
      return null;
    }
  },
  watch: {
    generalParams(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== '') {
        this.fetchStats();
      }
    }
  },
  mounted() {
    this.fetchStats();
  },
  methods: {
    async fetchStats() {
      this.loading = true;
      this.stats = await getStats(this.generalParams);
      this.loading = false;
    },
    exportData() {
      const list = [['', 'Formations', '', '', '', '', 'Entretiens']];
      this.fullStats.forEach(profession => {
        const professionRaws = [
          [profession.label, 'Total', 'Reçus', 'Reçus %', 'Recalés', 'Recalés %', 'En Attente', 'Reçus', 'Reçus %', 'Recalés', 'Recalés %']
        ];
        profession.enterprises.forEach(enterprise => {
          professionRaws.push([
            enterprise.name,
            enterprise.nbAgentsTrained,
            enterprise.nbFailedInterview,
            enterprise.nbFailedInterviewPercent,
            enterprise.nbFailedTraining,
            enterprise.nbFailedTrainingPercent,
            enterprise.nbInterviewsAwaiting,
            enterprise.nbInterviewsTaken,
            enterprise.nbSuccessInterview,
            enterprise.nbSuccessInterviewPercent,
            enterprise.nbSuccessTraining,
            enterprise.nbSuccessTrainingPercent
          ]);
        });

        list.push(...professionRaws);
        list.push(['']);
      });

      try {
        json2excel({
          data: list,
          name: 'statistiques-formations'
        });
      } catch (e) {
        console.error('export error', e);
      }
    }
  }
};
</script>

<style lang="scss">
#statsContainer {
  margin-top: 5px;
  display: flex;
  flex-flow: column nowrap;
}
.statsTable {
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
}

.top-headers {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
</style>
