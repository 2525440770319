<template>
  <div class="agents-block">
    <v-row no-gutters>
      <v-col v-for="(agent, index) in agents" :key="index" class="agents-block--test" cols="3" sm="3" md="3" lg="3" xl="3">
        <div class="agents-block--item">
          <AgentCard :agent="agent" @waitForQr="$emit('waitForQr', $event)" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AgentCard from '@/components/sessions/AgentCard';

export default {
  name: 'AgentsBlocks',
  components: {
    AgentCard
  },
  props: {
    agents: {
      type: Array,
      default: () => []
    },
    sessionId: {
      type: String
    }
  },
};
</script>

<style lang="scss">
.agents-block {
  margin: 20px 0 0 0 !important;

  &--test {
    min-height: 180px !important;
  }

  &--item {
    width: 98%;
    height: 98%;
    border-radius: 4px;
  }
}
</style>
