import { find, get } from 'lodash';

import * as types from './mutation-types';

import {
  getbyId,
  getInfo,
  fetchAll,
  remove,
  change,
  add,
  addAgent,
  addAgents,
  removeAgents,
  setState,
  getAnswers,
  getQuestions,
  getCurrentQuestion,
  setNextQuestion,
  setUserResult,
  sessionEnd
} from '@/services/sessions.service';

export default {
  async fetchSessionById({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getbyId(id);
      const info = await getInfo(id);
      commit(types.SET_SESSION, Object.assign(data, info));
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async fetchSessions({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAll();
      commit(types.SET_SESSIONS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async removeSession({ commit, dispatch }, id) {
    commit(types.SET_ERRORS, null);
    try {
      await remove(id);
      dispatch('fetchSessions');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async editSession({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await change(id, data);
      dispatch('fetchSessions');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async newSession({ commit, dispatch }, data) {
    commit(types.SET_ERRORS, null);
    try {
      await add(data);
      dispatch('fetchSessions');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async addAgentToSession({ commit, dispatch }, { id, agentId }) {
    commit(types.SET_ERRORS, null);
    try {
      await addAgent(id, agentId);
      dispatch('sessionAgents/fetchSessionAgents', id, { root: true });
      dispatch('sessionAgents/fetchAgents', {}, { root: true });
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async addAgentsToSession({ commit, dispatch }, { id, agentIds }) {
    commit(types.SET_ERRORS, null);
    try {
      await addAgents(id, agentIds);
      dispatch('sessionAgents/fetchSessionAgents', id, { root: true });
      dispatch('sessionAgents/fetchAgents', {}, { root: true });
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async removeAgentsFromSession({ commit, dispatch }, { id, agentIds }) {
    commit(types.SET_ERRORS, null);
    try {
      await removeAgents(id, agentIds);
      dispatch('sessionAgents/fetchSessionAgents', id, { root: true });
      dispatch('sessionAgents/fetchAgents', {}, { root: true });
      dispatch('fetchSessionById', id);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async setSessionState({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await setState(id, data);
      dispatch('sessionAgents/fetchSessionAgents', id, { root: true });
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getSessionsAnswers({ commit, dispatch }, id) {
    commit(types.SET_ERRORS, null);
    try {
      await dispatch('getSessionsQuestions', id);
      const data = await getAnswers(id);
      commit(types.SET_SESSIONS_ANSWERS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getSessionsQuestions({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getQuestions(id);
      commit(types.SET_SESSIONS_QUESTIONS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getSessionCurrentQuestion({ commit }, sessionId) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getCurrentQuestion(sessionId);
      commit(types.SET_SESSIONS_CURRENT_QUESTIONS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async setNextQuestion({ commit, dispatch }, sessionId) {
    commit(types.SET_ERRORS, null);
    try {
      await setNextQuestion(sessionId);
      await dispatch('getSessionCurrentQuestion', sessionId);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async setUserAnswerResult({ commit }, { sessionAgentId, questionId, result }) {
    commit(types.SET_ERRORS, null);
    try {
      await setUserResult(sessionAgentId, questionId, { result });
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async endSession({ commit }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await sessionEnd(id, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
