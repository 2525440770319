import { ROUTES } from '@/common/constants';
import { clearToken } from '@/services/helpers/token';
import { setLastRoute } from '@/services/helpers/lastRoute';
import { API_ROUTES } from '@/config';

/**
 * Check error field inside unsuccessful response, map and reject them
 *
 * @param {Object} error
 * @returns {Object}
 */
export function errorHandler(error) {
  if (error.response?.status === 401 && error.response.config.url !== API_ROUTES.auth.login) {
    clearToken();
    setLastRoute();
    if (!new URL(window.location).searchParams.has('redirect')) {
      window.location.href = `${ROUTES.login}?redirect=${window.location.pathname}`;
    }
  }

  if (error.response?.data) {
    if (error.response.data?.errors) {
      return Promise.reject(error.response.data.errors);
    }

    return Promise.reject(error.response.data);
  }

  return Promise.reject(error);
}
