<template>
  <div class="sessions-page">
    <Header
      v-if="isShowHeader"
      :session="session"
      :questions="questionsData"
      :is-show-subtitle="isShowSubtitle"
      :is-show-header-items="!isShowValidation"
      :is-can-finish-session="isCanFinish"
      :is-current-question-fully-answered="isCurrentQuestionFullyAnswered"
      :agents="agents"
      :all-agents-list="list"
      @next-step="nextStep"
      @next-question="nextQuestion"
      @finish-quiz="saveFinalScore"
    />

    <SessionsPreporation
      v-if="isShowPreparation"
      :session-agents="agents"
      :all-agents="list"
      @add-agents="addAgents"
      @start-session="nextStep"
      @remove-agents="removeAgents"
    />
    <AgentsBlocks v-if="isShowRunning" :agents="agents" @waitForQr="waitForQr" />
    <SessionsInProgress
      v-if="isShowInProgress"
      :answers="answers"
      :questions="questions"
      :agents="agents"
      :current-position="currentQuestionPosition"
      @updateAnswers="updateAnswers"
      @setUserScore="setUserScore"
    />
    <SessionsValidation
      v-if="isShowValidation"
      :agents="agents"
      :session="session"
      :step="step - 1"
      :answers="answers"
      :questions="questions"
      @addComment="addComment"
      @finish="validateSession"
    />
  </div>
</template>

<script>
import { find, get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import { ROUTES_NAME } from '@/common/constants';
import SessionsPreporation from '@/components/sessions/SessionsPreporation';
import Header from '@/components/sessions/Header';
import AgentsBlocks from '@/components/sessions/AgentsBlocks';
import SessionsInProgress from '@/components/sessions/SessionsInProgress';
import SessionsValidation from '@/components/sessions/SessionsValidation';

export default {

  name: ROUTES_NAME.sessionDetails,

  components: {
    SessionsPreporation,
    Header,
    AgentsBlocks,
    SessionsInProgress,
    SessionsValidation
  },

  data() {
    return {
      step: 0,
      sessionId: null
    };
  },

  computed: {
    ...mapGetters('sessionAgents', ['agents']),
    ...mapGetters('agents', ['list']),
    ...mapGetters('sessions', ['session', 'answers', 'questions', 'currentQuestion', 'isCanFinish', 'errors']),

    errorText() {
      return this.errors ? 'auth.errorMessage' : '';
    },

    isShowHeader() {
      const value = this.isShowPreparation || this.isShowRunning || this.isShowInProgress || this.isShowValidation;
      return value;
    },

    isShowSubtitle() {
      return this.step === 2 || this.step === 1;
    },

    isShowAllSessions() {
      return this.step === 0;
    },

    isShowPreparation() {
      return this.step === 0;
    },

    isShowRunning() {
      return this.step === 1;
    },

    isShowInProgress() {
      return this.step === 2 && this.questions.length;
    },

    isShowValidation() {
      return (this.step === 3 && this.session) || (this.step === 4 && this.session);
    },

    currentQuestionPosition() {
      return get(this.currentQuestion, 'position', 0);
    },

    isCurrentQuestionFullyAnswered() {
      return (
        this.answers.filter(
          item =>
            typeof item.agentAnswer[this.currentQuestionPosition - 1] !== 'undefined' &&
            typeof item.agentAnswer[this.currentQuestionPosition - 1].type !== 'undefined'
        ).length === this.answers.length
      );
    },

    questionsData() {
      return {
        questionsCount: this.questions.length,
        currentQuestion: this.currentQuestionPosition,
        remainingQuestions: this.questions.length - this.currentQuestionPosition
      };
    }
  },

  async mounted() {
    this.sessionId = this.$route.params.id;
    await this.fetchSessionById(this.sessionId);
    await this.nextStep(0);
    this.step = this.session.state;
  },

  methods: {
    ...mapActions('form', ['fetchForms']),
    ...mapActions('sessionAgents', ['fetchSessionAgents', 'getDeviceToken', 'getAnswers', 'setAgentComment', 'saveAgentFinalScore']),
    ...mapActions('sessions', [
      'fetchSessionById',
      'addAgentToSession',
      'addAgentsToSession',
      'removeAgentsFromSession',
      'setSessionState',
      'getSessionsQuestions',
      'getSessionsAnswers',
      'setNextQuestion',
      'getSessionCurrentQuestion',
      'setUserAnswerResult',
      'endSession'
    ]),
    ...mapActions('agents', ['fetchList']),
    ...mapActions('ui', ['toggleLoader']),

    async nextStep(newState = 1) {
      this.toggleLoader();
      this.step += 1;

      // Update de l'état de la session seulement si on est dans un état != 0 (Session en préparation)
      if (newState) {
        await this.setSessionState({ id: this.sessionId, data: { state: this.step } });
      }

      switch (this.step) {
        case 1: // Démarrage de la session; questionnaire non lancé
          await Promise.all([
            this.fetchSessionAgents(this.sessionId),
            this.fetchSessionById(this.sessionId),
            this.getSessionsAnswers(this.sessionId),
            this.getSessionCurrentQuestion(this.sessionId),
            this.fetchList()
          ]);
          break;
        case 2: // Questionnaire en cours
          await Promise.all([
            this.fetchSessionAgents(this.sessionId),
            this.fetchSessionById(this.sessionId),
            this.getSessionsAnswers(this.sessionId),
            this.getSessionCurrentQuestion(this.sessionId)
          ]);
          break;
        case 3: // Questionnaire terminé, reste à valider les accès
          await Promise.all([
            this.fetchSessionAgents(this.sessionId),
            this.fetchSessionById(this.sessionId),
            this.getSessionsAnswers(this.sessionId),
            this.getSessionCurrentQuestion(this.sessionId)
          ]);
          break;
        case 4: // Session terminée
          await Promise.all([
            this.fetchSessionAgents(this.sessionId),
            this.fetchSessionById(this.sessionId),
            this.getSessionsAnswers(this.sessionId)
          ]);
          break;
        case 5: // ?
          await Promise.all([
            this.fetchSessionAgents(this.sessionId),
            this.fetchSessionById(this.sessionId),
            this.fetchList(),
            this.getSessionsAnswers(this.sessionId)
          ]);
          break;
        default:
          break;
      }

      this.toggleLoader();
    },

    addAgent(agentId) {
      this.addAgentToSession({ id: this.sessionId, agentId });
    },
    addAgents(agentIds) {
      this.addAgentsToSession({ id: this.sessionId, agentIds });
    },

    removeAgents(agentIds) {
      this.removeAgentsFromSession({
        id: this.sessionId,
        agentIds: agentIds
      });
    },

    waitForQr(agentSessionId) {
      const checkQr = async () => {
        await this.fetchSessionAgents(this.sessionId);
        const { deviceToken } = find(this.agents, { id: agentSessionId });

        if (deviceToken) {
          clearInterval(checkedInterval);
          this.$modals.hideAll();
        }
      };

      const checkedInterval = setInterval(checkQr, 5000, agentSessionId);
    },

    nextQuestion() {
      this.setNextQuestion(this.sessionId);
    },

    addComment({ id, comment }) {
      this.setAgentComment({ id, comment });
    },

    updateAnswers() {
      this.getSessionsAnswers(this.sessionId);
      this.getSessionCurrentQuestion(this.sessionId);
    },

    setUserScore(data) {
      this.setUserAnswerResult(data);
    },

    /**
     * @param {Object} data
     * @param {Integer} data.state Statut de la session
     * @param {Integer} data.nbSuccess Nombre d'agents ayant réussi le questionnaire & validé leur accès
     * @param {Integer} data.nbInterview Nombre d'agents ayant échoué le questionnaire mais envoyés en entretien
     * @param {Integer} data.nbFail Nombre d'agents ayant échoué le questionnaire
     * @param {Array} data.resultsAndComments object[] => { sessionAgentId, result, comment }
     */
    async validateSession(data) {
      await this.endSession({
        id: this.sessionId,
        data
      });
      await this.fetchSessionAgents(this.sessionId);
      this.nextStep(0);
    },

    // Appelé par le bouton "Question Suivante" sur la dernière question
    async saveFinalScore() {
      // On bloque le passage à l'étape suivante si la session ne peut pas être finie
      if (!this.isCanFinish) {
        return;
      }

      this.answers.forEach(async agent => {
        const correctAnswersCount = agent.agentAnswer.map(item => item.result).reduce((prev, next) => prev + next);

        const maxPoint = this.questions.reduce((prev, next) => prev + next.maxPoint, 0);
        const finalScore = (((correctAnswersCount / maxPoint) * 100) / 5).toFixed(2);

        await this.saveAgentFinalScore({
          id: agent.agentId,
          finalScore
        });
      });

      this.nextStep();
    }
  }
};
</script>

<style lang="scss">
.sessions-page {
  padding: 0 10px;
}
</style>
