<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="sessions"
      fixed-header
      height="500"
      class="agent-details-session-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:header.rgpdConsentDate="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="agent-details-session-table--sort-icon" />
        </div>
      </template>

      <template v-slot:item.rgpdConsentDate="{ item }">
        {{ item.rgpdConsentDate ? formatTime(item.rgpdConsentDate) : "Pas de date" }}
      </template>

      <template v-slot:item.result="{ item }">
        <a>
          {{ statusText[item.result] }}
        </a>
      </template>

      <template v-slot:item.details="{ item }">
        <a class="agent-details-session-table--form" @click.stop.prevent="showSessionDetail(item)">
          Détail
        </a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatValues } from '@/mixins/formatValues.mixin';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';

export default {
  name: 'AgentSessionsTable',
  components: {
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    sessions: {
      type: Array,
      default: () => []
    },
    answers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      statusText: ["Refusé", "Entretien", "Validé"],
      sortBy: 'rgpdConsentDate',
      sortDesc: true
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Date",
          divider: true,
          align: 'start',
          sortable: true,
          width: '20%',
          value: 'rgpdConsentDate'
        },
        {
          text: "Questionnaire",
          divider: true,
          align: 'start',
          width: '20%',
          sortable: true,
          value: 'formName'
        },
        {
          text: "Note",
          divider: true,
          align: 'start',
          width: '15%',
          sortable: true,
          value: 'finalScore'
        },
        {
          text: "Résultat",
          divider: true,
          align: 'start',
          width: '20%',
          sortable: true,
          value: 'result'
        },
        {
          text: "Commentaire",
          divider: true,
          align: 'start',
          width: '30%',
          sortable: true,
          value: 'comment'
        },
        {
          text: "",
          divider: true,
          width: '5%',
          align: 'start',
          value: 'details'
        }
      ];
    }
  },
  methods: {
    async showSessionDetail(item) {
      const clickAction = () => {
        this.$modals.open({
          name: 'SessionDetailModal',
          payload: {
            date: item.createdAt,
            answers: this.answers
          }
        });
      };

      await this.$emit('show-details', { id: item.id, action: clickAction });
    }
  }
};
</script>

<style lang="scss">
.sessions-control-icon {
  padding: 0 4px;
}

.agent-details-session-table {
  .v-data-table-header__icon {
    display: none;
  }

  &--form {
    color: #0094ff !important;
    text-decoration: underline;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
