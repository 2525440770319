import Vue from 'vue';

import i18n from './i18n';
import router from './router';
import store from './store';
import { bootstrapApplication } from './bootstrap';
import vuetify from './plugins/vuetify';
import App from './App.vue';

Vue.config.productionTip = false;

bootstrapApplication(Vue);

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
