import * as types from './mutation-types';

import { getForms } from '@/services/form.service';

export default {
  async fetchForms({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getForms();
      commit(types.SET_FORMS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
