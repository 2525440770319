export const SET_LIST = 'SET_LIST';
export const SET_ALLOWED_SITES_LIST = 'SET_ALLOWED_SITES_LIST';
export const SET_ALLOWED_SITE = 'SET_ALLOWED_SITE';
export const SET_MOUVEMENTS_LIST = 'SET_MOUVEMENTS_LIST';
export const SET_COMMUNICATIONS_LIST = 'SET_COMMUNICATIONS_LIST';
export const SET_ANOMALIES_LIST = 'SET_ANOMALIES_LIST';
export const SET_ACTUAL_AGENT_LIST = 'SET_ACTUAL_AGENT_LIST';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_SITE = 'SET_SITE';
export const SET_SELECT_ZONE = 'SET_SELECT_ZONE';
export const SET_LIST_PERMISSION = 'SET_LIST_PERMISSION';
