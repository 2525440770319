<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="sections"
      fixed-header
      height="500"
      class="agent-details-sections-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
    </v-data-table>
  </div>
</template>

<script>
import { formatValues } from '@/mixins/formatValues.mixin';

export default {
  name: 'AgentDetailsSections',
  components: {},
  mixins: [formatValues],
  props: {
    sections: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sortBy: 'name',
      sortDesc: false
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'Zone',
          divider: true,
          align: 'start',
          sortable: true,
          width: '100%',
          value: 'Site.name'
        }
      ];
    }
  }
};
</script>

<style lang="scss">
.sessions-control-icon {
  padding: 0 4px;
}

.agent-details-sections-table {
  .v-data-table-header__icon {
    display: none;
  }

  &--form {
    color: #0094ff !important;
    text-decoration: underline;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
