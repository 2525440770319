<template>
  <v-app>
    <loader
      v-if="isShowLoader"
      object="#3dd598"
      color1="#ffffff"
      color2="#17fd3d"
      size="11"
      speed="1"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      disable-scrolling="false"
      name="spinning"
    ></loader>
    <Header v-if="isAuth" />
    <TheModals />
    <navigation v-if="isAuth" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Navigation from '@/components/navigation/Navigation';
import Header from '@/components/common/header/Header';
import { ROUTES } from '@/common/constants';
import { auth } from '@/mixins/auth.mixin';

export default {
  components: {
    Navigation,
    Header
  },
  mixins: [auth],
  computed: {
    ...mapGetters('ui', ['isShowLoader'])
  },
  mounted() {
    if (!this.isAuth && window.location.pathname !== '/login') {
      window.location.href = `${ROUTES.login}?redirect=${window.location.pathname}`;
    }
    this.extractRoles();
    this.getConfig();
  },
  methods: {
    ...mapActions('auth', ['extractRoles']),
    ...mapActions('ui', ['getConfig'])
  }
};
</script>

<style lang="scss">
@import 'assets/styles';
</style>
