import request from '@/services/helpers/request';
import { API_ROUTES } from '@/config';

export const fetchAllRoles = async () => (await request.getInstance()).get(API_ROUTES.accounts.fetchAllRoles);
export const fetchAll = async () => (await request.getInstance()).get(API_ROUTES.site.list);
export const fetchAllRolePermissions = async () => (await request.getInstance()).get(API_ROUTES.accounts.fetchAllRolePermissions);
export const fetchAllPermissions = async () => (await request.getInstance()).get(API_ROUTES.accounts.fetchAllPermissions);
export const fetchAllAccess = async () => (await request.getInstance()).get(API_ROUTES.accounts.fetchAllAccess);
export const fetchAllAccounts = async () => (await request.getInstance()).get(API_ROUTES.accounts.fetchAllAccounts);

export const addRoleToAccount = async (userId, role, siteId) =>
  (await request.getInstance()).post(API_ROUTES.accounts.addRoleToAccount.replace(':id', userId).replace(':siteId', siteId), role);
export const addPermissionToUser = async (userId, permission, siteId) =>
  (await request.getInstance()).post(API_ROUTES.accounts.addPermissionToUser.replace(':id', userId).replace(':siteId', siteId), permission); // <-

export const addNewRole = async data => (await request.getInstance()).post(API_ROUTES.accounts.addNewRole, data);
export const addNewPermission = async data => (await request.getInstance()).post(API_ROUTES.accounts.addNewPermission, data);
export const permissionToRole = async (id, data) => (await request.getInstance()).post(API_ROUTES.accounts.permissionToRole.replace(':id', id), data);

export const removeAccount = async id => (await request.getInstance()).delete(API_ROUTES.accounts.removeAccount.replace(':id', id));
export const updateAccount = async (id, data) => (await request.getInstance()).put(API_ROUTES.accounts.updateAccount.replace(':id', id), data);
export const changePassword = async (id, data) => (await request.getInstance()).put(API_ROUTES.accounts.changePassword.replace(':id', id), data);
export const selfChangePassword = async ({ id, oldPassword, newPassword }) =>
  (await request.getInstance()).post(API_ROUTES.accounts.selfChangePassword, {
    id,
    oldPassword,
    newPassword
  });
export const addNew = async data => (await request.getInstance()).post(API_ROUTES.accounts.addNew, data);

export const getAccountRoles = async () => (await request.getInstance()).get(API_ROUTES.accounts.getAccountRoles); // <-
export const getAccountPermissions = async (id, siteId) =>
  (await request.getInstance()).get(API_ROUTES.accounts.getAccountPermissions.replace(':id', id).replace(':siteId', siteId));
export const getAccountRolesAndPermissions = async id =>
  (await request.getInstance()).get(API_ROUTES.accounts.getAccountRolesAndPermissions.replace(':id', id));

export const getPermissionsToAccount = async () => (await request.getInstance()).get(API_ROUTES.accounts.getPermissionsToAccount);

export const getRolesToAccount = async (id, siteId) =>
  (await request.getInstance()).get(API_ROUTES.accounts.getRolesToAccount.replace(':id', id).replace(':siteId', siteId));
