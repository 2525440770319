import * as types from './mutation-types';

export default {
  [types.SET_BANNEDLIST](state, bannedList) {
    state.bannedList = bannedList;
  },
  [types.SET_WHITEDLIST](state, whitedList) {
    state.whitedList = whitedList;
  },
  [types.SET_LIST](state, list) {
    state.list = list;
  },
  [types.SET_ADDRESS](state, address) {
    state.address = address;
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [types.SET_BANNEDIP](state, bannedIP) {
    state.bannedIP = bannedIP;
  },
  [types.SET_CHANGE_MODAL](state, changeModal) {
    // <-
    state.changeModal = changeModal;
  }
};
