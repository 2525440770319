export default {
  durationLabelsStandard: {
    S: 'milliseconde',
    SS: 'millisecondes',
    s: 'seconde',
    ss: 'secondes',
    m: 'minute',
    mm: 'minutes',
    h: 'heure',
    hh: 'heures',
    d: 'jour',
    dd: 'jours',
    w: 'semaine',
    ww: 'semaines',
    M: 'mois',
    MM: 'mois',
    y: 'année',
    yy: 'années'
  },
  durationLabelsShort: {
    S: 'msec',
    SS: 'msecs',
    s: 'sec',
    ss: 'secs',
    m: 'min',
    mm: 'mins',
    h: 'hr',
    hh: 'hrs',
    d: 'jr',
    dd: 'jrs',
    w: 'sm',
    ww: 'sms',
    M: 'mo',
    MM: 'mos',
    y: 'an',
    yy: 'ans'
  },
  durationTimeTemplates: {
    HMS: 'h:mm:ss',
    HM: 'h:mm',
    MS: 'm:ss'
  },
  durationLabelTypes: [
    { type: 'standard', string: '__' },
    { type: 'short', string: '_' }
  ]
};
