<template>
  <div class="tables-wrapper">
    <SimpleTable
      :data="formsList"
      :text="text"
      @selectForm="setCurrentFormId($event)"
      @add="createForm($event)"
      @edit="editForm($event)"
      @remove="removeForm($event)"
    />
    <QuestionsTable
      :questions="questionsByFormId[currentFormId]"
      :questions-themes="questionsThemes"
      :current-form-id="currentFormId"
      @edit="editQuestion($event)"
      @add="createQuestion($event)"
      @remove="removeQuestion($event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import QuestionsTable from '@/components/common/tables/QuestionsTable';
import SimpleTable from '@/components/common/tables/SimpleTable';

export default {
  components: {
    QuestionsTable,
    SimpleTable
  },
  data() {
    return {
      selectedForm: [],
      text: {
        table: 'form',
        add: {
          title: "Ajouter un nouveau formulaire"
        },
        edit: {
          title: "Modifier un formulaire"
        },
        remove: {
          title: "Supprimer un formulaire",
          subTitle: "Êtes-vous sûr de vouloir supprimer le formulaire ?"
        }
      }
    };
  },
  computed: {
    ...mapGetters('formManagement', ['formsList', 'questionsByFormId', 'currentFormId']),
    ...mapGetters('questionsThemes', ['questionsThemes'])
  },
  beforeMount() {
    this.getAllForms();
    this.getAllQuestions();
    this.getAllThemes();
  },
  methods: {
    ...mapActions('formManagement', [
      'getAllForms',
      'getAllQuestions',
      'setCurrentFormId',
      'editForm',
      'createQuestion',
      'removeQuestion',
      'createForm',
      'removeForm',
      'editQuestion'
    ]),
    ...mapActions('questionsThemes', ['getAllThemes'])
  }
};
</script>

<style lang="scss">
.tables-wrapper {
  padding: 0 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
}
</style>
