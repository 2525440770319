import * as types from './mutation-types';

export default {
  [types.SET_ALL_AGENTS](state, agents) {
    state.allAgents = agents.map(agent => {
      const { sessionAgent, firstname, lastname, badge, agentProfileName, enterpriseName, formName } = agent;

      return {
        firstname,
        lastname,
        agentProfileName,
        enterpriseName,
        formName,
        ...sessionAgent,
        agentBadge: badge
      };
    });
  },
  [types.SET_AGENTS](state, agents) {
    state.agents = [];
    state.agents = agents.map(agent => {
      const { sessionAgent, firstname, lastname, badge, profileName, enterprise, interview, formName } = agent;

      return {
        firstname,
        lastname,
        interview,
        agentProfileName: profileName,
        enterpriseName: enterprise,
        formName,
        ...sessionAgent,
        agentBadge: badge
      };
    });
  },
  [types.SET_CURRENT_SESSION](state, id) {
    state.sessionId = id;
  },
  [types.SET_DEVICE_TOKEN](state, deviceToken) {
    state.deviceToken = deviceToken;
  },
  [types.SET_AGENT_ANSWERS](state, answers) {
    state.answers = answers;
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  }
};
