<template>
  <v-dialog
    :value="true"
    :hide-overlay="hideOverlay"
    :overlay-color="overlayColor"
    :max-width="maxWidth"
    :width="width"
    :content-class="contentClass"
    @click:outside="closeHandler"
  >
    <v-card class="px-6 py-8">
      <Close class="close-button" @click.native="closeHandler" />
      <slot />
    </v-card>
  </v-dialog>
</template>

<script>
import Close from '@/components/common/icons/Close';

export default {
  name: 'BasePopupModal',
  components: {
    Close
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    },
    hideOverlay: {
      type: Boolean,
      default: false
    },
    overlayColor: {
      type: String,
      default: null
    },
    contentClass: {
      type: String,
      default: 'modals-container'
    },
    maxWidth: {
      type: Number,
      default: 900
    },
    width: {
      type: Number,
      required: false
    }
  },
  methods: {
    closeHandler() {
      this.$modals.hide(this.modal.id);
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;

  .close-button {
    position: absolute;
    right: 25px;
    cursor: pointer;
  }
}
</style>
