<template>
  <div>
    <div v-if="access.length" class="sessions-control d-flex justify-end align-center">
      <div class="sessions-control--export" @click.stop.prevent="exportData">
        <div class="d-flex justify-end align-center font-weight-medium">
          Exporter
        </div>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="access"
      :items-per-page="-1"
      fixed-header
      height="500"
      class="access-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:header.firstname="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="access-table--sort-icon" />
        </div>
      </template>

      <template v-slot:item.date="{ item }">
        {{ formatDateHourMinute(item.date) }}
      </template>

      <template v-slot:item.type="{ item }">
        {{ item.type ? 'Sortie' : 'Entrée' }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { json2excel } from 'js2excel';
import moment from 'moment';

import { formatValues } from '@/mixins/formatValues.mixin';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';

export default {
  name: 'MouvementTable',
  components: {
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    access: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sortBy: ['date'],
      sortDesc: true
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Nom",
          divider: true,
          align: 'start',
          width: '17%',
          sortable: true,
          value: 'Agent.lastname'
        },
        {
          text: "Prénom",
          divider: true,
          align: 'start',
          sortable: true,
          width: '17%',
          value: 'Agent.firstname'
        },
        {
          text: "Profil",
          divider: true,
          align: 'start',
          width: '18%',
          sortable: true,
          value: 'Agent.AgentProfile.name'
        },
        {
          text: "Entreprise",
          divider: true,
          align: 'start',
          width: '18%',
          sortable: true,
          value: 'Agent.Enterprise.name'
        },
        {
          text: 'Type',
          divider: true,
          align: 'start',
          width: '10%',
          sortable: true,
          value: 'type'
        },
        {
          text: 'Badge',
          divider: true,
          align: 'start',
          width: '8%',
          sortable: true,
          value: 'badge'
        },
        {
          text: "Date",
          divider: true,
          align: 'start',
          width: '12%',
          sortable: true,
          value: 'date'
        }
      ];
    }
  },
  methods: {
    exportData() {
      const agentsList = this.access
        .sort((a, b) => b.date.localeCompare(a.date)) // sort décroissant
        .map(item => {
          const agent = [
            ["Nom", item.Agent.lastname],
            ["Prénom", item.Agent.firstname],
            ["Profil", item.Agent.AgentProfile.name],
            ["Entreprise", item.Agent.Enterprise.name],
            ['Type', item.type ? 'Sortie' : 'Entrée'],
            ["Date", moment(item.date).format('DD/MM/YYYY HH:mm')]
          ];
          return Object.fromEntries(agent);
        });

      try {
        json2excel({
          data: agentsList,
          name: 'agents-in-out'
        });
      } catch (e) {
        console.error('export error', e);
      }
    }
  }
};
</script>

<style lang="scss">
.sessions-control-icon {
  padding: 0 4px;
}

.access-table {
  .v-data-table-header__icon {
    display: none;
  }

  &--form {
    color: #0094ff !important;
    text-decoration: underline;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
