<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="interviews"
      fixed-header
      height="500"
      class="agent-details-interview-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:header.createdAt="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="agent-details-interview-table--sort-icon" />
        </div>
      </template>

      <template v-slot:item.result="{ item }">
        {{ resultText[item.result] }}
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ formatTime(item.createdAt) }}
      </template>

      <template v-slot:item.form="{ item }">
        <a class="agent-details-interview-table--form" @click="showDetails(item)">
          Voir
        </a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatValues } from '@/mixins/formatValues.mixin';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';

export default {
  name: 'AgentInterviewTable',
  components: {
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    interviews: {
      type: Array,
      default: () => []
    },
    answers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      resultText: ['Refusé', 'Validé'],
      sortBy: 'date',
      sortDesc: true
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Date",
          divider: true,
          align: 'start',
          sortable: true,
          width: '20%',
          value: 'createdAt'
        },
        {
          text: "Questionnaire",
          divider: true,
          align: 'start',
          width: '20%',
          sortable: true,
          value: 'form'
        },
        {
          text: "Commentaire session",
          divider: true,
          align: 'start',
          width: '20%',
          sortable: true,
          value: 'sessionComment'
        },
        {
          text: "Évaluateurs",
          divider: true,
          align: 'start',
          width: '20%',
          sortable: true,
          value: 'interviewers'
        },
        {
          text: "Résultat",
          divider: true,
          align: 'start',
          width: '20%',
          sortable: true,
          value: 'result'
        },
        {
          text: "Commentaire",
          divider: true,
          align: 'start',
          width: '20%',
          sortable: true,
          value: 'comment'
        }
      ];
    }
  },
  methods: {
    async showDetails(item) {
      console.info('show details', { item });
      const clickAction = () => {
        this.$modals.open({
          name: 'SessionDetailModal',
          payload: {
            date: item.sessionEndDate,
            answers: this.answers
          }
        });
      };

      await this.$emit('show-details', { id: item.sessionAgentId, action: clickAction });
    }
  }
};
</script>

<style lang="scss">
.sessions-control-icon {
  padding: 0 4px;
}

.agent-details-interview-table {
  .v-data-table-header__icon {
    display: none;
  }

  &--form {
    color: #0094ff !important;
    text-decoration: underline;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
