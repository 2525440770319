var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Control',{on:{"add":_vm.addAccess,"remove":_vm.removeAccess,"edit":_vm.editAccess}}),_c('v-data-table',{staticClass:"access-table",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.zones,"items-per-page":20,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50, -1]
    },"height":"600","single-select":true,"show-select":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header.firstname",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('TwoArrowSort',{staticClass:"access-table--sort-icon"})],1)]}},{key:"item.nb",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.Agents.length)+" ")]}},{key:"item.poiEnabled",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.poiEnabled === 1 ? 'Oui' : 'Non')+" ")]}},{key:"item.details",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"tag":'a',"to":{
          name: 'SectionDetails',
          params: {
            id: item.id
          }
        }}},[_vm._v(" Détail ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }