import * as types from './mutation-types';

import { getForms, deleteForm, changeForm, addForm, getQuestions, addQuestion, changeQuestion, deleteQuestion } from '@/services/formManagement.service';

export default {
  async getAllForms({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getForms();
      commit(types.SET_FORMS_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },
  async removeForm({ commit, dispatch }, id) {
    commit(types.SET_ERRORS, null);
    try {
      await deleteForm(id);
      dispatch('getAllForms');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },
  async editForm({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await changeForm(id, data);
      dispatch('getAllForms');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },
  async createForm({ commit, dispatch }, data) {
    commit(types.SET_ERRORS, null);
    try {
      await addForm(data);
      dispatch('getAllForms');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },
  async getAllQuestions({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getQuestions();
      commit(types.SET_QUESTIONS_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },
  async editQuestion({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await changeQuestion(id, data);
      dispatch('getAllQuestions');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },
  async createQuestion({ commit, dispatch }, data) {
    commit(types.SET_ERRORS, null);
    try {
      await addQuestion(data);
      dispatch('getAllQuestions');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },
  async removeQuestion({ commit, dispatch }, id) {
    commit(types.SET_ERRORS, null);
    try {
      await deleteQuestion(id);
      dispatch('getAllQuestions');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },
  setCurrentFormId({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      commit(types.SET_CURRENT_FORM_ID, id);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
