import * as types from './mutation-types';

export default {
  [types.SET_LIST](state, list) {
    state.access = list;
  },
  [types.SET_AGENT_ACCESS](state, access) {
    state.agentAccess = access;
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  }
};
