import * as types from './mutation-types';

export default {
  [types.SET_INTERVIEWER](state, interviewers) {
    state.interviewer = interviewers.map(interviewer => ({
      name: `${interviewer.agentFirstname} ${interviewer.agentLastname}`,
      ...interviewer
    }));
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  }
};
