var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Control',{attrs:{"is-export":true},on:{"add":_vm.addItem,"remove":_vm.removeItems,"edit":_vm.editItem,"export":_vm.exportData}}),_c('v-data-table',{staticClass:"agents-table",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.agentsList,"items-per-page":20,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50, -1]
    },"single-select":false,"show-select":"","height":"600","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header.firstname",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center squeezeHeader"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('TwoArrowSort',{staticClass:"agents-table--sort-icon"})],1)]}},{key:"header.lastname",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center squeezeHeader"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('TwoArrowSort',{staticClass:"agents-table--sort-icon"})],1)]}},{key:"header.profileName",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center squeezeHeader"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('TwoArrowSort',{staticClass:"agents-table--sort-icon"})],1)]}},{key:"header.enterpriseName",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center squeezeHeader"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('TwoArrowSort',{staticClass:"agents-table--sort-icon"})],1)]}},{key:"header.badge",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center squeezeHeader"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('TwoArrowSort',{staticClass:"agents-table--sort-icon"})],1)]}},{key:"item.accessBeginDate",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.statusText(item)))])]}},{key:"item.accessEndDate",fn:function(ref){
    var item = ref.item;
return [(item.accessEndDate)?_c('span',[_vm._v(_vm._s(_vm.formatTime(item.accessEndDate)))]):_vm._e()]}},{key:"item.actualSiteId",fn:function(ref){
    var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"#3dd598","disabled":true},model:{value:(item.actualSiteId),callback:function ($$v) {_vm.$set(item, "actualSiteId", $$v)},expression:"item.actualSiteId"}})]}},{key:"item.badge",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.badge))])]}},{key:"item.detail",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"tag":'a',"to":{ name: 'AgentsDetails', params: { id: item.id } }}},[_vm._v(" Détail ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }