import { TOKEN_STORAGE_KEY } from '@/common/constants';

export const saveToken = ({ token }) => {
  localStorage.setItem(TOKEN_STORAGE_KEY.accessToken, token.replace('Bearer ', ''));
};

export const getToken = () => ({
  accessToken: localStorage.getItem(TOKEN_STORAGE_KEY.accessToken)
});

export const clearToken = () => {
  localStorage.setItem(TOKEN_STORAGE_KEY.accessToken, '');
};
