import Vue from 'vue';

export default {
  ADD_MODAL(state, { id, name, payload }) {
    Vue.set(state.modalsById, id, {
      id,
      name,
      payload
    });

    if (!state.modalsIds.includes(id)) {
      state.modalsIds.push(id);
    }
  },
  REMOVE_MODAL_BY_ID(state, id) {
    state.modalsIds = state.modalsIds.filter(value => value !== id);

    Vue.delete(state.modalsById, id);
  },
  REMOVE_ALL_MODALS(state) {
    state.modalsById = {};
    state.modalsIds = [];
  }
};
