export default {
  sites: [],
  allowedSitesList: [],
  mouvementsList: [],
  communicationsList: [],
  anomaliesList: [],
  allowedSites: [],
  actualAgentsList: [],
  errors: null,
  site: {},
  selectZone: '',
  listPermission: []
};
