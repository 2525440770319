<template>
  <div class="interviews-page">
    <div class="interviews-page--header d-flex justify-end">
      <div class="sessions-validation-header--sub-list-status all d-flex justify-start align-center">
        <div class="sessions-validation-header--sub-list-status-item">
          <div class="sessions-validation-header--sub-list-status-item-title">
            Entretien à réaliser
          </div>
          <div class="sessions-validation-header--sub-list-status-item-value">
            {{ interviewerCount }}
          </div>
        </div>
      </div>
    </div>
    <InterviewsTable :interviews="interviewer" :answers="answers" @close="closeItem" @show-details="showSessionDetails($event)" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { ROUTES_NAME } from '@/common/constants';
import InterviewsTable from '@/components/common/tables/InterviewsTable';

export default {
  name: ROUTES_NAME.interviews,
  components: {
    InterviewsTable
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('interviewer', ['interviewer', 'interviewerCount']),
    ...mapGetters('sessionAgents', ['answers'])
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    ...mapActions('interviewer', ['fetchList', 'closeItem']),
    ...mapActions('sessionAgents', ['getAnswers']),
    async showSessionDetails(data) {
      await this.getAnswers(data.id);
      if (data.action) {
        data.action();
      }
    }
  }
};
</script>

<style lang="scss">
.interviews-page {
  padding: 0 10px;

  .sessions-validation-header--sub-list-status {
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 500;
    font-size: 18px;
    &-sub {
      width: 242px;
      height: 70px;
    }
  }
}
</style>
