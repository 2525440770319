import * as types from './mutation-types';

export default {
  [types.SET_ROLES](state, roles) {
    state.roles = roles;
  },
  [types.SET_ACCOUNTS](state, accounts) {
    state.accounts = accounts;
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [types.SET_ALL_PERMISSIONS](state, allPermissions) {
    state.allPermissions = allPermissions;
  }
};
