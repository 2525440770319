import BadgeModal from '@/components/modals/BadgeModal';
import QrModal from '@/components/modals/QrModal';
import SessionModal from '@/components/modals/SessionModal';
import AddAgentModal from '@/components/modals/AddAgentModal';
import AddAgentsModal from '@/components/modals/AddAgentsModal';
import RemoveAgentsModal from '@/components/modals/RemoveAgentsModal';
import RemoveSessionModal from '@/components/modals/RemoveSessionModal';
import FreeQuestionModal from '@/components/modals/FreeQuestionModal';
import ThemesModal from '@/components/modals/ThemesModal';
import RemoveThemeModal from '@/components/modals/RemoveThemesModal';
import RemoveItem from '@/components/modals/RemoveItem';
import ChangeItem from '@/components/modals/ChangeItem';
import ChangeEnterprise from '@/components/modals/ChangeEnterprise';
import ChangeAgentModal from '@/components/modals/ChangeAgentModal';
import AddAccountModal from '@/components/modals/AddAccountModal';
import InterviewFinishModal from '@/components/modals/InterviewFinishModal';
import SessionDetailModal from '@/components/modals/SessionDetailModal';
import EditQuestionModal from '@/components/modals/EditQuestionModal';
import ChangeAccessModal from '@/components/modals/ChangeAccessModal';
import ChangeSiteModal from '@/components/modals/ChangeSiteModal';
import ChangeSectionModal from '@/components/modals/ChangeSectionModal';
import PoiModal from '@/components/modals/PoiModal';
import InterviewDetailsModal from '@/components/modals/InterviewDetailsModal';
import ChangeAccountPasswordModal from '@/components/modals/ChangeAccountPasswordModal';
import FixAnomalyModal from '@/components/modals/FixAnomalyModal';
import ChangePasswordModal from '@/components/modals/ChangePasswordModal';
import AddNewRole from '@/components/modals/AddNewRole';
import ChangeRolePermissionForUser from '@/components/modals/ChangeRolePermissionForUser';

export default {
  BadgeModal,
  QrModal,
  SessionModal,
  AddAgentModal,
  AddAgentsModal,
  RemoveAgentsModal,
  RemoveSessionModal,
  FreeQuestionModal,
  ThemesModal,
  RemoveThemeModal,
  RemoveItem,
  ChangeItem,
  ChangeEnterprise,
  ChangeAgentModal,
  AddAccountModal,
  InterviewFinishModal,
  SessionDetailModal,
  EditQuestionModal,
  ChangeAccessModal,
  ChangeSiteModal,
  ChangeSectionModal,
  PoiModal,
  InterviewDetailsModal,
  ChangeAccountPasswordModal,
  FixAnomalyModal,
  ChangePasswordModal,
  AddNewRole,
  ChangeRolePermissionForUser
};
