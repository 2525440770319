var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"statsContainer"}},[_c('v-data-table',{attrs:{"id":"statsTable","fixed-header":"","headers":_vm.defaultHeaders,"items":_vm.formattedAnomalies,"items-per-page":20,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50, -1]
    },"height":"600"},scopedSlots:_vm._u([{key:"item.fixAnomalies",fn:function(ref){
    var item = ref.item;
return [_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.fixAnomaly(item)}}},[_vm._v(" Corriger ")])]}},{key:"item.enterDate",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:item.wrongedField === 'enterDate' ? 'red--text' : ''},[_vm._v(" "+_vm._s(item.enterDate)+" ")])]}},{key:"item.leaveDate",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:item.wrongedField === 'leaveDate' ? 'red--text' : ''},[_vm._v(" "+_vm._s(item.leaveDate)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }