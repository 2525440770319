import * as types from './mutation-types';

import {
  fetchAll,
  newItem,
  remove,
  getSite,
  changePoiValue,
  changeItem,
  allowedSitesList,
  siteMouvementsList,
  siteCommunicationsList,
  siteAnomaliesList,
  siteActualAgentList,
  addAllowSiteAccess,
  removeAllowSiteAccess,
  getAllowSiteAccessForAgent,
  fetchListPermission
} from '@/services/site.service';

export default {
  async fetchSites({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAll();
      commit(types.SET_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async newSite({ commit, dispatch }, data) {
    commit(types.SET_ERRORS, null);
    try {
      await newItem(data);
      dispatch('fetchSites');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async removeSite({ commit, dispatch }, id) {
    commit(types.SET_ERRORS, null);
    try {
      await remove(id);
      dispatch('fetchSites');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getSite({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      const site = await getSite(id);
      commit(types.SET_SITE, site);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async changeSite({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await changeItem(id, data);
      dispatch('fetchSites');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async changePoi({ commit }, { id, value }) {
    commit(types.SET_ERRORS, null);
    try {
      await changePoiValue(id, { poiEnabled: value });
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getAllowedSitesList({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await allowedSitesList(id);
      commit(types.SET_ALLOWED_SITES_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getMouvementsList({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await siteMouvementsList(id);
      commit(types.SET_MOUVEMENTS_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getCommunicationsList({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await siteCommunicationsList(id);
      commit(types.SET_COMMUNICATIONS_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getAnomaliesList({ commit }, params) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await siteAnomaliesList(params);
      commit(types.SET_ANOMALIES_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getActualAgentList({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await siteActualAgentList(id);
      commit(types.SET_ACTUAL_AGENT_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async newAllowedSites({ commit }, data) {
    commit(types.SET_ERRORS, null);
    try {
      await addAllowSiteAccess(data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async removeAllowSite({ commit }, ids) {
    commit(types.SET_ERRORS, null);
    try {
      await removeAllowSiteAccess(ids);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getAllowSiteForAgent({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getAllowSiteAccessForAgent(id);
      commit(types.SET_ALLOWED_SITE, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getSelectZoneForAccount({ commit }, data) {
    commit(types.SET_ERRORS, null);
    try {
      commit(types.SET_SELECT_ZONE, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getListToPermission({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchListPermission();
      commit(types.SET_LIST_PERMISSION, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
