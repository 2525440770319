<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="badge-modal">
      <div class="badge-modal--header font-weight-light">
        Appareillage de la tablette à <span class="font-weight-medium">{{ agentName }}</span>
      </div>
      <div class="badge-modal--content">
        <div class="badge-modal--content-label font-weight-medium">
          Scanner le QrCode avec la tablette avant de la donner au stagiaire
        </div>
        <div>
          <em>
            <Alert></Alert>
            Attention, votre clavier doit être configuré en français et vous ne devez pas avoir verrouillé les majuscules
          </em>
        </div>
        <div class="badge-modal--content-input d-flex justify-center">
          <v-form class="badge-modal--content-input-form" @submit.prevent="preventFormSubmission">
            <v-container>
              <v-text-field ref="title" v-model="title" label="Badge" :rules="badgeRules" outline></v-text-field>
            </v-container>
          </v-form>
        </div>
      </div>
      <div class="badge-modal--buttons d-flex justify-end">
        <v-btn class="badge-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="badge-modal--buttons-save" color="#38BB00" dark :disabled="isDisabled" depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import BasePopupModal from '@/components/modals/base/BasePopupModal';
import Alert from '@/components/common/icons/Alert.vue';

export default {
  name: 'BadgeModal',
  components: {
    BasePopupModal,
    Alert
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      title: ''
    };
  },
  computed: {
    agentName() {
      return this.modal.payload.agentName;
    },
    badgeRules() {
      return [
        v =>
          !!v.match(/^[a-z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]*$/i) || "Caractères invalides",
        v => v.length < 51 || "Trop long",
        v => v.length > 0 || "Trop court"
      ];
    },
    isDisabled() {
      const disabled =
        !!this.title.match(/^[a-z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]*$/i) &&
        this.title.length > 0 &&
        this.title.length < 51;
      return !disabled;
    }
  },
  beforeDestroy() {
    this.close();
  },
  mounted() {
    // On auto focus le champs title quand la modale est montée
    const el = this.$refs.title;
    setTimeout(() => el.focus(), 100);

    // si on fourni un badge à la modal, on le set
    if (this.modal.payload.agentBadge) {
      this.title = this.modal.payload.agentBadge;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit(this.title);

        this.close();
      }
    },
    preventFormSubmission() {
      // Évite que le form soit envoyer quand les champs reçoivent un retour à la ligne ou key "Enter"
      return true;
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.badge-modal {
  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  &--header {
    text-align: center;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 343px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
