<template>
  <div class="logs-page">
    <LogsTable :logs="logs" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import LogsTable from '../../components/common/tables/LogsTable.vue';

import { ROUTES_NAME } from '@/common/constants';

export default {
  name: ROUTES_NAME.logs,
  components: { LogsTable },
  computed: {
    ...mapGetters('logs', ['logs'])
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    ...mapActions('logs', ['fetchList'])
  }
};
</script>

<style lang="scss">
.logs-page {
  padding: 0 10px;
}
</style>
