var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Control',{on:{"add":_vm.addItem,"remove":_vm.removeItemById,"edit":_vm.editItem}}),_c('v-data-table',{staticClass:"simple-table",attrs:{"fixed-header":"","headers":_vm.headers || _vm.defaultHeaders,"items":_vm.data,"items-per-page":20,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50, -1]
    },"height":"600","single-select":true,"show-select":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"input":_vm.selectItem},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('TwoArrowSort',{staticClass:"simple-table--sort-icon"})],1)]}},{key:"header.date",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('TwoArrowSort',{staticClass:"simple-table--sort-icon"})],1)]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.createdAt))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }