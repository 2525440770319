<template>
  <div class="statistics-page">
    <!-- HEADER -->
    <h2>Statistiques Formations</h2>
    <div id="general-stats" class="d-flex flex-row py-4 flex-wrap">
      <div class="d-flex mx-1 mb-2 pa-2 text-center flex-column stats-block">
        <b>Personnes formées</b> <span> {{ agentsTrained }}</span>
      </div>
      <div class="d-flex mx-1 mb-2 pa-2 text-center flex-column stats-block">
        <b>Formations validées</b> <span> {{ agentsTrainedSuccess }}</span>
      </div>
      <div class="d-flex mx-1 mb-2 pa-2 text-center flex-column stats-block">
        <b>Pourcentage de formations validées</b>
        <span> {{ agentsTrainedSuccessPercentage.toFixed(1) }}%</span>
      </div>
      <div class="d-flex mx-1 mb-2 pa-2 text-center flex-column stats-block">
        <b>Personnes recallées</b> <span> {{ agentsTrainedFailed }}</span>
      </div>
      <div class="d-flex mx-1 mb-2 pa-2 text-center flex-column stats-block">
        <b>Pourcentage de personnes recallées</b>
        <span> {{ agentsTrainedFailedPercentage.toFixed(1) }}%</span>
      </div>
      <div class="d-flex mx-1 mb-2 pa-2 text-center flex-column stats-block">
        <b>Entretiens réalisés</b> <span> {{ interviewsTaken }}</span>
      </div>
      <div class="d-flex mx-1 mb-2 pa-2 text-center flex-column stats-block">
        <b>Entretiens en attente</b> <span> {{ interviewsAwaiting }}</span>
      </div>
      <div class="d-flex mx-1 mb-2 pa-2 text-center flex-column stats-block">
        <b>Entretiens validés</b> <span> {{ interviewsSuccess }}</span>
      </div>
      <div class="d-flex mx-1 mb-2 pa-2 text-center flex-column stats-block">
        <b>Pourcentage d'entretiens validés</b>
        <span> {{ interviewsSuccessPercentage.toFixed(1) }}%</span>
      </div>
      <div class="access-page--header filterSearch mr-6 flex-column">
        <div class=" d-flex justify-space-between align-center">
          <div class=" d-flex justify-start align-center">
            <div class="filterHeader">
              <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dates" label="Période d'affichage" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="dates" range></v-date-picker>
              </v-menu>
            </div>
          </div>
        </div>
      </div>
      <v-select v-model="formId" :items="[{ id: '', name: 'Tous les quiz' }, ...formsList]" item-text="name" item-value="id"></v-select>
    </div>

    <!-- TABS -->
    <v-card>
      <v-tabs v-model="tab">
        <v-tab to="#general">Général</v-tab>
        <v-tab to="#responses" :disabled="formId === ''">Réponses</v-tab>
        <v-tab to="#candidates">Candidats</v-tab>
        <v-tab to="#professions">Corps de métier</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="general" value="general">
          <StatistiqueFormationsTable :general-params="generalParams" />
        </v-tab-item>
        <v-tab-item key="responses" value="responses" :disabled="formId === ''">
          <AnswersQuizChart v-if="formId" :general-params="generalParams" :form-id="formId"></AnswersQuizChart>
          <p v-else class="mx-auto mt-6 text-center">Pas de quiz sélectionné</p>
        </v-tab-item>
        <v-tab-item key="candidates" value="candidates">
          <CandidatesChart :general-params="generalParams"></CandidatesChart>
        </v-tab-item>
        <v-tab-item key="professions" value="professions">
          <ProfessionsChart :general-params="generalParams"></ProfessionsChart>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import { getHeader } from '../../services/statsFormations.service';
import StatistiqueFormationsTable from '../../components/common/tables/StatistiqueFormationsTable.vue';
import AnswersQuizChart from '../../components/common/graphs/AnswersQuizChart.vue';
import CandidatesChart from '../../components/common/graphs/CandidatesChart.vue';
import ProfessionsChart from '../../components/common/graphs/ProfessionsChart.vue';

export default {
  name: 'StatisticFormation',

  components: {
    StatistiqueFormationsTable,
    AnswersQuizChart,
    CandidatesChart,
    ProfessionsChart
  },
  data() {

    const defaultWeekStart = moment().startOf("week");
    const defaultWeekEnd = moment().endOf("week");

    return {
      tab: null,
      formId: '',
      dates: [
        defaultWeekStart.format('YYYY-MM-DD'),
        defaultWeekEnd.format('YYYY-MM-DD')
      ],
      headerData: {}
    };
  },
  computed: {
    agentsTrained() {
      return this.headerData.nbAgentsTrained;
    },
    agentsTrainedSuccess() {
      return this.headerData.nbSuccessTraining;
    },
    agentsTrainedSuccessPercentage() {
      return (this.headerData.nbSuccessTraining * 100) / this.headerData.nbAgentsTrained || 0;
    },
    agentsTrainedFailed() {
      return this.headerData.nbAgentsTrained - this.headerData.nbSuccessTraining || 0;
    },
    agentsTrainedFailedPercentage() {
      return (this.agentsTrainedFailed * 100) / this.headerData.nbAgentsTrained || 0;
    },
    interviewsTaken() {
      return this.headerData.nbInterviewsTaken;
    },
    interviewsAwaiting() {
      return this.headerData.nbInterviewsAwaiting;
    },
    interviewsSuccess() {
      return this.headerData.nbSuccessInterview;
    },
    interviewsSuccessPercentage() {
      return (this.headerData.nbSuccessInterview * 100) / this.headerData.nbInterviewsTaken || 0;
    },
    generalParams() {
      let params = '?';
      if (this.dates[0]) {
        params = params.concat(`startPeriod=${this.dates[0]}&`);
      }
      if (this.dates[1]) {
        params = params.concat(`endPeriod=${this.dates[1]}&`);
      }
      if (this.formId) {
        params = params.concat(`formId=${this.formId}&`);
      }

      return params;
    },
    ...mapGetters('formManagement', ['formsList'])
  },
  watch: {
    dates: {
      immediate: true,
      deep: true,
      handler(newDates) {
        if (newDates[1]) {
          if (moment(newDates[1]).isBefore(moment(newDates[0]))) {
            const temp = newDates[0];
            // eslint-disable-next-line prefer-destructuring
            this.dates[0] = newDates[1];
            this.dates[1] = temp;
          }
        }
      }
    },
    generalParams(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== '') {
        this.getHeaderData();
      }
    }
  },
  mounted() {
    this.getAllForms();
    this.getHeaderData();
  },
  methods: {
    async getHeaderData() {
      this.headerData = await getHeader(this.generalParams);
    },
    ...mapActions('formManagement', ['getAllForms'])
  }
};
</script>

<style>
.statistics-page {
  padding: 5px;
}

.stats-block {
  border: 1px solid #e4e6e8;
  border-radius: 4px;
}
</style>
