<template>
  <div v-if="showModals" class="modals-wrapper">
    <component :is="modal.name" v-for="modal in $modals.getAll()" :key="modal.id" :modal="modal" @close="closeHandler(modal.id)" />
  </div>
</template>

<script>
export default {
  name: 'TheModals',
  computed: {
    showModals() {
      return this.$modals.getAll().length > 0;
    }
  },
  methods: {
    closeHandler(id) {
      this.$modals.hide(id);
    }
  }
};
</script>
