<template>
  <div>
    <Control @add="addItem" @remove="removeItemById" @edit="editItem" />
    <v-data-table
      v-model="selected"
      fixed-header
      :headers="headers"
      :items="data"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      }"
      height="600"
      :single-select="true"
      show-select
      class="simple-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @input="selectItem"
    >
      <template v-slot:header.name="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="simple-table--sort-icon" />
        </div>
      </template>

      <template v-slot:header.date="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="simple-table--sort-icon" />
        </div>
      </template>

      <template v-slot:item.date="{ item }">
        {{ formatTime(item.createdAt) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { get } from 'lodash';

import { formatValues } from '@/mixins/formatValues.mixin';
import Control from '@/components/sessions/Control';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';

export default {
  components: {
    Control,
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    text: {
      type: Object,
      default: () => {}
    },
    selectedAction: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      singleSelect: true,
      selected: [],
      sortBy: 'label',
      sortDesc: false,
      headers: [
        {
          text: 'Corps de métier',
          divider: true,
          align: 'start',
          sortable: true,
          width: '100%',
          value: 'label'
        }
      ]
    };
  },
  computed: {
    selectedItem() {
      return this.selected[0] || {};
    },
    selectedId() {
      return get(this.selectedItem, 'id', null);
    }
  },
  methods: {
    selectItem() {
      this.$emit('selectForm', this.selectedId);
    },
    removeItemById() {
      if (!this.selectedId) {
        return;
      }
      this.$modals.open({
        name: 'RemoveItem',
        payload: {
          text: {
            title: this.text.remove.title,
            subTitle: this.text.remove.subTitle
          },
          submit: () => {
            this.$emit('remove', this.selectedId);
          }
        }
      });
    },
    editItem() {
      if (!this.selectedId) {
        return;
      }

      this.$modals.open({
        name: 'ChangeItem',
        payload: {
          text: {
            title: this.text.edit.title
          },
          oldValues: {
            name: this.selectedItem.label
          },
          submit: data => {
            this.$emit('edit', { id: this.selectedId, data: { label: data.name } });
            this.selected = [];
          }
        }
      });
    },
    addItem() {
      this.$modals.open({
        name: 'ChangeItem',
        payload: {
          text: {
            title: this.text.add.title
          },
          oldValues: null,
          submit: data => {
            this.$emit('add', { label: data.name });
          }
        }
      });
    }
  }
};
</script>

<style></style>
