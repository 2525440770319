<template>
  <div>
    <LoginForm :errors="errorText" :isBan="isIPBan" @submit="login($event)" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { ROUTES, ROUTES_NAME } from '@/common/constants';
import LoginForm from '@/components/forms/auth/LoginForm';
import { auth } from '@/mixins/auth.mixin';

export default {
  name: ROUTES_NAME.login,
  components: {
    LoginForm
  },

  mixins: [auth],
  computed: {
    ...mapGetters('auth', ['errors', 'isBan']),
    errorText() {
      return this.errors || '';
    },
    isIPBan() {
      if (this.errors === 'Erreur 401') {
        return (this.isBan = true);
      }
    }
  },
  mounted() {
    if (this.isAuth) {
      this.$router.push(ROUTES.sessions);
    }
  },
  methods: {
    ...mapActions('auth', ['login'])
  }
};
</script>
