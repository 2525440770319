<template>
  <div>
    <div v-if="access.length" class="sessions-control d-flex justify-end align-center">
      <div class="sessions-control--export" @click.stop.prevent="exportData">
        <div class="d-flex justify-end align-center font-weight-medium">
          Exporter
        </div>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="access"
      :items-per-page="-1"
      fixed-header
      height="500"
      class="access-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:header.firstname="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="access-table--sort-icon" />
        </div>
      </template>

      <template v-slot:item.date="{ item }">
        {{ formatDateHourMinute(item.date) }}
      </template>

      <template v-slot:item.type="{ item }">
        {{ getTypeText(item.type) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { json2excel } from 'js2excel';

import { formatValues } from '@/mixins/formatValues.mixin';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';

export default {
  name: 'AnomaliesTable',
  components: {
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    access: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sortBy: ['date'],
      sortDesc: true
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Date",
          divider: true,
          align: 'start',
          sortable: true,
          value: 'date'
        },
        {
          text: 'Type',
          divider: true,
          align: 'start',
          sortable: true,
          value: 'type'
        },
        {
          text: "Nom",
          divider: true,
          align: 'start',
          sortable: true,
          value: 'Agent.lastname'
        },
        {
          text: "Prénom",
          divider: true,
          align: 'start',
          sortable: true,
          value: 'Agent.firstname'
        },
        {
          text: "Profil",
          divider: true,
          align: 'start',
          sortable: true,
          value: 'Agent.AgentProfile.name'
        },
        {
          text: "Badge",
          divider: true,
          align: 'start',
          sortable: false,
          value: 'Agent.badge'
        },
        {
          text: "Entreprise",
          divider: true,
          align: 'start',
          sortable: true,
          value: 'Agent.Enterprise.name'
        },
        {
          text: "Commentaire",
          divider: true,
          align: 'start',
          sortable: true,
          value: 'comment'
        }
      ];
    }
  },
  methods: {
    getTypeText(type) {
      const intType = parseInt(type, 10);
      switch (intType) {
        case 0:
          return 'Badge refusé';
        case 1:
          return 'Entrée autorisée - Badge inconnu';
        case 2:
          return 'Sortie autorisée - Badge inconnu';
        case 3:
          return 'Refus Anti-passback';
        case 4:
          return 'Sortie réalisée par le système suite à une sortie non badgée';
        case 5:
          return 'Sortie réalisée sans entrée préalable';
      }
    },

    exportData() {
      const anomaliesList = this.access
        .sort((a, b) => b.date.localeCompare(a.date))
        .map(item => {
          const agent = [
            ["Date", this.formatDateHourMinute(item.date)],
            ["Type", this.getTypeText(item.type)],
            ["Nom", item?.Agent?.lastname ? item.Agent.lastname : '-'],
            ["Prénom", item?.Agent?.firstname ? item.Agent.firstname : '-'],
            ["Entreprise", item?.Agent?.Enterprise.name ? item.Agent.Enterprise.name : '-'],
            ["Badge", item?.Agent?.badge ? item.Agent.badge : '-'],
            ["Commentaire", item.comment]
          ];
          return Object.fromEntries(agent);
        });

      try {
        json2excel({
          data: anomaliesList,
          name: 'anomalies-list'
        });
      } catch (e) {
        console.error('export error', e);
      }
    }
  }
};
</script>

<style lang="scss">
.sessions-control-icon {
  padding: 0 4px;
}

.access-table {
  .v-data-table-header__icon {
    display: none;
  }

  &--form {
    color: #0094ff !important;
    text-decoration: underline;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
