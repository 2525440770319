<template>
  <div class="accounts-page d-flex align-start flex-column">
    <v-col cols="12" class="accounts-page--control">
      <Control :show-lock="true" @add="AddNewRole" @remove="removeItemById" @edit="editAccount" />
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between accounts-page--tables">
      <v-col cols="6" class="accounts-page--accounts">
        <RolesAdminTable :roles="roles" @set-selected-id="selectedAccount = $event" />
      </v-col>
      <v-col cols="6" class="accounts-page--roles">
        <PermissionsAdminTable
          v-if="newPermissions"
          :key="selectedId"
          :selected-id="selectedId"
          :permissions="newPermissions"
          :add-permission-to-role="addPermissionToRole"
        />
      </v-col>
    </v-col>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';

import { ROUTES_NAME } from '@/common/constants';
import Control from '@/components/sessions/Control';
import RolesAdminTable from '@/components/common/tables/RolesAdminTable';
import PermissionsAdminTable from '@/components/common/tables/PermissionsAdminTable';

export default {
  name: ROUTES_NAME.roles,
  components: {
    RolesAdminTable,
    PermissionsAdminTable,
    Control
  },
  data() {
    return {
      newPermissionsList: [],
      selectedAccount: []
    };
  },
  computed: {
    ...mapGetters('accounts', ['allPermissions']),
    selectedItem() {
      return this.selectedAccount[0] || {};
    },
    selectedId() {
      return get(this.selectedItem, 'id', null);
    },
    roles() {
      return this.allPermissions.rolesList;
    },
    newPermissions() {
      this.changeListPermissions();

      if (!this.selectedId) {
        return [];
      }

      const currentRoles = this.selectedId;

      return this.newPermissionsList.map(item => {
        let status = false;

        if (item.roleId.includes(currentRoles)) {
          status = true;
        }

        return {
          id: item.id,
          name: item.name,
          description: `${item.name} - ${item.description}`,
          status
        };
      });
    }
  },
  mounted() {
    this.fetchAccess();
    this.fetchRoles();
  },
  methods: {
    ...mapActions('accounts', ['fetchRoles', 'fetchAccess', 'addPermissionToRole', 'addNewRoleAction']),
    changeListPermissions() {
      if (!this.allPermissions.permissionList) {
        return [];
      }

      const rolesList = this.allPermissions.rolesPermissionsList;
      this.newPermissionsList = this.allPermissions.permissionList;

      return this.newPermissionsList.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.roleId = [];
        // eslint-disable-next-line array-callback-return
        rolesList.map(roleItem => {
          if (item.id === roleItem.permissionId) {
            return item.roleId.push(roleItem.roleId);
          }
        });
      });
    },
    AddNewRole() {
      this.$modals.open({
        name: 'AddNewRole',
        payload: {
          oldValues: null,
          roles: this.roles,
          submit: data => {
            this.addNewRoleAction(data);
          }
        }
      });
      this.$forceUpdate();
    },
    removeItemById() {},
    editAccount() {},
    lockAccount() {}
  }
};
</script>

<style lang="scss">
.accounts-page {
  tr,
  td {
    height: 48px !important;
    max-height: 48px !important;
  }

  &--control {
    padding-left: 24px;
    padding-right: 24px;
  }

  &--tables {
    margin-top: -10px;
    padding-top: 0px;
  }
}
</style>
