<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="remove-session-modal">
      <div class="remove-session-modal--header font-weight-light">
        Supprimer la session
      </div>
      <div class="remove-session-modal--content">
        <div class="remove-session-modal--content-label font-weight-medium">
          {{ subtitle }}
        </div>
      </div>
      <div class="remove-session-modal--buttons d-flex justify-end">
        <v-btn class="remove-session-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn v-if="!finishedSession" class="remove-session-modal--buttons-save" color="#38BB00" dark depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'RemoveSessionModal',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    finishedSession() {
      return this.modal.payload.state === 4;
    },
    subtitle() {
      if (this.finishedSession)
        return "Vous ne pouvez pas supprimer cette session car elle est terminée";
      else
        return "Êtes-vous sûr de vouloir supprimer la session ?"
    }
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.modal.payload.submit();
      this.close();
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}
.remove-session-modal {
  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  &--header {
    text-align: center;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 150px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-list {
      width: 60%;
      margin-top: 40px;
    }

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
