export const SET_ROLES = 'SET_ROLES';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_ALL_PERMISSIONS = 'SET_ALL_PERMISSIONS';
export const SET_ALL_ROLES = 'SET_ALL_ROLES';
export const SET_PERMISSIONS_ACCOUNT = 'SET_PERMISSIONS_ACCOUNT';
export const SET_ROLES_ACCOUNT_TABLE = 'SET_ROLES_ACCOUNT_TABLE';
export const SET_ZONE_LIST = 'SET_ZONE_LIST';

export const SET_CHANGE_ROLE = 'SET_CHANGE_ROLE';
export const SET_CHANGE_PERMISSION = 'SET_CHANGE_PERMISSION';
export const SET_PERMISSIONS_TABLE_ACCOUNT = 'SET_PERMISSIONS_TABLE_ACCOUNT';
export const SET_CHANGE_MODAL = 'SET_CHANGE_MODAL';
