<template>
  <div id="statsContainer">
    <div style="width: 100%; height:auto;" class=" d-flex justify-end align-center  pa-2 ">
      <div v-if="filteredHours.length">
        <div class="sessions-control--export" @click.stop.prevent="exportData">
          <div class="d-flex justify-end align-center font-weight-medium">
            Exporter
          </div>
        </div>
      </div>
    </div>
    <v-data-table
      id="statsTable"
      fixed-header
      :headers="defaultHeaders"
      :items="filteredHours"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      }"
      height="600"
    >
    </v-data-table>
  </div>
</template>

<script>
import { json2excel } from 'js2excel';
import moment from 'moment';

import 'moment-duration-format';
import 'moment/locale/fr';
import frLocale from '@/i18n/moment/fr';

moment.updateLocale('fr', frLocale);

export default {
  props: {
    hours: {
      type: Array,
      default: () => []
    },
    searchQuery: {
      type: String,
      default: ''
    }
  },
  computed: {
    filteredHours() {
      if (this.hours?.length > 0) {
        const localHours = this.hours.map(item => ({
          ...item,
          totalTimeSpent: moment.duration(item.totalTimeSpent, 'seconds').format('D __ h _ m _ s _')
        }));
        if (this.searchQuery) {
          return [...localHours].filter(
            hour =>
              hour.entreprise.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
              hour.site.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        }
        return localHours;
      }
      return [];
    },
    defaultHeaders() {
      return [
        {
          text: 'Entreprise',
          divider: true,
          align: 'start',
          sortable: true,
          width: '33%',
          value: 'entreprise.name'
        },
        {
          text: 'Site',
          divider: true,
          align: 'start',
          sortable: true,
          width: '33%',
          value: 'site.name'
        },
        {
          text: 'Heures passées',
          divider: true,
          align: 'start',
          width: '34%',
          sortable: true,
          value: 'totalTimeSpent'
        },
        {
          text: 'Personne Unique sur site',
          divider: true,
          align: 'start',
          width: '34%',
          sortable: true,
          value: 'nbUniqEntrepriseSiteMoves'
        },
        {
          text: 'Entrées',
          divider: true,
          align: 'start',
          width: '34%',
          sortable: true,
          value: 'nbEntrepriseSiteMovesIn'
        },
        {
          text: 'Sorties',
          divider: true,
          align: 'start',
          width: '34%',
          sortable: true,
          value: 'nbEntrepriseSiteMovesOut'
        },
        {
          text: 'Anomalies',
          divider: true,
          align: 'start',
          width: '34%',
          sortable: true,
          value: 'nbEntrepriseAnormalMoves'
        }
      ];
    }
  },
  methods: {
    datesUpdate(dates) {
      this.$emit('datesUpdate', dates);
    },
    exportData() {
      const hoursList = this.filteredHours.map(item => {
        const hour = [
          ['Entreprise', item.entreprise.name],
          ['Site', item.site.name],
          ['Heures Passées', item.totalTimeSpent]
        ];
        return Object.fromEntries(hour);
      });

      try {
        json2excel({
          data: hoursList,
          name: 'hours-list'
        });
      } catch (e) {
        console.error('export error', e);
      }
    }
  }
};
</script>

<style lang="scss">
#statsContainer {
  margin-top: 5px;
  display: flex;
  flex-flow: column nowrap;
}
#statsTable {
  border-top: 1px solid #c4c4c4;
}
</style>
