<template>
  <div>
    <Control @add="addQuestion" @remove="removeQuestionById" @edit="editQuestionById" />
    <v-data-table
      v-model="selected"
      fixed-header
      :headers="headers"
      :items="questions"
      :items-per-page="-1"
      height="600"
      :single-select="true"
      class="questions-table"
      show-select
      :must-sort="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:header.position="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="themes-table--sort-icon" />
        </div>
      </template>
      <template v-slot:item.questionThemeId="{ item }">
        {{ getThemeText(item.questionThemeId) }}
      </template>

      <template v-slot:item.type="{ item }">
        {{ questionType(item.type) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { get, find } from 'lodash';

import Control from '@/components/sessions/Control';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';
import { formatValues } from '@/mixins/formatValues.mixin';
import questionTypes from '@/mixins/questionTypes.mixin';

export default {
  name: 'ThemesTable',
  components: {
    Control,
    TwoArrowSort
  },
  mixins: [formatValues, questionTypes],
  props: {
    questions: {
      type: Array,
      default: () => []
    },
    questionsThemes: {
      type: Array,
      default: () => []
    },
    currentFormId: {
      type: String
    }
  },
  data() {
    return {
      selected: [],
      sortBy: ['position']
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'N°',
          divider: true,
          align: 'center',
          sortable: true,
          width: '5%',
          value: 'position'
        },
        {
          text: 'Theme',
          divider: true,
          align: 'start',
          width: '15%',
          value: 'questionThemeId'
        },
        {
          text: 'Type',
          divider: true,
          align: 'start',
          width: '22%',
          value: 'type'
        },
        {
          text: 'Bonnes réponses',
          divider: true,
          align: 'start',
          width: '18%',
          value: 'goodAnswers'
        },
        {
          text: 'Mauvaises réponses',
          divider: true,
          align: 'start',
          width: '20%',
          value: 'badAnswers'
        },
        {
          text: 'Réponses possibles',
          divider: true,
          align: 'start',
          width: '10%',
          value: 'answerNumbers'
        },
        {
          text: 'Nb. points',
          divider: true,
          align: 'start',
          width: '10%',
          value: 'maxPoint'
        }
      ];
    },
    selectedQuestion() {
      return this.selected[0] || {};
    },
    selectedId() {
      return get(this.selectedQuestion, 'id', null);
    }
  },
  methods: {
    getThemeText(id) {
      return get(find(this.questionsThemes, { id }), 'label', '');
    },
    addQuestion() {
      if (!this.currentFormId) {
        return;
      }

      this.$modals.open({
        name: 'EditQuestionModal',
        payload: {
          position: this.questions.length + 1,
          themes: this.questionsThemes,
          submit: data => {
            const resp = { ...data };
            resp.formId = this.currentFormId;
            this.$emit('add', resp);
          }
        }
      });
    },
    editQuestionById() {
      if (!this.selectedId) {
        return;
      }
      this.$modals.open({
        name: 'EditQuestionModal',
        payload: {
          themes: this.questionsThemes,
          oldValues: {
            position: this.selectedQuestion.position,
            questionThemeId: this.selectedQuestion.questionThemeId,
            type: this.selectedQuestion.type,
            goodAnswers: this.selectedQuestion.goodAnswers,
            badAnswers: this.selectedQuestion.badAnswers,
            answerNumbers: this.selectedQuestion.answerNumbers,
            maxPoint: this.selectedQuestion.maxPoint
          },
          submit: data => {
            const resp = { ...data };
            resp.formId = this.currentFormId;
            this.$emit('edit', { id: this.selectedId, data: resp });
            this.selected = [];
          }
        }
      });
    },
    removeQuestionById() {
      if (!this.selectedId) {
        return;
      }

      this.$modals.open({
        name: 'RemoveItem',
        payload: {
          text: {
            title: 'Supprimer la question',
            subTitle: 'Vous voulez absolument supprimer la question'
          },
          submit: () => {
            this.$emit('remove', this.selectedId);
            this.selected = [];
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
// TODO: Сделать таблицы на всю страницу и с фиксированной шириной
.themes-control-icon {
  padding: 0 4px;
}

.questions-table {
  .v-data-table-header__icon {
    display: none;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
