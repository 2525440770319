<template>
  <div>
    <v-data-table
      v-model="selected"
      fixed-header
      :headers="headers"
      :items="interviews"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      }"
      height="600"
      class="interviews-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:item.state="{ item }">
        {{ statusText[item.state] }}
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ formatTime(item.createdAt) }}
      </template>

      <template v-slot:item.formName="{ item }">
        {{ item.formName }}
        <a class="agent-details-session-table--form" @click.stop.prevent="showSessionDetail(item)"> (voir) </a>
      </template>

      <template v-slot:item.closeInterviewDate="{ item }">
        <div v-if="item.state === 1">
          {{ formatTime(item.closeInterviewDate) }}
        </div>
      </template>

      <template v-slot:item.result="{ item }">
        <div v-if="item.state === 1">
          {{ resultText[item.result] }}
        </div>
      </template>

      <template v-slot:item.finish="{ item }">
        <a v-if="item.state === 0" class="show-modal" @click.stop.prevent="showInterviewModal(item)">
          réaliser
        </a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatValues } from '@/mixins/formatValues.mixin';

export default {
  name: 'InterviewsTable',
  mixins: [formatValues],
  props: {
    interviews: {
      type: Array,
      default: () => []
    },
    answers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      singleSelect: true,
      selected: [],
      statusText: ['À réaliser', 'Terminé'],
      resultText: ['Refusé', 'Validé'],
      sortBy: ['createdAt'],
      sortDesc: true
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Stagiaire",
          divider: true,
          align: 'start',
          sortable: true,
          width: '20%',
          value: 'name'
        },
        {
          text: "Statut",
          divider: true,
          align: 'start',
          sortable: true,
          width: '20%',
          value: 'state'
        },
        {
          text: "Questionnaire",
          divider: true,
          align: 'start',
          sortable: true,
          width: '20%',
          value: 'formName'
        },
        {
          text: "Commentaire session",
          divider: true,
          align: 'start',
          sortable: true,
          width: '20%',
          value: 'sessionComment'
        },
        {
          text: "Date Questionnaire",
          divider: true,
          align: 'start',
          sortable: true,
          width: '20%',
          value: 'createdAt'
        },
        {
          text: "Date de réalisation",
          divider: true,
          align: 'start',
          sortable: true,
          width: '20%',
          value: 'closeInterviewDate'
        },
        {
          text: "Évaluateurs",
          divider: true,
          align: 'start',
          sortable: true,
          width: '20%',
          value: 'interviewers'
        },
        {
          text: "Sanction",
          divider: true,
          align: 'start',
          sortable: true,
          width: '20%',
          value: 'result'
        },
        {
          text: "Commentaire",
          divider: true,
          align: 'start',
          sortable: true,
          width: '20%',
          value: 'comment'
        },
        {
          text: "",
          divider: true,
          align: 'start',
          sortable: true,
          width: '10%',
          value: 'finish'
        }
      ];
    }
  },
  methods: {
    showInterviewModal(item) {
      this.$modals.open({
        name: 'InterviewFinishModal',
        payload: {
          submit: data => {
            this.$emit('close', {
              id: item.id,
              data
            });
          }
        }
      });
    },
    finishInterview() {},
    async showSessionDetail(item) {
      const clickAction = () => {
        this.$modals.open({
          name: 'SessionDetailModal',
          payload: {
            date: item.sessionEndDate,
            answers: this.answers
          }
        });
      };

      await this.$emit('show-details', { id: item.sessionAgentId, action: clickAction });
    }
  }
};
</script>

<style lang="scss">
.interviews-table {
  table-layout: fixed;

  .show-modal {
    color: #0094ff;
    text-decoration: underline;
  }

  .v-data-table-header__icon {
    display: none;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
