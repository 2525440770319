<template>
  <div class="session-preparation">
    <Control :next-button-text="'Démarrer la session'" @add="addAgents" @remove="removeAgents" @next="confirmStartSession" />
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="sessionAgents"
      :items-per-page="-1"
      fixed-header
      height="500"
      :single-select="false"
      :item-key="'id'"
      show-select
      class="session-table"
    >
    </v-data-table>
    <v-dialog v-model="isConfirmDialogOpen" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Confirmation
        </v-card-title>

        <v-card-text class="text--primary mt-2">
          Attention, si vous validez le démarrage de la session, vous ne pourrez plus retourner à la page précédente.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#aaa" text @click="isConfirmDialogOpen = false">
            Annuler
          </v-btn>
          <v-btn color="primary" text @click="startSession">
            Démarrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>



<script>
import moment from 'moment';

import Control from '@/components/sessions/Control';

export default {
  name: 'SessionsPreporation',

  components: {
    Control
  },

  props: {
    sessionAgents: {
      type: Array,
      default: () => []
    },
    allAgents: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      selected: [],
      isConfirmDialogOpen: false
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Nom",
          divider: true,
          align: 'start',
          width: '25%',
          value: 'lastname'
        },
        {
          text: "Prénom",
          divider: true,
          align: 'start',
          width: '25%',
          value: 'firstname'
        },
        {
          text: "Entreprise",
          divider: true,
          align: 'start',
          width: '25%',
          value: 'enterpriseName'
        },
        {
          text: "Profil",
          divider: true,
          align: 'start',
          width: '25%',
          value: 'agentProfileName'
        }
      ];
    },
    selectedItems() {
      return this.selected || [];
    },
    selectedSessionAgentsIds() {
      return this.selectedItems.map(sessionAgent => sessionAgent.id);
    },
  },

  methods: {
    addAgents() {
      const agentsIds = this.sessionAgents.map(item => item.agentId);
      this.$modals.open({
        name: 'AddAgentsModal',
        payload: {
          agents: this.allAgents.filter(
            item =>
              // on filtre les agents qui sont déjà dans la sessions
              !agentsIds.includes(item.id) &&
              // on filtre les agents qui ont un accès encore valable au moins un mois
              (item.accessEndDate === null ||
                moment()
                  .add(1, 'month')
                  .isAfter(moment(item.accessEndDate)))
          ),
          submit: (agentIds) => {
            this.$emit('add-agents', agentIds);
          }
        }
      });
    },

    removeAgents() {
      const agentIds = this.selectedSessionAgentsIds;

      if (!agentIds || agentIds.length === 0) {
        return;
      }

      this.$modals.open({
        name: 'RemoveAgentsModal',
        payload: {
          count: `${agentIds.length}`,
          submit: () => {
            this.$emit('remove-agents', agentIds);
            this.selected = [];
          }
        }
      });
    },

    startSession() {
      this.$emit('start-session');
      this.isConfirmDialogOpen = false;
    },

    confirmStartSession() {
      if (!this.sessionAgents.length) {
        return;
      }
      this.isConfirmDialogOpen = true;
    }
  }
};
</script>



<style lang="scss">
.session-preparation {
  margin-top: 47px;

  .sessions-control {
    .edit {
      display: none;
    }
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }
}
</style>
