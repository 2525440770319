import * as types from './mutation-types';

import { getAll, addTheme, deleteTheme, changeTheme } from '@/services/questionsThemes.service';

export default {
  async getAllThemes({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getAll();
      commit(types.SET_QUESTIONS_THEMES, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async newTheme({ commit, dispatch }, data) {
    commit(types.SET_ERRORS, null);
    try {
      await addTheme(data);
      dispatch('getAllThemes');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async removeTheme({ commit, dispatch }, id) {
    commit(types.SET_ERRORS, null);
    try {
      await deleteTheme(id);
      dispatch('getAllThemes');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async editTheme({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await changeTheme(id, data);
      dispatch('getAllThemes');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
