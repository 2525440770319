import { sync } from 'vuex-router-sync';
import loader from 'vue-ui-preloader';

import router from '@/router';
import store from '@/store';
import * as config from '@/config';
import * as constants from '@/common/constants';
import modals from '@/plugins/modals';
import modalsComponents from '@/components/modals';

/**
 * Used for adding plugins and preparing Vue instance for initialization.
 *
 * @instance { Vue }
 */
export const bootstrapApplication = Vue => {
  /* eslint-disable no-param-reassign */
  Vue.prototype.$config = config;
  Vue.prototype.$constants = constants;
  /* eslint-enable no-param-reassign */

  Vue.use(loader);

  Vue.use(modals, {
    store,
    components: modalsComponents
  });

  sync(store, router, {
    moduleName: 'router'
  });
};
