<template>
  <div>
    Settings
  </div>
</template>

<script>
import { ROUTES_NAME } from '@/common/constants';

export default {
  name: ROUTES_NAME.settings
};
</script>
