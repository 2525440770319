import * as types from './mutation-types';

import {
  fetchAllRoles,
  fetchAllRolePermissions,
  fetchAllAccess,
  addNew,
  removeAccount,
  addRoleToAccount,
  fetchAllAccounts,
  updateAccount,
  changePassword
} from '@/services/accounts.service';

export default {
  async fetchRoles({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAllRoles();
      commit(types.SET_ROLES, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async fetchAccess({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAllAccess();
      commit(types.SET_ALL_PERMISSIONS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async fetchRolesPermissions({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAllRolePermissions();
      commit(types.SET_ROLES_PERMISSIONS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async fetchAccounts({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAllAccounts();
      commit(types.SET_ACCOUNTS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async addNewUser({ commit, dispatch }, data) {
    commit(types.SET_ERRORS, null);
    try {
      await addNew(data);
      dispatch('fetchAccounts');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async removeUser({ commit, dispatch }, id) {
    commit(types.SET_ERRORS, null);
    try {
      await removeAccount(id);
      dispatch('fetchAccounts');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async updateUser({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await updateAccount(id, data);
      dispatch('fetchAccounts');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async changeUserPassword({ commit }, { id, password }) {
    commit(types.SET_ERRORS, null);
    try {
      await changePassword(id, password);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async addNewRoleToAccount({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await addRoleToAccount(id, data);
      dispatch('fetchAccounts');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
