<template>
  <div class="agent-block" :class="colorStatus">
    <div class="agent-block--control-buttons">
      <Budge v-if="!isBadge" class="agent-block--control-buttons-budge" @click.native="changeBudge" />
      <Qr v-if="!isPairing" class="agent-block--control-buttons-qr" @click.native="showQr" />
    </div>
    <div class="agent-block--title">{{ agent.lastname }} {{ agent.firstname }}</div>
    <div class="agent-block--title">{{ agent.enterpriseName }} - {{ agent.agentProfileName }}</div>
    <div v-if="!isPairing" class="agent-block--status font-weight-bold">En attente tablette</div>
    <div v-if="!isBadge" class="agent-block--status font-weight-bold">En attente badge</div>
    <div v-if="isBadge && isPairing" class="agent-block--status font-weight-bold">Prêt</div>
    <div v-if="!isBadge" class="agent-block--container-remove-agent">
      <v-dialog v-model="isDialogOpen" width="500">
        <template v-slot:activator="{ attrs }">
          <RemoveAgent
            class="agent-block--control-buttons-remove-agent"
            :style="isPairing ? 'margin-top: 45px;' : ''"
            v-bind="attrs"
            @click.native="isDialogOpen = true"
          ></RemoveAgent
        ></template>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Supprimer l'agent
          </v-card-title>

          <v-card-text style="margin-top: 1em">
            L'agent est absent ou indisponible ? Supprimez-le de la liste des participants de cette session.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#aaa" text @click="isDialogOpen = false">
              Annuler
            </v-btn>
            <v-btn color="primary" autofocus text @click="removeAgent">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Budge from '@/components/common/icons/Budge.vue';
import Qr from '@/components/common/icons/Qr.vue';
import RemoveAgent from '@/components/common/icons/RemoveAgent.vue';

export default {
  name: 'AgentCard',
  components: {
    Budge,
    Qr,
    RemoveAgent
  },
  props: {
    agent: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      isDialogOpen: false
    };
  },
  computed: {
    isPairing() {
      return this.agent.deviceToken;
    },
    isBadge() {
      return this.agent.badge;
    },
    agentStatus() {
      if (this.isPairing && this.isBadge) {
        return 'Tablette appairée';
      }

      if (!this.isPairing && this.isBadge) {
        return 'En attente de tablette';
      }

      if (this.isPairing && !this.isBadge) {
        return 'En attente du badge';
      }

      return 'En attente de tablette \n En attente du badge';
    },
    cardColor() {
      if (this.isPairing && this.isBadge) {
        return true;
      }

      return false;
    },
    colorStatus() {
      const color = this.cardColor ? 'active' : 'disabled';
      return `agent-block--${color}`;
    }
  },
  methods: {
    ...mapActions('sessionAgents', ['setAgentBadge']),
    ...mapActions('sessions', ['removeAgentsFromSession']),
    changeBudge() {
      this.$modals.open({
        name: 'BadgeModal',
        payload: {
          agentName: `${this.agent.firstname} ${this.agent.lastname}`,
          agentBadge: this.agent.agentBadge,
          submit: badge => {
            this.setAgentBadge({ id: this.agent.id, data: badge });
          }
        }
      });
    },
    removeAgent() {
      this.isDialogOpen = false;
      this.removeAgentsFromSession({
        id: this.agent.sessionId,
        agentIds: [this.agent.id]
      });
    },
    showQr() {
      this.$modals.open({
        name: 'QrModal',
        payload: {
          agentName: `${this.agent.firstname} ${this.agent.lastname}`,
          agentSessionId: this.agent.id,
          waitForQr: () => {
            this.$emit('waitForQr', this.agent.id);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.agent-block {
  position: relative;
  width: 100%;
  height: 100%;

  &--title {
    padding-top: 8px;
  }

  &--status {
    padding-top: 18px;
    text-align: center;
    font-size: 20px;
  }

  &--title,
  &--status {
    padding-left: 18px;
  }

  &--active {
    background-color: #b6d7a8;
  }

  &--disabled {
    background-color: #ea9999;
  }

  &--container {
    &-remove-agent {
      display: flex;
      flex-flow: row;
      justify-content: end;
      margin-bottom: 10px;
      margin-top: -10px;
    }
  }

  &--control-buttons {
    position: absolute;
    top: 10px;
    right: 0;

    &-budge {
      margin-right: 14px;
    }

    &-qr,
    &-remove-agent {
      margin-right: 10px;
    }

    &-budge,
    &-remove-agent,
    &-qr {
      cursor: pointer;
    }
  }
}
</style>
