<template>
  <div class="pageContainer pa-6 ma-2">
    <div v-show="!loading">
      <div id="lineBarChartContainer">
        <canvas id="lineBarChart" ref="lineBarChart"></canvas>
      </div>
    </div>
    <div v-show="loading">
      <div class="d-flex justify-center ">
        <v-progress-circular :indeterminate="true" class="mx-auto"></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, BarController, LineController, Title, Tooltip, Legend, PointElement, LineElement, BarElement, CategoryScale, LinearScale } from 'chart.js';

import { getResponses } from '../../../services/statsFormations.service';

Chart.register(Title, PointElement, LineElement, BarController, LineController, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  props: {
    generalParams: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      chart: null,
      labels: [],
      datasets: [
        {
          type: 'line',
          label: 'Moyenne des réponses',
          borderColor: '#4874c5',
          data: [],
          options: {
            layout: {
              padding: 20
            }
          }
        },
        {
          type: 'bar',
          label: '100% de bonnes réponses',
          backgroundColor: '#eb7c31',
          data: [],
          options: {
            layout: {
              padding: 20
            }
          }
        }
      ]
    };
  },
  computed: {},
  watch: {
    generalParams(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== '') {
        this.fetchAnswersStats();
      }
    },
    datasets: {
      immediate: true,
      deep: true,
      handler() {
        this.updateChart();
      }
    }
  },
  mounted() {
    this.fetchAnswersStats();
  },
  methods: {
    async fetchAnswersStats() {
      this.loading = true;
      const answers = await getResponses(this.generalParams);
      this.datasets[0].data = answers.map(answer => answer.averageNote);
      this.datasets[1].data = answers.map(answer => answer.percFullyValidAnswer);

      this.labels = answers.map((answer, index) => index + 1);
      this.loading = false;
    },
    updateChart() {
      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(this.$refs.lineBarChart, {
        data: {
          datasets: this.datasets,
          labels: this.labels
        }
      });
    }
  }
};
</script>

<style>
#lineBarChart {
  max-height: 50vh;
  height: 100%;
}
</style>
