var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"agent-details-session-table",attrs:{"headers":_vm.headers,"items":_vm.sessions,"fixed-header":"","height":"500","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header.rgpdConsentDate",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('TwoArrowSort',{staticClass:"agent-details-session-table--sort-icon"})],1)]}},{key:"item.rgpdConsentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rgpdConsentDate ? _vm.formatTime(item.rgpdConsentDate) : "Pas de date")+" ")]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('a',[_vm._v(" "+_vm._s(_vm.statusText[item.result])+" ")])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"agent-details-session-table--form",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showSessionDetail(item)}}},[_vm._v(" Détail ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }