import * as types from './mutation-types';

export default {
  [types.SET_LIST](state, sites) {
    state.sites = sites;
  },
  [types.SET_ALLOWED_SITES_LIST](state, list) {
    state.allowedSitesList = list;
  },
  [types.SET_ALLOWED_SITE](state, list) {
    state.allowedSites = list;
  },
  [types.SET_MOUVEMENTS_LIST](state, list) {
    state.mouvementsList = list;
  },
  [types.SET_COMMUNICATIONS_LIST](state, list) {
    state.communicationsList = list;
  },
  [types.SET_ANOMALIES_LIST](state, list) {
    state.anomaliesList = list;
  },
  [types.SET_ACTUAL_AGENT_LIST](state, list) {
    state.actualAgentsList = list;
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [types.SET_SITE](state, site) {
    state.site = site;
  },
  [types.SET_SELECT_ZONE](state, selectZone) {
    state.selectZone = selectZone;
  },
  [types.SET_LIST_PERMISSION](state, listPermission) {
    state.listPermission = listPermission;
  }
};
