import * as types from './mutation-types';

import { fetchAll, get, remove, newItem, editItem, getProfiles, getEnterprises, removeAgents } from '@/services/agents.service';

export default {
  /**
   * Retourne un tableau qui contient Agent & le SessionAgent associé
   */
  async fetchList({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAll();
      commit(types.SET_LIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async get({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await get(id);
      commit(types.SET_AGENT, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async removeItem({ commit, dispatch }, id) {
    commit(types.SET_ERRORS, null);
    try {
      await remove(id);
      dispatch('fetchList');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async removeItems({ commit, dispatch }, ids) {
    commit(types.SET_ERRORS, null);
    try {
      await removeAgents(ids);
      dispatch('fetchList');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async addItem({ commit, dispatch }, data) {
    commit(types.SET_ERRORS, null);
    try {
      await newItem(data);
      dispatch('fetchList');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async changeItemById({ commit, dispatch }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await editItem(id, data);
      dispatch('fetchList');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getAgentProfiles({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getProfiles();
      commit(types.SET_PROFILES, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getAgentEnterprises({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getEnterprises();
      commit(types.SET_ENTERPRISES, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
