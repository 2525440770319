import * as types from './mutation-types';

export default {
  toggleLoader({ commit }) {
    commit(types.TOGGLE_LOADER);
  },
  getConfig({ commit }) {
    commit(types.GET_CONFIG);
  }
};
