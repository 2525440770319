var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Control',{on:{"add":_vm.addSession,"remove":_vm.removeSessionById,"edit":_vm.editSessionById}}),_c('v-data-table',{staticClass:"session-table",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.sessions,"items-per-page":20,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50, -1]
    },"height":"600","single-select":true,"show-select":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"multi-sort":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header.label",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))])])]}},{key:"header.date",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))])])]}},{key:"header.state",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))])])]}},{key:"header.formName",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))])])]}},{key:"header.minValid",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))])])]}},{key:"header.minInterview",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))])])]}},{key:"header.accessAndValidity",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))])])]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.date))+" ")]}},{key:"item.state",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setState(item.state))+" ")]}},{key:"item.accessAndValidity",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.accessAndValidity))+" ")]}},{key:"item.minValid",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.minValid.toFixed(2))+" ")]}},{key:"item.minInterview",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.minInterview.toFixed(2))+" ")]}},{key:"item.details",fn:function(ref){
    var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('set-session', item)}}},[_vm._v(" Détail ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }