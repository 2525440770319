<template>
  <div>
    <Control @add="addAccess" @remove="removeAccess" @edit="editAccess" />
    <v-data-table
      v-model="selected"
      fixed-header
      height="500"
      :headers="headers"
      :items="access"
      class="agent-details-access-table"
      :single-select="true"
      show-select
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:header.beginDate="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="agent-details-access-table--sort-icon" />
        </div>
      </template>

      <template v-slot:item.beginDate="{ item }">
        {{ formatTime(item.beginDate) }}
      </template>

      <template v-slot:item.endDate="{ item }">
        {{ formatTime(item.endDate) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { get } from 'lodash';

import { formatValues } from '@/mixins/formatValues.mixin';
import Control from '@/components/sessions/Control';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';

export default {
  name: 'AgentAccessTable',
  components: {
    Control,
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    access: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      statusText: ["Refusé", "Entretien", "Validé"],
      selected: [],
      sortBy: 'beginDate',
      sortDesc: true
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Date d’obtention",
          divider: true,
          align: 'start',
          sortable: true,
          width: '50%',
          value: 'beginDate'
        },
        {
          text: "Date de fin de validité",
          divider: true,
          align: 'start',
          width: '50%',
          sortable: true,
          value: 'endDate'
        }
      ];
    },
    selectedAccess() {
      return this.selected[0] || {};
    },
    selectedId() {
      return get(this.selectedAccess, 'id', null);
    }
  },
  methods: {
    addAccess() {
      this.$modals.open({
        name: 'ChangeAccessModal',
        payload: {
          submit: data => {
            this.$emit('add', data);
          }
        }
      });
    },
    removeAccess() {
      if (!this.selectedId) {
        return;
      }
      this.$modals.open({
        name: 'RemoveItem',
        payload: {
          text: {
            title: "Supprimer l'accès",
            subTitle: "Veux vraiment supprimer l'accès?"
          },
          submit: () => {
            this.$emit('remove', this.selectedId);
          }
        }
      });
    },
    editAccess() {
      if (!this.selectedId) {
        return;
      }

      const { beginDate, endDate } = this.selectedAccess;

      this.$modals.open({
        name: 'ChangeAccessModal',
        payload: {
          oldValues: {
            beginDate,
            endDate
          },
          submit: data => {
            this.$emit('edit', { ...data, id: this.selectedId });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.sessions-control-icon {
  padding: 0 4px;
}

.agent-details-access-table {
  .v-data-table-header__icon {
    display: none;
  }

  &--form {
    color: #0094ff !important;
    text-decoration: underline;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
