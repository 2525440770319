<template>
  <div class="section-page">
    <SectionsTable v-if="showTable" :access="sites" @add="newSite($event)" @remove="removeSite($event)" @edit="changeSite($event)" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { ROUTES_NAME } from '@/common/constants';
import SectionsTable from '@/components/common/tables/SectionsTable';

export default {
  name: ROUTES_NAME.sections,
  components: {
    SectionsTable
  },
  data() {
    return {
      showTable: false
    };
  },
  computed: {
    ...mapGetters('site', ['sites'])
  },
  async mounted() {
    await this.fetchSites();
    this.showTable = true;
  },
  methods: {
    ...mapActions('site', ['fetchSites', 'newSite', 'removeSite', 'changeSite'])
  }
};
</script>

<style lang="scss">
.section-page {
  padding: 0 10px;

  &--header {
    &-name {
      font-size: 36px;
      font-weight: 700;
    }
  }

  &--tabs {
    display: block;
    margin-top: 20px;
    &-item {
      background-color: #f2f2f2;
      display: inline-block;
      height: 45px;
      padding: 14px 24px;
      cursor: pointer;
    }

    .active {
      background-color: #ffffff;
    }
  }
}
</style>
