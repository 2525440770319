<template>
  <div>
    <div v-if="access.length" class="sessions-control d-flex justify-end align-center">
      <div class="sessions-control--export" @click.stop.prevent="exportData">
        <div class="d-flex justify-end align-center font-weight-medium">
          Exporter
        </div>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="access"
      :items-per-page="-1"
      fixed-header
      height="500"
      class="access-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:header.firstname="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="access-table--sort-icon" />
        </div>
      </template>

      <template v-slot:item.date="{ item }">
        {{ item.actualSiteEntranceDate ? formatDateHourMinute(item.actualSiteEntranceDate) : formatDateHourMinute(item.updatedAt) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { get } from 'lodash';
import { json2excel } from 'js2excel';

import { formatValues } from '@/mixins/formatValues.mixin';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';

export default {
  name: 'AccessTable',
  components: {
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    access: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      statusText: ["Refusé", "Entretien", "Validé"],
      selected: [],
      sortBy: 'date',
      sortDesc: true
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Nom",
          divider: true,
          align: 'start',
          width: '20%',
          sortable: true,
          value: 'lastname'
        },
        {
          text: "Prénom",
          divider: true,
          align: 'start',
          sortable: true,
          width: '20%',
          value: 'firstname'
        },
        {
          text: "Profil",
          divider: true,
          align: 'start',
          width: '20%',
          sortable: true,
          value: 'AgentProfile.name'
        },
        {
          text: "Entreprise",
          divider: true,
          align: 'start',
          width: '20%',
          sortable: true,
          value: 'Enterprise.name'
        },
        {
          text: "Date",
          divider: true,
          align: 'start',
          width: '150',
          sortable: true,
          value: 'date'
        }
      ];
    },
    selectedAccess() {
      return this.selected[0] || {};
    },
    selectedId() {
      return get(this.selectedAccess, 'id', null);
    }
  },
  methods: {
    addAccess() {
      this.$modals.open({
        name: 'ChangeSiteModal',
        payload: {
          profiles: this.profiles,
          enterprises: this.enterprises,
          submit: data => {
            this.$emit('add', data);
          }
        }
      });
    },
    removeAccess() {
      if (!this.selectedId) {
        return;
      }
      this.$modals.open({
        name: 'RemoveItem',
        payload: {
          text: {
            title: "Supprimer l'accès",
            subTitle: "Veux vraiment supprimer l'accès?"
          },
          submit: () => {
            this.$emit('remove', this.selectedId);
          }
        }
      });
    },
    editAccess() {
      if (!this.selectedId) {
        return;
      }

      this.$modals.open({
        name: 'ChangeSiteModal',
        payload: {
          oldValues: {
            firstname: this.selectedAccess.firstname,
            lastname: this.selectedAccess.lastname,
            profile: this.selectedAccess.profileName,
            enterprise: this.selectedAccess.enterpriseName
          },
          submit: data => {
            this.$emit('edit', { ...data, id: this.selectedId });
          }
        }
      });
    },
    exportData() {
      const agentsList = this.access.map(item => {
        const agent = [
          ["Nom", item.lastname],
          ["Prénom", item.firstname],
          ["Entreprise", item.Enterprise.name],
          ["Profil", item.AgentProfile.name],
          ["Badge", item.badge]
        ];
        return Object.fromEntries(agent);
      });

      try {
        json2excel({
          data: agentsList,
          name: 'agents-present-list'
        });
      } catch (e) {
        console.error('export error', e);
      }
    }
  }
};
</script>

<style lang="scss">
.sessions-control-icon {
  padding: 0 4px;
}

.access-table {
  .v-data-table-header__icon {
    display: none;
  }

  &--form {
    color: #0094ff !important;
    text-decoration: underline;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
