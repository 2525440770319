<template>
  <div>
    <v-data-table :headers="headers" :items="answers" height="500" fixed-header :items-per-page="-1" class="session-table-in-progress">
      <template v-if="session && session.state === 2" v-slot:item.QRCode="{ item }">
        <Qr class="agent-block--control-buttons-qr" @click.native="showQr(item)" />
      </template>

      <!-- (Ré)Associer un badge -->
      <template v-if="session && session.state === 2" v-slot:item.Badge="{ item }">
        <Badge class="agent-block--control-buttons-qr" :agent="agents.find(agt => agt.id === item.agentId)" :click="associateBadge" />
      </template>

      <!-- Liste des questiobns -->
      <template v-for="(question, index) in questions" v-slot:[`item.agentAnswer[${index}]`]="{ item }">

        <!-- Question libre pas encore répondue -->
        <div
          v-if="item.agentAnswer[index].result === null && (item.agentAnswer[index].type === 1 || item.agentAnswer[index].type === 2)"
          :key="index"
          class="free-question-todo"
          @click="setResultForFreeQuestion(index, item.agent, item.agentAnswer[index])"
        >
          <span>À corriger</span>
        </div>

        <!-- Question libre déjà répondue & validée -->
        <div
          v-if="item.agentAnswer[index].result !== null && (item.agentAnswer[index].type === 1 || item.agentAnswer[index].type === 2)"
          :key="index"
          class="free-question-done"
          @click="setResultForFreeQuestion(index, item.agent, item.agentAnswer[index])"
        >
          <span>Editer</span>
        </div>

        <!-- Question QCM -->
        <v-tooltip :key="index" :right="index < 5" :top="index > 4">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              :class="{ [getCellClasses(item.agentAnswer[index].result, item.agentAnswer[index].maxPoint)]: true }"
              v-on="on"
            >
              {{ item.agentAnswer[index].result }}
              <small v-if="hasSelectedAllAnswers(item.agentAnswer[index].answer, question)" class="all-answers">
                <v-icon dense color="red">mdi-information-outline</v-icon>
              </small>
            </div>
          </template>
          <span v-if="item.answer != ''">
            Réponse :
            {{ item.agentAnswer[index].type === 0 ? sortMultipleChoiceAnswer(item.agentAnswer[index].answer) : item.agentAnswer[index].answer }}
          </span>
          <span v-if="hasSelectedAllAnswers(item.agentAnswer[index].answer, question)" style="word-break: break-all; text-align: center;">
            <br />
            Le stagiaire a coché <br />toutes les réponses alors <br />que cela n'était pas attendu
          </span>
        </v-tooltip>

      </template>

      <!-- Note actuelle -->
      <template v-slot:item.result="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ getActualSuccessPercentage(item.agentAnswer) }}
            </span>
          </template>
          <span> Points : {{ getActualAgentPoint(item.agentAnswer) }} </span>
        </v-tooltip>
      </template>

      <!-- Q1 (0.00 %) -->
      <template v-for="(question, index) in questions" v-slot:[`header.agentAnswer[${index}]`]="{ header }">
        <v-tooltip :key="index" top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              :class="{
                current: isCurrentQuestion(index + 1),
                currentHeader: isCurrentQuestion(index + 1),
                currentTotal: isCurrentQuestion(index + 1) && getActualQuestionPercentageResponse(index) === 100
              }"
              v-on="on"
            >
              <span class="questionHeader">
                {{ header.text }} <!-- Q1 -->
                <span v-if="isCurrentQuestion(index + 1)">
                  <br>
                  {{ getActualQuestionPercentageResponse(index).toFixed(1) }}% <!-- 0.0% -->
                </span>
              </span>
            </div>
          </template>
          <span>
            Points maximum : {{ header.maxPoint }}
            <br />
            Bonnes réponses : {{ header.goodAnswers }}
          </span>
          <span v-if="header.badAnswers != ''">
            <br v-if="header.badAnswers != ''" />
            Mauvaises réponses : {{ header.badAnswers }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:header.result="{ header }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              {{ header.text }}
            </div>
          </template>
          <span> max : {{ header.maxPoint }} </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Qr from '../common/icons/Qr.vue';
import Badge from '../common/icons/Badge.vue';
import sessionAgents from '../../store/modules/sessionAgents';

export default {
  name: 'SessionsInProgress',
  components: { Badge, Qr },
  props: {
    answers: {
      type: Array,
      default: () => []
    },
    questions: {
      type: Array,
      default: () => []
    },
    currentPosition: {
      type: Number,
      default: 0
    },
    agents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      checkedInterval: null
    };
  },
  computed: {
    sessionAgents() {
      return sessionAgents;
    },
    ...mapGetters('sessions', ['session']),
    headers() {
      const questionsCount = this.questions.length;
      const tableHeader = [
        {
          text: "Stagiaire",
          divider: true,
          align: 'start',
          sortable: false,
          width: '150',
          value: 'agent'
        }
      ];
      if (this.session && this.session.state === 2) {
        tableHeader.push({
          text: "Rattacher l'appareil",
          divider: true,
          width: '50',
          align: 'start',
          sortable: false,
          value: 'QRCode'
        });
      }
      if (this.session && this.session.state === 2) {
        tableHeader.push({
          text: "(Ré)Associer un badge",
          divider: true,
          width: '50',
          align: 'start',
          sortable: false,
          value: 'Badge'
        });
      }
      tableHeader.push({
        text: "Note actuelle",
        divider: true,
        width: '50',
        align: 'start',
        sortable: false,
        value: 'result',
        maxPoint: this.totalMaxPoint
      });
      for (let i = 0; i < questionsCount; i += 1) {
        const tableItem = {
          text: `Q${i + 1}`,
          divider: true,
          align: 'start',
          width: '50',
          sortable: false,
          value: `agentAnswer[${i}]`,
          position: i + 1,
          maxPoint: this.questions[i].maxPoint,
          goodAnswers: this.questions[i].goodAnswers,
          badAnswers: this.questions[i].badAnswers
        };
        tableHeader.push(tableItem);
      }
      return tableHeader;
    },
    totalMaxPoint() {
      return this.questions.reduce((previous, item) => previous + item.maxPoint, 0);
    }
  },
  mounted() {
    this.checkedInterval = setInterval(() => this.$emit('updateAnswers'), 2500);
  },
  beforeDestroy() {
    clearInterval(this.checkedInterval);
  },
  methods: {
    ...mapActions('sessionAgents', ['setAgentBadge']),
    getActualAgentPoint(agentAnswer) {
      return agentAnswer.map(item => item.result).reduce((prev, next) => prev + next);
    },
    getActualSuccessPercentage(agentAnswer) {
      const correctAnswersCount = agentAnswer.map(item => item.result).reduce((prev, next) => prev + next);
      const correctAnswersMaxCount = agentAnswer.map(item => item.maxPoint).reduce((prev, next) => prev + next);
      let percentage = 0;
      if (correctAnswersMaxCount !== 0) {
        percentage = (correctAnswersCount / correctAnswersMaxCount) * 100;
        percentage = (percentage / 5).toFixed(1);
      }
      return `${percentage}/20`;
    },
    setResultForFreeQuestion(index, agentName, item) {
      const question = { ...item.freeQuestion, answer: item.answer, maxPoint: item.maxPoint };
      this.$modals.open({
        name: 'FreeQuestionModal',
        payload: {
          agentName,
          questionNumber: index,
          question,
          submit: score => {
            // SessionDetails.vue > setUserScore()
            this.$emit('setUserScore', {
              sessionAgentId: item.sessionAgentId,
              questionId: item.formQuestionId,
              result: score
            });
          }
        }
      });
    },
    getCellClasses(result, maxPoint) {
      if (result === maxPoint) {
        return 'session-table-in-progress--correct';
      }
      if (result < 0) {
        return 'session-table-in-progress--incorrect';
      }
      return 'session-table-in-progress--partially-correct';
    },
    isCurrentQuestion(position) {
      return position === this.currentPosition;
    },
    getActualQuestionPercentageResponse(position) {
      return (this.answers.filter(item => typeof item.agentAnswer[position].type !== 'undefined').length * 100) / this.answers.length;
    },
    sortMultipleChoiceAnswer(answer) {
      return answer
        .split(',')
        .sort()
        .join(',');
    },
    showQr(agentSession) {
      this.$modals.open({
        name: 'QrModal',
        payload: {
          agentName: agentSession.agent,
          agentSessionId: agentSession.agentId,
          waitForQr: () => {
            this.$emit('waitForQr', agentSession.agentId);
          }
        }
      });
    },
    associateBadge(agent) {
      this.$modals.open({
        name: 'BadgeModal',
        payload: {
          agentName: agent.lastname + agent.firstname,
          agentBadge: agent.agentBadge, // badge by default
          submit: badge => {
            this.setAgentBadge({ id: agent.id, data: badge });
          }
        }
      });
    },
    hasSelectedAllAnswers(answerString, question) {
      if (question?.goodAnswers) {
        const goodAnswerArray = question?.goodAnswers.split(',');
        // Si question de type QCM && toutes les réponses ne sont pas valides
        if (question.type === 0 && question.answerNumbers !== goodAnswerArray.length && answerString) {
          const answerArray = answerString.split(',');
          // Si le nombre de réponses égal le nombre de choix possible
          if (answerArray && answerArray.length === question.answerNumbers) {
            return true;
          }
        }
      }

      return false;
    }
  }
};
</script>

<style lang="scss">
.session-table-in-progress {
  &--incorrect {
    color: #ea9999;
  }

  &--correct {
    color: #b6d7a8;
  }

  &--partially-correct {
    color: #f9cb9c;
  }

  &--incorrect,
  &--correct,
  &--partially-correct {
    font-weight: 900;
    font-size: 25px;
  }

  margin: 20px 0 0 0 !important;

  .theme--light.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate) .v-icon {
    color: #3dd598 !important;
  }

  .free-question-todo {
    color: #0094ff;
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
  }
  .free-question-done {
    color: #a003abdc;
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
  }

  td div,
  th div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .current {
    text-align: center;
    margin: 0 -16px;
    height: 100%;
    background-color: #ffc400;
  }
  .currentTotal {
    text-align: center;
    background-color: #09ff00;
  }

  // CSS TO Fixed two first columns - BEGIN
  tbody > tr > td:nth-child(1),
  thead > tr > th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0px;
    background: white;
  }

  tbody > tr > td:nth-child(2),
  thead > tr > th:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 150px;
    background: white;
  }
  tbody > tr > td:nth-child(3),
  thead > tr > th:nth-child(3) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 235px;
    background: white;
  }

  thead > tr > th:nth-child(1),
  tbody > tr > td:nth-child(1),
  thead > tr > th:nth-child(2),
  tbody > tr > td:nth-child(2),
  thead > tr > th:nth-child(3),
  tbody > tr > td:nth-child(3) {
    z-index: 200 !important;
  }
  // CSS TO Fixed two first columns - END
}

.all-answers {
  font-size: 0.7em;
  line-height: 0.7em;
  margin: -10px -10px 10px 0px;
  color: #e16565;
}
</style>
