import axios from 'axios';
import moment from 'moment';

import { API_BASE_URL } from '@/config';
import { responseHandler } from '@/services/interceptors/response';
import { requestHandler } from '@/services/interceptors/request';
import { errorHandler } from '@/services/interceptors/error';

let overrideBaseUrl = null;

/**
 * Renvoie l'url de base de l'API
 * @returns string||boolean
 */
const getBaseUrl = async () => {
  // si on a pas d'info sur l'overide url, on le récupère
  if (overrideBaseUrl === null) {
    overrideBaseUrl = await axios
      .get(`/config.json?ts=${moment().format('YYYYMMDDHHmmss')}`)
      .then(response => {
        const { data: { apiUrl } = data } = response;
        if (typeof apiUrl !== 'undefined' && apiUrl !== '') {
          return apiUrl;
        } else {
          return false;
        }
      })
      .catch(() => false);
  }

  // si l'overide base url est différent de false,
  // c'est qu'il est override donc on le renvoie
  if (overrideBaseUrl !== false) {
    return overrideBaseUrl;
  }
  // sinon, on renvoie celui par defaut
  else {
    return API_BASE_URL;
  }
};

/**
 * Renvoie l'objet request pour les appels API
 * @returns Axios
 */
const getInstance = async () => {
  const request = axios.create({
    baseURL: await getBaseUrl()
  });

  request.interceptors.response.use(responseHandler, errorHandler);
  request.interceptors.request.use(requestHandler, errorHandler);

  return request;
};

export default { getInstance };
