<template>
  <div class="pageContainer pa-6 ma-2">
    <div id="controlsContainer" class="d-flex flex-row justify-center mb-2">
      <v-text-field
        v-model="acceptScore"
        :rules="[val => val <= 20 && val >= interviewScore]"
        type="number"
        step="0.5"
        label="Note d'acceptation"
        class="flex-grow-0 flex-shrink-1 px-4"
      ></v-text-field>
      <v-text-field
        v-model="interviewScore"
        :rules="[val => val <= acceptScore && val >= 0]"
        type="number"
        step="0.5"
        label="Note d'entretien"
        class="flex-grow-0 flex-shrink-1 px-4"
      ></v-text-field>
    </div>
    <div v-show="!loading">
      <div id="curveGraphContainer">
        <canvas id="curveGraph" ref="curveGraph"></canvas>
      </div>
    </div>
    <div v-show="loading">
      <div class="d-flex justify-center ">
        <v-progress-circular :indeterminate="true" class="mx-auto"></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, BarController, LineController, Title, Tooltip, Legend, PointElement, LineElement, BarElement, CategoryScale, LinearScale } from 'chart.js';

import { getCandidates } from '../../../services/statsFormations.service';

Chart.register(Title, PointElement, LineElement, BarController, LineController, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  props: {
    generalParams: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      chart: null,
      labels: [],
      acceptScore: 16,
      interviewScore: 13,
      datasets: [
        {
          type: 'line',
          label: 'Note',
          borderColor: '#4874c5',
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          data: [],
          options: {
            layout: {
              padding: 20
            },
            scales: {
              y: {
                min: 0,
                max: 20
              }
            }
          }
        },
        {
          type: 'line',
          label: 'Note acceptée',
          borderColor: '#ea6f1a',
          data: new Array(101).fill(16),
          pointRadius: 0,
          pointHoverRadius: 0,
          options: {
            layout: {
              padding: 20
            },
            plugins: {
              legend: {
                display: false
              }
            },
            scales: {
              y: {
                min: 0,
                max: 20
              }
            }
          }
        },
        {
          type: 'line',
          label: 'Note entretien',
          borderColor: '#9c9d9d',
          data: new Array(101).fill(13),
          pointRadius: 0,
          pointHoverRadius: 0,
          options: {
            layout: {
              padding: 20
            },
            scales: {
              y: {
                min: 0,
                max: 20
              }
            }
          }
        }
      ]
    };
  },
  computed: {
    acceptScoreArrayed() {
      return new Array(this.datasets[0].data.length).fill(this.acceptScore);
    },
    interviewScoreArrayed() {
      return new Array(this.datasets[0].data.length).fill(this.interviewScore);
    }
  },
  watch: {
    generalParams(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== '') {
        this.fetchAnswersStats();
      }
    },
    acceptScoreArrayed() {
      this.datasets[1].data = this.acceptScoreArrayed;
    },
    interviewScoreArrayed() {
      this.datasets[2].data = this.interviewScoreArrayed;
    },
    datasets: {
      immediate: true,
      deep: true,
      handler() {
        this.updateChart();
      }
    }
  },
  mounted() {
    this.fetchAnswersStats();
    // this.updateChart();
  },
  methods: {
    async fetchAnswersStats() {
      this.loading = true;
      const candidats = [{ finalScore: null }, ...(await getCandidates(this.generalParams))];

      this.acceptScore = candidats[1] ? candidats[1].Session.minValid : this.acceptScore;
      this.interviewScore = candidats[1] ? candidats[1].Session.minInterview : this.interviewScore;

      this.datasets[0].data = candidats.map(candidat => candidat.finalScore);
      this.labels = candidats.map((candidat, index) => `${((index / (candidats.length - 1)) * 100).toFixed(1)}%`);
      this.loading = false;
    },

    updateChart() {
      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(this.$refs.curveGraph, {
        data: {
          datasets: this.datasets,
          labels: this.labels
        },
        options: {
          scales: {
            y: {
              min: 0,
              max: 20
            }
          }
        }
      });
    }
  }
};
</script>

<style>
#curveGraph {
  max-height: 50vh;
  height: 100%;
}
</style>
