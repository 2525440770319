/**
 * Check error field inside successful response and reject them
 *
 * @param {Object} response
 * @returns {Object}
 */
export function responseHandler(response) {
  if (response.data.errors?.length) {
    return Promise.reject(response.data.errors);
  }
  return response.data;
}
