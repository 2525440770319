import * as types from './mutation-types';

export default {
  [types.SET_QUESTIONS_THEMES](state, data) {
    state.questionsThemes = data;
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  }
};
