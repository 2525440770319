var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"session-table-in-progress",attrs:{"headers":_vm.headers,"items":_vm.answers,"height":"500","fixed-header":"","items-per-page":-1},scopedSlots:_vm._u([(_vm.session && _vm.session.state === 2)?{key:"item.QRCode",fn:function(ref){
var item = ref.item;
return [_c('Qr',{staticClass:"agent-block--control-buttons-qr",nativeOn:{"click":function($event){return _vm.showQr(item)}}})]}}:null,(_vm.session && _vm.session.state === 2)?{key:"item.Badge",fn:function(ref){
var item = ref.item;
return [_c('Badge',{staticClass:"agent-block--control-buttons-qr",attrs:{"agent":_vm.agents.find(function (agt) { return agt.id === item.agentId; }),"click":_vm.associateBadge}})]}}:null,_vm._l((_vm.questions),function(question,index){return {key:("item.agentAnswer[" + index + "]"),fn:function(ref){
var item = ref.item;
return [(item.agentAnswer[index].result === null && (item.agentAnswer[index].type === 1 || item.agentAnswer[index].type === 2))?_c('div',{key:index,staticClass:"free-question-todo",on:{"click":function($event){return _vm.setResultForFreeQuestion(index, item.agent, item.agentAnswer[index])}}},[_c('span',[_vm._v("À corriger")])]):_vm._e(),(item.agentAnswer[index].result !== null && (item.agentAnswer[index].type === 1 || item.agentAnswer[index].type === 2))?_c('div',{key:index,staticClass:"free-question-done",on:{"click":function($event){return _vm.setResultForFreeQuestion(index, item.agent, item.agentAnswer[index])}}},[_c('span',[_vm._v("Editer")])]):_vm._e(),_c('v-tooltip',{key:index,attrs:{"right":index < 5,"top":index > 4},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var _obj;

var on = ref.on;
var attrs = ref.attrs;return [_c('div',_vm._g(_vm._b({class:( _obj = {}, _obj[_vm.getCellClasses(item.agentAnswer[index].result, item.agentAnswer[index].maxPoint)] = true, _obj )},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.agentAnswer[index].result)+" "),(_vm.hasSelectedAllAnswers(item.agentAnswer[index].answer, question))?_c('small',{staticClass:"all-answers"},[_c('v-icon',{attrs:{"dense":"","color":"red"}},[_vm._v("mdi-information-outline")])],1):_vm._e()])]}}],null,true)},[(item.answer != '')?_c('span',[_vm._v(" Réponse : "+_vm._s(item.agentAnswer[index].type === 0 ? _vm.sortMultipleChoiceAnswer(item.agentAnswer[index].answer) : item.agentAnswer[index].answer)+" ")]):_vm._e(),(_vm.hasSelectedAllAnswers(item.agentAnswer[index].answer, question))?_c('span',{staticStyle:{"word-break":"break-all","text-align":"center"}},[_c('br'),_vm._v(" Le stagiaire a coché "),_c('br'),_vm._v("toutes les réponses alors "),_c('br'),_vm._v("que cela n'était pas attendu ")]):_vm._e()])]}}}),{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getActualSuccessPercentage(item.agentAnswer))+" ")])]}}],null,true)},[_c('span',[_vm._v(" Points : "+_vm._s(_vm.getActualAgentPoint(item.agentAnswer))+" ")])])]}},_vm._l((_vm.questions),function(question,index){return {key:("header.agentAnswer[" + index + "]"),fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:{
              current: _vm.isCurrentQuestion(index + 1),
              currentHeader: _vm.isCurrentQuestion(index + 1),
              currentTotal: _vm.isCurrentQuestion(index + 1) && _vm.getActualQuestionPercentageResponse(index) === 100
            }},'div',attrs,false),on),[_c('span',{staticClass:"questionHeader"},[_vm._v(" "+_vm._s(header.text)+" "),(_vm.isCurrentQuestion(index + 1))?_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm.getActualQuestionPercentageResponse(index).toFixed(1))+"% ")]):_vm._e()])])]}}],null,true)},[_c('span',[_vm._v(" Points maximum : "+_vm._s(header.maxPoint)+" "),_c('br'),_vm._v(" Bonnes réponses : "+_vm._s(header.goodAnswers)+" ")]),(header.badAnswers != '')?_c('span',[(header.badAnswers != '')?_c('br'):_vm._e(),_vm._v(" Mauvaises réponses : "+_vm._s(header.badAnswers)+" ")]):_vm._e()])]}}}),{key:"header.result",fn:function(ref){
            var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(header.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(" max : "+_vm._s(header.maxPoint)+" ")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }