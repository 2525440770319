import * as types from './mutation-types';

import { getAllAgents, getBySessionId, getDeviceTokenById, getAgentAnswers, setBadge, addComment, saveFinalScore } from '@/services/sessionAgents.service';

export default {

  /**
   * Retourne :
   * - SessionAgent
   *   - Agent
   *     - AgentProfile
   *     - Entreprise
   *   - Session
   *     - Form (dupliqué)
   * - nom, prénom, nom entreprise, numéro badge, nom du formulaire
   * - AgentProfile (dupliqué)
   */
  async fetchAgents({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getAllAgents();
      commit(types.SET_ALL_AGENTS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async fetchSessionAgents({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getBySessionId(id);
      commit(types.SET_AGENTS, data);
      commit(types.SET_CURRENT_SESSION, id);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getDeviceToken({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getDeviceTokenById(id);
      commit(types.SET_DEVICE_TOKEN, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getAnswers({ commit }, id) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getAgentAnswers(id);
      commit(types.SET_AGENT_ANSWERS, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async setAgentBadge({ commit, dispatch, getters }, { id, data }) {
    commit(types.SET_ERRORS, null);
    try {
      await setBadge(id, { badge: data });
      dispatch('fetchSessionAgents', getters.sessionId);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async setAgentComment({ commit }, { id, comment }) {
    commit(types.SET_ERRORS, null);
    try {
      await addComment(id, { comment });
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async saveAgentFinalScore({ commit }, { id, finalScore, result }) {
    commit(types.SET_ERRORS, null);
    try {
      await saveFinalScore(id, { finalScore, result });
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
