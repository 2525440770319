<template>
  <div class="sessions-validation">
    <div class="sessions-validation-header--sub-list d-flex justify-end justify-space-between">
      <div class="sessions-validation-header--sub-list-tabs d-flex justify-end align-end">
        <div class="sessions-validation-header--sub-list-result" :class="{ active: isValidateTab }" @click="changeTab">
          Résultat des participants
        </div>
        <div class="sessions-validation-header--sub-list-answer" :class="{ active: isResultTab }" @click="changeTab">
          Réponses aux questions
        </div>
      </div>

      
      <div class="d-flex justify-end align-start">
        
        <!-- Nb stagiaires -->
        <div class="sessions-validation-header--sub-list-status all d-flex justify-start align-center">
          <div class="sessions-validation-header--sub-list-status-item">
            <div class="sessions-validation-header--sub-list-status-item-title">
              Stagiaires
            </div>
            <div class="sessions-validation-header--sub-list-status-item-value">
              {{ agents.length }}
            </div>
          </div>
        </div>

        <!-- Nb Obtention -->
        <div class="sessions-validation-header--sub-list-status validate d-flex justify-start align-center">
          <div class="sessions-validation-header--sub-list-status-item">
            <div class="sessions-validation-header--sub-list-status-item-title">
              Obtentions
            </div>
            <div class="sessions-validation-header--sub-list-status-item-value">
              {{ countOfAgents(2) }}
            </div>
          </div>
        </div>

        <!-- Nb Entretiens -->
        <div class="sessions-validation-header--sub-list-status interview d-flex justify-start align-center">
          <div class="sessions-validation-header--sub-list-status-item">
            <div class="sessions-validation-header--sub-list-status-item-title">
              Entretiens
            </div>
            <div class="sessions-validation-header--sub-list-status-item-value">
              {{ countOfAgents(1) }}
            </div>
          </div>
        </div>
        
        <!-- Nb refus -->
        <div class="sessions-validation-header--sub-list-status denied d-flex justify-start align-center">
          <div class="sessions-validation-header--sub-list-status-item">
            <div class="sessions-validation-header--sub-list-status-item-title">
              Refus
            </div>
            <div class="sessions-validation-header--sub-list-status-item-value">
              {{ countOfAgents(0) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <SessionsInProgress v-if="isResultTab" :answers="answers" :questions="questions" />
    <v-row v-if="isValidateTab" no-gutters class="sessions-validation--block">
      <v-col v-for="(agent, index) in agents" :key="index" class="sessions-validation--test" cols="3" sm="3" md="3" lg="3" xl="3">
        <div class="sessions-validation--item">
          <ValidationCard
            :agent="agent"
            :final-score-status="getResultStatus(agent.finalScore)"
            :result="agent.result"
            :session-state="step"
            :agent-comment="getComment(agent.id)"
            @addComment="addComment"
            @changeValidationStatus="changeValidationStatus"
          />
        </div>
      </v-col>
    </v-row>
    <div v-if="step === 2" class="sessions-validation--validate d-flex justify-end">
      <v-btn class="sessions-validation--validate-btn" color="#38bb00" width="204px" height="37px" @click.prevent="validateResult">
        Valider les résultats
      </v-btn>
    </div>
  </div>
</template>

<script>
import { get, findIndex } from 'lodash';

import ValidationCard from '@/components/sessions/ValidationCard';
import SessionsInProgress from '@/components/sessions/SessionsInProgress';

export default {
  name: 'SessionValidation',
  components: {
    ValidationCard,
    SessionsInProgress
  },
  props: {
    session: {
      type: Object,
      default: () => []
    },
    agents: {
      type: Array,
      default: () => []
    },
    sessionId: {
      type: String
    },
    step: {
      type: Number,
      default: 3
    },
    answers: {
      type: Array,
      default: () => []
    },
    questions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      validationResult: [],
      resultStatus: ['validate', 'interview', 'denied'],
      isValidateTab: true,
      isResultTab: false
    };
  },
  computed: {
    headers() {
      const questionsCount = this.questions.length;
      const colWidth = 55 / questionsCount;

      const tableHeader = [
        {
          text: "Stagiaire",
          divider: true,
          align: 'start',
          sortable: true,
          width: '20%',
          value: 'agent'
        }
      ];

      // 1 colonne par question
      for (let i = 0; i < questionsCount; i += 1) {
        tableHeader.push({
          text: `Q${i + 1}`,
          divider: true,
          align: 'start',
          width: `${colWidth}%`, // Toutes les width en % ne fonctionnent pas
          sortable: true,
          value: `agentAnswer[${i}]`
        });
      }

      tableHeader.push({
        text: "Note actuelle",
        divider: true,
        width: '15%',
        align: 'start',
        sortable: true,
        value: 'result'
      });

      return tableHeader;
    },
    sessionState() {
      return this.session.state;
    }
  },
  watch: {
    agents() {
      this.setValidationResult();
    }
  },
  mounted() {
    this.setValidationResult();
  },
  methods: {
    getResultStatus(finalScore) {
      const { minInterview, minValid } = this.session;

      let status = 0; // refus

      if (finalScore >= minInterview && finalScore < minValid) {
        status = 1; // entretien
      }

      if (finalScore >= minValid) {
        status = 2; // valide
      }

      return status;
    },

    countOfAgents(status) {
      let count = 0;

      if (status === 0 && this.session.nbFail) {
        return this.session.nbFail;
      }

      if (status === 1 && this.session.nbInterview) {
        return this.session.nbInterview;
      }

      if (status === 2 && this.session.nbSuccess) {
        return this.session.nbSuccess;
      }

      this.agents.forEach(item => {
        if (item.result !== null) {
          if (item.result === status) {
            count += 1;
          }
        } else if (this.getResultStatus(item.finalScore) === status) {
          count += 1;
        }
      });

      return count;
    },

    getComment(id) {
      const index = findIndex(this.validationResult, { id });
      return get(this.validationResult[index], 'comment', null);
    },
    addComment({ id, comment }) {
      const index = findIndex(this.validationResult, { id });
      this.validationResult[index].comment = comment;
    },

    changeValidationStatus({ id, validation }) {
      const index = findIndex(this.validationResult, { id });
      this.validationResult[index].validation = validation;
    },

    /**
     * Déclenché en cliquant sur "Valider les résultats"
     * Envoie les résultats & commentaires de tous les agents de la session
     * Clôture la session et donne les accès à tous agents ayant réussi le questionnaire
     */
    validateResult() {
      const data = {
        state: 4,
        nbSuccess: 0,
        nbInterview: 0,
        nbFail: 0,
        resultsAndComments: []
      }

      // Ajout de la note et du commentaire de chaque agent à la liste des données à envoyer
      this.validationResult.forEach(result => {
        const tmp = {
          sessionAgentId: result.id,
          result: result.validation,
          comment: result.comment
        };
        data.resultsAndComments.push(tmp);

        // Comptage des différents cas
        if (result.validation === 0) {
          data.nbFail += 1;
        }
        if (result.validation === 1) {
          data.nbInterview += 1;
        }
        if (result.validation === 2) {
          data.nbSuccess += 1;
        }
      });

      // SessionDetails.vue > validateSession()
      this.$emit('finish', data);
    },

    changeTab() {
      this.isValidateTab = !this.isValidateTab;
      this.isResultTab = !this.isResultTab;
    },

    setValidationResult() {
      this.validationResult = [];
      this.agents.forEach(agent => {
        this.validationResult.push({
          validation: this.getResultStatus(agent.finalScore),
          id: agent.id,
          result: agent.result,
          comment: ''
        });
      });
    }
  }
};
</script>

<style lang="scss">
.sessions-validation {
  margin: 20px 0 20px 0 !important;

  &-header--sub-list {
    &-tabs {
      div:not(.active) {
        background-color: #f2f2f2;
        color: #3f3f44;
      }
      cursor: pointer;
    }

    &-result,
    &-answer {
      border-radius: 4px 0px 0px 0px;
      border: 1px solid #e4e6e8;
      max-height: 45px;
      padding: 14px 24px;
      font-size: 12px;
      font-weight: 700;
    }

    &-status {
      min-width: 120px;
      max-width: 220px;
      height: 70px;
      margin-bottom: 8px;

      border: 1px solid #e4e6e8;
      box-sizing: border-box;
      border-radius: 4px;

      &-item {
        width: 100%;
        text-align: center;
      }
    }

    .validate {
      background-color: #b6d7a8;
    }

    .interview {
      background-color: #f9cb9c;
    }

    .denied {
      background-color: #ea9999;
    }

    .all,
    .validate,
    .interview {
      margin-right: 10px;
    }
  }

  // Card container
  &--test {
    min-height: 180px !important;
  }

  // Card
  &--item {
    width: 98%;
    height: 98%;
    border-radius: 4px;
    min-height: 160px !important;
  }

  // Card grid
  &--block { 
    border-radius: 4px 0px 0px 0px;
    border: 1px solid #e4e6e8;
    padding: 0px 10px 20px 10px;
  }

  &--validate {
    margin-top: 20px;
    &-btn {
      color: #ffffff !important;
      border-radius: 10px;
      font-size: 12px !important;
      text-transform: none !important;
    }
  }
}
</style>
