import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import { VUETIFY_CUSTOM_THEME_COLORS } from '@/common/constants';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: VUETIFY_CUSTOM_THEME_COLORS,
      dark: VUETIFY_CUSTOM_THEME_COLORS
    }
  }
});
