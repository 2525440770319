import { uid } from 'uid';

import TheModals from './components/TheModals';
import modalsStoreModule from './store';

export default {
  install(Vue, { store, components = {} } = {}) {
    if (!store) {
      throw new Error('Missing store option in modals plugin!');
    }

    Vue.component('TheModals', {
      components,
      extends: TheModals
    });

    store.registerModule('modals', modalsStoreModule);

    Vue.prototype.$modals = {
      getAll() {
        return store.getters['modals/modals'];
      },
      get(id) {
        return store.getters['modals/getModal'](id);
      },
      getPayload(id) {
        return store.getters['modals/getModalPayload'](id);
      },
      open({ name, payload, id = uid(32) } = {}) {
        store.dispatch('modals/addModal', { name, payload, id });
      },
      hide(id) {
        store.dispatch('modals/removeModal', id);
      },
      hideAll() {
        store.dispatch('modals/removeAllModals');
      }
    };
  }
};
