import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

import { ROUTES, ROUTES_NAME } from '@/common/constants';
import Login from '@/views/login/Login';
import Agents from '@/views/agents/Agents';
import Interviews from '@/views/interviews/Interviews';
import Sessions from '@/views/sessions/Sessions';
import Settings from '@/views/settings/Settings';
import Accounts from '@/views/accounts/Accounts';
import Logs from '@/views/logs/Logs';
import QuestionsThemes from '@/views/questionsThemes/QuestionsThemes';
import Enterprise from '@/views/enterprise/Enterprise';
import Profession from '@/views/profession/Profession';
import Profile from '@/views/profile/Profile';
import FormManagement from '@/views/formManagement/FormManagement';
import AgentsDetails from '@/views/agents-details/AgentsDetails';
import SessionDetails from '@/views/sessionDetails/SessionDetails';
import SectionDetails from '@/views/section-details/SectionDetails';
import Sections from '@/views/sections/Sections'; // Devient -> Sites
import Statistics from '@/views/statistics/Statistics';
import StatisticFormations from '@/views/statistics/StatisticFormations';
import Roles from '@/views/roles/Roles';
import BanList from '@/views/banIP/BanIP';

Vue.use(VueRouter);

const routes = [
  {
    name: ROUTES_NAME.login,
    path: ROUTES.login,
    component: Login,
    meta: {
      title: 'Connexion'
    }
  },
  {
    name: ROUTES_NAME.agents,
    path: ROUTES.agents,
    component: Agents,
    meta: {
      title: 'Gestion des agents'
    }
  },
  {
    name: ROUTES_NAME.interviews,
    path: ROUTES.interviews,
    component: Interviews,
    meta: {
      title: 'Entretiens'
    }
  },
  {
    name: ROUTES_NAME.statistics,
    path: ROUTES.statistics,
    component: Statistics,
    meta: {
      title: 'Statistiques Chantier'
    }
  },
  {
    name: 'StatisticFormation',
    path: ROUTES.statisticsFormation,
    component: StatisticFormations,
    meta: {
      title: 'Statistiques Formation'
    }
  },
  {
    name: ROUTES_NAME.sessions,
    path: ROUTES.sessions,
    component: Sessions,
    meta: {
      title: 'Gestion des sessions'
    }
  },
  {
    name: ROUTES_NAME.settings,
    path: ROUTES.settings,
    component: Settings,
    meta: {
      title: 'Paramétrages'
    }
  },
  {
    name: ROUTES_NAME.accounts,
    path: ROUTES.accounts,
    component: Accounts,
    meta: {
      title: 'Gestion des comptes'
    }
  },
  {
    name: ROUTES_NAME.logs,
    path: ROUTES.logs,
    component: Logs,
    meta: {
      title: 'Suivis des logs'
    }
  },
  {
    name: ROUTES_NAME.questionsThemes,
    path: ROUTES.questionsThemes,
    component: QuestionsThemes,
    meta: {
      title: 'Paramétrage des thèmes'
    }
  },
  {
    name: ROUTES_NAME.enterprise,
    path: ROUTES.enterprise,
    component: Enterprise,
    meta: {
      title: 'Gestion des entreprises'
    }
  },
  {
    name: ROUTES_NAME.profession,
    path: ROUTES.profession,
    component: Profession,
    meta: {
      title: 'Gestion des corps de métier'
    }
  },
  {
    name: ROUTES_NAME.profile,
    path: ROUTES.profile,
    component: Profile,
    meta: {
      title: 'Gestion des profils'
    }
  },
  {
    name: ROUTES_NAME.formManagement,
    path: ROUTES.formManagement,
    component: FormManagement,
    meta: {
      title: 'Gestion des questionnaires'
    }
  },
  {
    name: ROUTES_NAME.sectionDetails,
    path: ROUTES.sectionDetails,
    component: SectionDetails,
    meta: {
      title: 'Détail de la zone'
    }
  },
  /* TODO a supprimer si OK
  {
    name: ROUTES_NAME.sections,
    path: ROUTES.sections,
    component: Sections,
    meta: {
      title: 'Gestion des sites'
    }
  },
    */
  {
    name: ROUTES_NAME.agentsDetails,
    path: ROUTES.agentsDetails,
    component: AgentsDetails,
    props: ({ query }) => ({ ...query }),
    meta: {
      title: "Detail de l'agent"
    }
  },
  {
    name: ROUTES_NAME.sessionDetails,
    path: ROUTES.sessionDetails,
    component: SessionDetails,
    props: ({ query }) => ({ ...query }),
    meta: {
      title: 'Detail de la session'
    }
  },
  {
    name: ROUTES_NAME.roles,
    path: ROUTES.roles,
    component: Roles,
    props: ({ query }) => ({ ...query }),
    meta: {
      title: 'Paramétrage des roles'
    }
  },
  {
    name: ROUTES_NAME.sites,
    path: ROUTES.sites,
    component: Sections,
    meta: {
      title: 'Paramétrage des zones'
    }
  },
  {
    name: ROUTES_NAME.banList,
    path: ROUTES.banIPList,
    component: BanList,
    meta: {
      title: 'Liste des adresses IP bannies'
    }
  },
  {
    path: '*',
    redirect: {
      path: ROUTES.sectionDetails
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

function setPageTitle(page) {
  document.title = `${store.getters['ui/config'].APP_TITLE} - ${page}`;
}

router.afterEach(to => {
  Vue.nextTick(() => {
    // Nom de la page
    const page = to.meta.title ? to.meta.title : 'Accueil';

    // on vérifie que l'on a la config, sinon, on attend qu'elle charge
    if (store.getters['ui/config'] === null) {
      store.watch(
        () => store.getters['ui/config'],
        () => {
          setPageTitle(page);
        }
      );
      return;
    }

    // si on a la config, on set la page
    setPageTitle(page);
  });
});

export default router;
