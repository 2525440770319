<template>
  <div class="enterprise-page">
    <EnterpriseTable v-if="list.length" :data="list" :text="text" @add="addItem($event)" @edit="changeItemById($event)" @remove="removeItem($event)" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { ROUTES_NAME } from '@/common/constants';
import EnterpriseTable from '@/components/common/tables/EnterpriseTable';

export default {
  name: ROUTES_NAME.enterprise,
  components: {
    EnterpriseTable
  },
  data() {
    return {
      text: {
        table: 'enterprise',
        add: {
          title: "Ajout d'entreprise"
        },
        edit: {
          title: "Modification"
        },
        remove: {
          title: "Suppression",
          subTitle: "Êtes-vous sûr de vouloir supprimer cette entreprise ?"
        }
      }
    };
  },
  computed: {
    ...mapGetters('enterprise', ['list'])
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    ...mapActions('enterprise', ['fetchList', 'removeItem', 'addItem', 'changeItemById'])
  }
};
</script>

<style lang="scss">
.enterprise-page {
  padding: 0 10px;
}
</style>
