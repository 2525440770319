<template>
  <div class="agents-details-page">
    <div class="agents-details-page--header d-flex justify-space-between align-center">
      <div>
        <div class="agents-details-page--header-name">
          {{ agentName }}
        </div>
        <div class="agents-details-page--header-enterpris">{{ enterpriseName }} - {{ profileName }}</div>
      </div>
      <div>
        <v-card :color="this.currentAccess ? 'success' : 'error'" class="float-right">
          <v-card-title class="justify-center"> Accès actuel</v-card-title>
          <v-card-text class="text-center">
            {{ currentAccess ? formatTime(currentAccess.endDate) : 'Accès non valide' }}
          </v-card-text>
        </v-card>
        <v-card :color="this.isAgentOnSite ? 'success' : 'error'" class="float-right mr-5">
          <v-card-title class="justify-center"> Zone actuelle</v-card-title>
          <v-card-text class="text-center">
            {{ this.agentCurrentSite }}
          </v-card-text>
        </v-card>
      </div>
    </div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab href="#access">Accès</v-tab>
        <v-tab href="#zones">Zones autorisées</v-tab>
        <v-tab href="#quiz">Questionnaire sécurité</v-tab>
        <v-tab href="#interviews">Entretiens</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="access">
          <AgentDetailsAccess
            :access="agentAccess"
            @add="newAccess({ id: agentId, data: $event })"
            @edit="changeAgentAccess($event)"
            @remove="removeAgentAccess($event)"
          />
        </v-tab-item>
        <v-tab-item value="zones">
          <AgentDetailsSections :sections="allowedSites" />
        </v-tab-item>
        <v-tab-item value="quiz">
          <AgentDetailsSession :sessions="sessions" :answers="answers" @show-details="showSessionDetails($event)" />
        </v-tab-item>
        <v-tab-item value="interviews">
          <AgentDetailsInterview :interviews="interviews" :answers="answers" @show-details="showSessionDetails($event)" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { get, filter, find } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import { formatValues } from '@/mixins/formatValues.mixin';
import { ROUTES_NAME } from '@/common/constants';
import AgentDetailsAccess from '@/components/common/tables/AgentDetailsAccess';
import AgentDetailsSections from '@/components/common/tables/AgentDetailsSections';
import AgentDetailsSession from '@/components/common/tables/AgentDetailsSession';
import AgentDetailsInterview from '@/components/common/tables/AgentDetailsInterview';

export default {
  name: ROUTES_NAME.agentsDetails,
  components: {
    AgentDetailsAccess,
    AgentDetailsSections,
    AgentDetailsSession,
    AgentDetailsInterview
  },
  mixins: [formatValues],
  data() {
    return {
      isShowSessions: false,
      currentAgentId: null,
      tab: null
    };
  },
  computed: {
    ...mapGetters('sessionAgents', ['allAgents', 'answers']),
    ...mapGetters('interviewer', ['interviewer']),
    ...mapGetters('agentAccess', ['agentAccess']),
    ...mapGetters('site', ['sites', 'allowedSites', 'mouvementsList']),
    ...mapGetters('agents', ['agent', 'enterprises', 'profiles']),
    agentId() {
      return this.currentAgentId;
    },
    agentName() {
      return `${get(this.agent, 'firstname', '')} ${get(this.agent, 'lastname', '')}`;
    },
    enterpriseName() {
      const enterprise = this.enterprises.find(item => item.id === this.agent.enterpriseId);
      if (!enterprise) {
        return '';
      }

      return enterprise.name;
    },
    isAgentOnSite() {
      if (this.agent === null) {
        return false;
      }
      return this.agent.Site !== null;
    },
    agentCurrentSite() {
      if (!this.isAgentOnSite) {
        return 'Non présent sur site';
      }

      return this.agent.Site.name;
    },
    profileName() {
      const profile = this.profiles.find(item => item.id === this.agent.agentProfileId);
      if (!profile) {
        return '';
      }

      return profile.name;
    },
    sessions() {
      return filter(this.allAgents, { agentId: this.agentId });
    },
    interviews() {
      return filter(this.interviewer, { agentId: this.agentId });
    },
    currentAccess() {
      return this.agentAccess.filter(a => moment().isBetween(a.beginDate, a.endDate))[0];
    },
    currentStatus() {
      const agentMouvementsList = this.mouvementsList.map(item => item).filter(item => item.agentId === this.agentId);
      const currentSections = agentMouvementsList.map(item => item).filter(item => item.type === 0);
      if (currentSections.length) {
        return currentSections[0];
      }

      return {};
    },
    currentStatusSection() {
      const currentSectionsId = get(this.currentStatus, 'siteId', '');
      return get(find(this.sites, { id: currentSectionsId }), 'name', null);
    },
    currentStatusDate() {
      return get(this.currentStatus, 'date', '');
    }
  },
  async mounted() {
    this.currentAgentId = get(this.$route, 'params.id', null);

    if (!this.currentAgentId) {
      this.$router.push(ROUTES_NAME.agents);
    }
    this.get(this.agentId);
    this.getAgentEnterprises();
    this.getAgentProfiles();
    this.fetchAgents();
    this.fetchList();
    this.fetchAgentAccess(this.agentId);
    this.getAllowSiteForAgent(this.agentId);
  },
  methods: {
    ...mapActions('sessionAgents', ['fetchAgents', 'getAnswers']),
    ...mapActions('interviewer', ['fetchList']),
    ...mapActions('agentAccess', ['fetchAgentAccess', 'newAccess', 'changeAccess', 'removeAccess']),
    ...mapActions('site', ['getAllowSiteForAgent']),
    ...mapActions('agents', ['get', 'getAgentEnterprises', 'getAgentProfiles']),
    async showSessionDetails(data) {
      await this.getAnswers(data.id);
      if (data.action) {
        data.action();
      }
    },
    changeAgentAccess(data) {
      const { id, beginDate, endDate } = data;
      this.changeAccess({
        id,
        data: {
          agentId: this.agentId,
          beginDate,
          endDate
        }
      });
    },
    async removeAgentAccess(id) {
      await this.removeAccess(id);
      await this.fetchAgentAccess(this.agentId);
    }
  }
};
</script>

<style lang="scss">
.agents-details-page {
  padding: 0 10px;

  &--header {
    &-name {
      font-size: 36px;
      font-weight: 700;
    }

    margin-bottom: 20px;
  }
}
</style>
