import request from '@/services/helpers/request';
import { API_ROUTES } from '@/config';

export const getForms = async () => (await request.getInstance()).get(API_ROUTES.formManagement.getFormsList);
export const getFormById = async id => (await request.getInstance()).get(API_ROUTES.formManagement.formById.replace(':id', id));
export const changeForm = async (id, data) => (await request.getInstance()).put(API_ROUTES.formManagement.formById.replace(':id', id), data);
export const deleteForm = async id => (await request.getInstance()).delete(API_ROUTES.formManagement.formById.replace(':id', id));
export const addForm = async data => (await request.getInstance()).post(API_ROUTES.formManagement.addForm, data);

export const getQuestions = async () => (await request.getInstance()).get(API_ROUTES.formManagement.questionsList);
export const addQuestion = async data => (await request.getInstance()).post(API_ROUTES.formManagement.createQuestion, data);
export const getQuestionById = async id => (await request.getInstance()).get(API_ROUTES.formManagement.questionById.replace(':id', id));
export const changeQuestion = async (id, data) => (await request.getInstance()).put(API_ROUTES.formManagement.questionById.replace(':id', id), data);
export const deleteQuestion = async id => (await request.getInstance()).delete(API_ROUTES.formManagement.questionById.replace(':id', id));
