// The file importing all language files.

const requireModule = require.context('.', true, /(\.js)$/);

const files = requireModule.keys();

export const { languages, messages } = files.reduce(
  (acc, fileName) => {
    const [, language] = fileName.match(/\/(\w+).js/);

    if (language && language !== 'index') {
      acc.languages[language] = language;
      acc.messages[language] = requireModule(fileName).default;
    }

    return acc;
  },
  { languages: {}, messages: {} }
);
