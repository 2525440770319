var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{key:_vm.accountsKey,staticClass:"accounts-table",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.accounts,"items-per-page":20,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50, -1]
    },"height":"600","single-select":true,"show-select":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header.email",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('TwoArrowSort',{staticClass:"accounts-table--sort-icon"})],1)]}},{key:"header.lastAt",fn:function(ref){
    var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('TwoArrowSort',{staticClass:"accounts-table--sort-icon"})],1)]}},{key:"item.lastConnexion",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastConnexion ? _vm.formatTime(item.lastConnexion) : '')+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }