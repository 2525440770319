<template>
  <div class="accounts-page d-flex align-start flex-column">
    <v-col cols="12" class="accounts-page--control d-flex flex-row">
      <v-col cols="12">
        <Control
          :show-lock="true"
          @add="addNewAccount"
          @remove="removeItemById"
          @edit="editAccount"
          @lock="lockAccount"
          @change="changeRolePermissionForUser"
        />
      </v-col>
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between accounts-page--tables">
      <v-col cols="12" class="accounts-page--accounts">
        <AccountsTable :accountsKey="accountsKey" :accounts="newAccount()" @set-selected-id="selectedAccount = $event" />
      </v-col>
    </v-col>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';
import { formatValues } from '@/mixins/formatValues.mixin';
import { ROUTES_NAME } from '@/common/constants';
import Control from '@/components/sessions/Control';
import AccountsTable from '@/components/common/tables/AccountsTable';

export default {
  name: ROUTES_NAME.accounts,
  components: {
    Control,
    AccountsTable
  },
  mixins: [formatValues],
  data() {
    return {
      selectedAccount: [],
      accountsKey: 0
    };
  },
  computed: {
    ...mapGetters('accounts', ['permissionsToAccount', 'allRolesUserList', 'zoneList', 'allPermissions']),
    ...mapGetters('site', ['selectZone']),
    selectedItem() {
      return this.selectedAccount[0] || {};
    },
    selectedId() {
      return get(this.selectedItem, 'id', null);
    }
  },
  mounted() {
    this.fetchAccounts();
    this.getAccountRolesForTable();
    this.fetchAccess();
    this.fetchSites();
    this.getListPermissionToAccount();
  },
  methods: {
    ...mapActions('accounts', [
      'fetchAccounts',
      'fetchAccess',
      'addNewUser',
      'removeUser',
      'updateUser',
      'changeUserPassword',
      'addNewRoleToAccount',
      'addNewPermissionToAccount',
      'getListPermissionToAccount',
      'getAccountRolesForTable',
      'fetchSites',
      'setChangeStatusModal'
    ]),

    forceRerenderAccount() {
      this.fetchAccounts();
      this.getAccountRolesForTable();
      this.getListPermissionToAccount();
      this.accountsKey += 1;
    },

    editAccount() {
      if (!this.selectedId) {
        return;
      }

      this.$modals.open({
        name: 'AddAccountModal',
        payload: {
          oldValues: {
            email: this.selectedItem.email,
            rolesId: this.selectedItem.rolesId
          },
          roles: this.allPermissions.rolesList,
          submit: data => {
            this.updateUser({ id: this.selectedId, data });
            this.addNewRoleToAccount({ id: this.selectedId, data });
            this.forceRerenderAccount();
          }
        }
      });
    },
    addNewAccount() {
      this.$modals.open({
        name: 'AddAccountModal',
        payload: {
          oldValues: null,
          roles: this.allPermissions.rolesList,
          submit: data => {
            this.addNewUser(data);
            //location.reload()
            setTimeout(() => {
              this.forceRerenderAccount();
            }, 500);
          }
        }
      });
    },
    removeItemById() {
      if (!this.selectedId) {
        return;
      }
      this.$modals.open({
        name: 'RemoveItem',
        payload: {
          text: {
            title: 'Supprimer un utilisateur',
            subTitle: 'Voulez-vous vraiment supprimer cet utilisateur ?'
          },
          submit: () => {
            this.removeUser(this.selectedId);
            //location.reload()
            this.forceRerenderAccount();
          }
        }
      });
    },
    lockAccount() {
      if (!this.selectedId) {
        return;
      }
      this.$modals.open({
        name: 'ChangeAccountPasswordModal',
        payload: {
          user: this.selectedItem,
          submit: data => {
            this.changeUserPassword({ id: this.selectedId, password: data.password });
          }
        }
      });
    },
    sayMyName(id) {
      let heisenberg = '';
      this.allRolesUserList.user.map(item => {
        if (item.id.includes(id)) {
          heisenberg = item.email;
        }
      });
      return heisenberg;
    },
    getRolesForUser(selectedId) {
      if (!selectedId) {
        return [];
      }

      const statusRole = this.allRolesUserList.details;
      const allRolesList = this.allPermissions.rolesList;
      const getRoleFromUser = [];
      const getZoneFromUser = [];

      // Récupération du role de la sélection
      statusRole.forEach(item => {
        if (selectedId === item.userId) {
          getRoleFromUser[getRoleFromUser.length] = item.roleId;
          getZoneFromUser[getZoneFromUser.length] = item.siteId;
        }
      });

      // vérification des roles dejà acquis
      return allRolesList.map(item => {
        let status = false;
        const zoneId = [];

        // Ajout des zones lié aux roles
        if (getRoleFromUser.length > 0) {
          getRoleFromUser.forEach((value, index) => {
            if (value.includes(item.id)) {
              status = true;
              zoneId[zoneId.length] = getZoneFromUser[index];
            }
          });
        }

        return {
          id: item.id,
          description: `${item.name} - ${item.description}`,
          zoneId,
          status
        };
      });
    },
    getPermissionsForUser(selectedId) {
      if (!selectedId) {
        return [];
      }

      const getPermissionsFromUser = [];
      const getZoneFromUser = [];

      // Récupération des permissions de la sélection
      this.permissionsToAccount.forEach(item => {
        if (this.selectedId === item.userId) {
          getPermissionsFromUser[getPermissionsFromUser.length] = item.permissionId;
          getZoneFromUser[getZoneFromUser.length] = item.siteId;
        }
      });

      // vérification des permissions dejà acquises
      return this.allPermissions.permissionList.map(item => {
        let status = false;
        const zoneId = [];

        // Ajout des zones lié aux permissions
        if (getPermissionsFromUser.length > 0) {
          getPermissionsFromUser.forEach((value, index) => {
            if (value.includes(item.id)) {
              status = true;
              zoneId[zoneId.length] = getZoneFromUser[index];
            }
          });
        }

        return {
          id: item.id,
          description: `${item.name} - ${item.description}`,
          zoneId,
          status
        };
      });
    },
    changeRolePermissionForUser() {
      let tempSelected = this.selectedId;
      this.selectedId = '';
      this.$modals.open({
        name: 'ChangeRolePermissionForUser',
        payload: {
          nameUser: this.sayMyName(tempSelected),
          selected: tempSelected,
          roles: this.getRolesForUser(tempSelected),
          permissions: this.getPermissionsForUser(tempSelected),
          permissionByRole: this.allPermissions.rolesPermissionsList,
          submit: ({ userId, roles, permissions, siteId }) => this.submitNewRolesPermissionsAccount(userId, roles, permissions, siteId)
        }
      });
    },
    submitNewRolesPermissionsAccount(userId, roles, permissions, siteId) {
      this.addNewRoleToAccount({ userId, role: { roles }, siteId });
      this.addNewPermissionToAccount({ userId, permission: { permissions }, siteId });
      //location.reload();
      setTimeout(() => {
        this.forceRerenderAccount();
      }, 500);
    },
    newAccount() {
      const listRolesUser = this.allRolesUserList.details;
      const allAccounts = this.allRolesUserList.user;
      const allRolesList = this.allPermissions.rolesList;
      // map insycrone
      return allAccounts?.map(item => {
        // préparation pour la reception  des données Role et Zone
        let rolesId = [];
        let zoneId = [];

        // récupération du Role et zone appartenant à l'utilisateur
        if (listRolesUser.length) {
          listRolesUser?.map(element => {
            if (element.userId === item.id) {
              rolesId[rolesId.length] = allRolesList?.find(value => value.id === element?.roleId);
              zoneId[zoneId.length] = this.zoneList?.find(value => value.id === element?.siteId);
            }
          });
        }

        // récupère le nom du role et la zone
        let nameRoles = rolesId?.map(element => ` ${element?.description}`);
        let nameZone = zoneId?.map(element => ` Zone ${element?.name}`);

        return {
          id: item.id,
          email: item.email,
          lastConnexion: item.lastConnexion,
          rolesId: nameRoles ? nameRoles : '',
          zoneId: nameZone ? nameZone : ''
        };
      });
    }
  }
};
</script>

<style lang="scss">
.accounts-page {
  tr,
  td {
    height: 48px !important;
    max-height: 48px !important;
  }

  &--control {
    padding-left: 24px;
    padding-right: 24px;
  }

  &--tables {
    margin-top: -10px;
    padding-top: 0px;
  }
}
</style>
