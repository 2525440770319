<template>
  <div>
    <Control @add="addTheme" @remove="removeThemeById" @edit="editThemeById" />
    <v-data-table
      v-model="selected"
      fixed-header
      :headers="headers"
      :items="themes"
      height="600"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      }"
      :single-select="true"
      show-select
      class="themes-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:header.label="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="themes-table--sort-icon" />
        </div>
      </template>

      <template v-slot:header.createdAt="{ header }">
        <div class="d-flex justify-space-between align-center">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="themes-table--sort-icon" />
        </div>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ formatTime(item.createdAt) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Control from '@/components/sessions/Control';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';
import { formatValues } from '@/mixins/formatValues.mixin';

export default {
  name: 'ThemesTable',
  components: {
    Control,
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    themes: {
      type: Array,
      default: () => [
        {
          date: '1',
          label: 'Test theme'
        }
      ]
    }
  },
  data() {
    return {
      selected: [],
      sortBy: ['label'],
      sortDesc: false
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'Themes',
          divider: true,
          align: 'start',
          sortable: true,
          value: 'label'
        },
        {
          text: 'Cree lee',
          divider: true,
          align: 'start',
          sortable: true,
          value: 'createdAt'
        }
      ];
    }
  },
  methods: {
    addTheme() {
      this.$modals.open({
        name: 'ThemesModal',
        payload: {
          submit: data => {
            this.$emit('add', data);
          }
        }
      });
    },
    editThemeById() {
      if (!this.selected.length) {
        return;
      }
      this.$modals.open({
        name: 'ThemesModal',
        payload: {
          oldValues: {
            label: this.selected[0].label,
            id: this.selected[0].id
          },
          submit: data => {
            this.$emit('edit', { id: this.selected[0].id, data });
            this.selected = [];
          }
        }
      });
    },
    removeThemeById() {
      if (!this.selected.length) {
        return;
      }

      this.$modals.open({
        name: 'RemoveThemeModal',
        payload: {
          submit: () => {
            this.$emit('remove', this.selected[0].id);
            this.selected = [];
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.themes-control-icon {
  padding: 0 4px;
}

.themes-table {
  .v-data-table-header__icon {
    display: none;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
