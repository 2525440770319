<template>
  <div class="sessions-page">
    <SessionsTable v-if="sessions" :sessions="sessions" :forms="forms" @set-session="setSessionId" />
  </div>
</template>



<script>
import { mapGetters, mapActions } from 'vuex';
import { ROUTES_NAME } from '@/common/constants';
import SessionsTable from '@/components/common/tables/SessionsTable';

export default {
  name: ROUTES_NAME.sessions,

  components: {
    SessionsTable
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters('form', ['forms']),
    ...mapGetters('sessions', ['sessions', 'errors']),
    errorText() {
      return this.errors ? 'auth.errorMessage' : '';
    },
  },

  async mounted() {
    this.toggleLoader();
    await this.fetchSessions();
    this.toggleLoader();
    this.fetchForms();
  },

  methods: {
    ...mapActions('form', ['fetchForms']),
    ...mapActions('ui', ['toggleLoader']),
    ...mapActions('sessions', ['fetchSessions']),

    setSessionId(session) {
      this.$router.push({ name: ROUTES_NAME.sessionDetails, params: { session, id: session.id } });
    },
  }
};
</script>



<style lang="scss">
.sessions-page {
  padding: 0 10px;
}
</style>
