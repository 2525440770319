export default {
  roles: [],
  permissions: [],
  accounts: [],
  errors: null,
  allPermissions: [],
  permissionsToAccount: [],
  allRolesUserList: [],
  zoneList: [],
  changeRoles: [],
  changePermission: [],
  permissionForTable: [],
  changeModal: null
};
