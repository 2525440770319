<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="quiz-detail-modal">
      <div class="quiz-detail-modal--header font-weight-light">Détail - Questionnaire du {{ formatTime(date) }}</div>
      <div class="quiz-detail-modal--content">
        <v-data-table :headers="headers" :items="answers" hide-default-footer disable-pagination>
          <template v-slot:item.answer.answer="{ item }">
            <div v-if="item.type == 0">
              <v-checkbox
                v-for="(ans, key) in getCheckbox(item.answer.answer)"
                :key="key"
                class="quiz-detail-modal--content-item-checkbox"
                :class="'checkbox-' + getCheckboxColor(ans, item.goodAnswers, item.badAnswers)"
                :input-value="true"
                :disabled="true"
                :label="ans"
              >
              </v-checkbox>
            </div>
            <div v-else>
              {{ item.answer.answer }}
            </div>
          </template>

          <template v-slot:item.answer.result="{ item }">
            <span class="quiz-detail-modal--content-item-result" :class="setStatusClass(item.answer.result, item.maxPoint)"> {{ item.answer.result }}</span>
          </template>
        </v-data-table>
      </div>
      <div class="quiz-detail-modal--buttons d-flex justify-end">
        <v-btn class="quiz-detail-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="quiz-detail-modal--buttons-save" color="#38BB00" dark depressed @click="close">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import { get } from 'lodash';

import { formatValues } from '@/mixins/formatValues.mixin';
import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'SessionDetailModal',
  components: {
    BasePopupModal
  },
  mixins: [formatValues],
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {};
  },
  computed: {
    answers() {
      return get(this.modal.payload, 'answers', []);
    },
    date() {
      return get(this.modal.payload, 'date', null);
    },
    headers() {
      return [
        {
          text: 'N°',
          divider: true,
          align: 'center',
          sortable: true,
          width: '5%',
          value: 'position'
        },
        {
          text: 'Réponses',
          divider: true,
          align: 'start',
          width: '90%',
          value: 'answer.answer'
        },
        {
          text: 'Nb de points',
          divider: true,
          align: 'center',
          width: '5%',
          value: 'answer.result'
        }
      ];
    }
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    getCheckbox(answer) {
      return answer.split(',').sort();
    },
    getCheckboxColor(answer, goodAnswers, badAnswers) {
      if (goodAnswers.includes(answer)) {
        return 'green';
      }

      if (badAnswers.includes(answer)) {
        return 'red';
      }

      return 'orange';
    },
    getStatusIndex(result, maxPoint) {
      if (result === maxPoint) {
        return 2;
      }

      if (result < maxPoint && result > 0) {
        return 1;
      }

      return 0;
    },

    setStatusClass(result, maxPoint) {
      const statusClass = [
        'quiz-detail-modal--content-item-result-denied',
        'quiz-detail-modal--content-item-result-interview',
        'quiz-detail-modal--content-item-result-correct'
      ];

      return statusClass[this.getStatusIndex(result, maxPoint)];
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
.quiz-detail-modal {
  &--content {
    text-align: center;
    margin-top: 10px;
    padding: 25px;
    height: 436px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;
    overflow: scroll;

    &-item {
      &-checkbox {
        float: left;
        margin-left: 10px;

        &.checkbox-orange {
          i,
          label {
            color: #f9cb9c !important;
          }
        }
        &.checkbox-red {
          i,
          label {
            color: #ff7c7c !important;
          }
        }
        &.checkbox-green {
          i,
          label {
            color: #3dd598 !important;
          }
        }
      }

      &-result {
        font-weight: 700;
        font-size: 20px !important;

        &-denied {
          color: #ff7c7c;
        }

        &-interview {
          color: #f9cb9c;
        }

        &-correct {
          color: #3dd598;
        }
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
