import * as types from './mutation-types';

import { fetchAllBannedIp, fetchAllWhitedIp, removeBannedIP } from '@/services/bannedIP.service';

export default {
  async fetchBannedList({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAllBannedIp();
      commit(types.SET_BANNEDLIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async fetchWhitedList({ commit }) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await fetchAllWhitedIp();
      commit(types.SET_WHITEDLIST, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async getBannedIP({ commit }, address) {
    commit(types.SET_ERRORS, null);
    try {
      const data = await getBannedIP(address);
      commit(types.SET_BANNEDIP, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async addNewBannedIP({ commit, dispatch }, data) {
    try {
      await addNewBannedIP(data);
      dispatch('fetchAccounts');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async removeItem({ commit, dispatch }, id) {
    commit(types.SET_ERRORS, null);
    try {
      await removeBannedIP(id);
      dispatch('fetchBannedList');
      dispatch('fetchWhitedList');
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  },

  async setChangeStatusModal({ commit }, data) {
    commit(types.SET_ERRORS, null);
    try {
      commit(types.SET_CHANGE_MODAL, data);
    } catch ({ message }) {
      commit(types.SET_ERRORS, true);
    }
  }
};
