<template>
  <div class="validation-block" :class="colorStatus">
    <div class="validation-block--title">{{ agent.lastname }} {{ agent.firstname }}</div>
    <div class="validation-block--title">{{ agent.enterpriseName }} - {{ agent.agentProfileName }}</div>
    <div v-if="sessionState === 2" class="validation-block--sub-title">Note: {{ resultScore.toFixed(2) }}</div>
    <div class="validation-block--content font-weight-bold">
      <v-radio-group
        v-if="sessionState === 2"
        v-model="validation"
        row
        class="d-flex align-center"
        @change="$emit('changeValidationStatus', { id: agent.id, validation })"
      >
        <v-radio v-for="n in [2, 1, 0]" :key="n" :label="statusText[n]" :value="n"></v-radio>
      </v-radio-group>
      <div v-if="sessionState === 3">
        <div class="validation-block--content-state">
          {{ resultStatus }}
        </div>
        <div class="validation-block--content-result">
          <span class="validation-block--content-title">Note: </span>
          <span class="validation-block--content-value">
            {{ Number.isInteger(parseFloat(resultScore.toFixed(1))) ? resultScore.toFixed(0) : resultScore.toFixed(1) }}
          </span>
        </div>
        <div v-if="resultComment" class="validation-block--content-comment">
          <span class="validation-block--content-title">Commentaire session: </span>
          <span class="validation-block--content-value">{{ resultComment }}</span>
        </div>
        <div v-if="agent.result === 1" class="validation-block--content-date">
          <span class="validation-block--content-title">{{ interviewText }}: </span>
          <span class="validation-block--content-value">{{ interviewDate }}</span>
        </div>
      </div>
    </div>
    <div v-if="isShowComments" class="validation-block--control-buttons d-flex justify-space-between align-center">
      <!-- <div class="validation-block--control-buttons-text">
        Commentaire
      </div> -->
      <div class="validation-block--control-buttons-input">
        <v-text-field v-model="comment" label="Ajouter un commentaire..." height="30px" solo @input="$emit('addComment', { id: agent.id, comment })"></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { get } from 'lodash';

export default {
  name: 'ValidationCard',
  props: {
    agent: {
      type: Object,
      default: () => {}
    },
    finalScoreStatus: {
      type: Number,
      default: 0
    },
    agentComment: {
      type: String,
      default: null
    },
    sessionState: {
      type: Number,
      default: 3
    },
    result: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      validation: this.finalScoreStatus,
      statusText: ["Refusé", "Entretien", "Validé"],
      comment: ''
    };
  },
  computed: {
    colorStatus() {
      let status = 'denied';

      if (this.validation === 1) {
        status = 'interview';
      }

      if (this.validation === 2) {
        status = 'validate';
      }

      return `validation-block--${status}`;
    },
    isShowComments() {
      return this.sessionState === 2;
    },
    resultComment() {
      return this.agent.comment;
    },
    interviewText() {
      if (get(this.agent, 'interview', null) === null || get(this.agent, 'interview.result', null) === null) {
        return "Date de l'entretien";
      }

      return get(this.agent, 'interview.result', 0) === 0 ? 'Accès réfusé après entretien le' : 'Accès validé après entretien le';
    },
    interviewDate() {
      if (get(this.agent, 'interview', null) === null || get(this.agent, 'interview.result', null) === null) {
        return 'Entretien non réalisé';
      }

      return moment(get(this.agent, 'interview.closeInterviewDate', null)).format('DD/MM/YYYY');
    },
    resultScore() {
      return this.agent.finalScore ? this.agent.finalScore : 0;
    },
    resultStatus() {
      const textResultStatus = ['Accès refusé', 'Entretien', 'Accès obtenu'];

      return textResultStatus[this.agent.result];
    }
  },
  watch: {
    sessionState() {
      this.changeValidationStatus();
    },
    result() {
      this.changeValidationStatus();
    },
    finalScoreStatus() {
      this.changeValidationStatus();
    },
    agent() {}
  },
  mounted() {
    this.changeValidationStatus();

    if (this.agentComment) {
      this.comment = this.agentComment;
    }
  },
  methods: {
    changeValidationStatus() {
      if (this.result !== null) {
        this.validation = this.result;
      } else {
        this.validation = this.finalScoreStatus;
      }
    }
  }
};
</script>

<style lang="scss">
.validation-block {
  position: relative;
  width: 100%;
  margin: 20px 0 0 0 !important;
  height: 100%;

  &--title {
    padding-top: 12px;
  }

  &--sub-title {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
  }

  &--content {
    font-size: 20px;

    .v-input--selection-controls__input {
      .v-icon {
        color: #000000 !important;
      }
    }

    .theme--light.v-radio--is-disabled {
      .v-icon {
        color: #000000 !important;
      }

      label {
        color: #000000 !important;
      }
    }

    &-state {
    }

    &-result {
      margin-top: 14px;
    }

    &-result,
    &-comment,
    &-date {
      font-size: 12px;
      font-weight: 400;
    }
  }

  &--title,
  &--sub-title,
  &--content {
    padding-left: 12px;
    padding-right: 12px;
  }

  &--validate {
    background-color: #b6d7a8;
  }

  &--interview {
    background-color: #f9cb9c;
  }

  &--denied {
    background-color: #ea9999;
  }

  &--control-buttons {
    padding-top: 10px;

    // &-text {
    //   padding-left: 20px;
    // }

    &-input {
      padding-left: 20px;
      padding-right: 20px;
    }

    .v-text-field.v-text-field--solo .v-input__control {
      height: 30px !important;
      max-height: 30px !important;
      min-height: 30px !important;
    }
  }
}
</style>
