<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="accounts-modal">
      <div class="accounts-modal--header font-weight-light">
        Ajouter un rôle
      </div>
      <div class="accounts-modal--content">
        <div class="accounts-modal--content-input d-flex justify-center">
          <v-form class="accounts-modal--content-input-form">
            <v-container>
              <v-text-field v-model="name" :label="'Nom du nouveau rôle'" type="text" outline> </v-text-field>
            </v-container>
          </v-form>
        </div>
      </div>
      <div class="accounts-modal--buttons d-flex justify-end">
        <v-btn class="accounts-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="accounts-modal--buttons-save" color="#38BB00" dark depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'AddRoleModal',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      name: '',
      description: ''
    };
  },
  computed: {
    oldValues() {
      return this.modal.payload.oldValues;
    },
    roles() {
      return this.modal.payload.roles;
    }
  },
  mounted() {
    if (this.oldValues) {
      this.formData.email = this.oldValues.email;
      const oldRoles = this.oldValues.rolesId;

      if (oldRoles.length) {
        this.formData.roleId = oldRoles;
      }
    }
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        const description = this.upperCaseDescription();
        await this.modal.payload.submit({
          name: this.name.toLowerCase(),
          description
        });
        this.close();
      }
      window.location.reload();
    },
    upperCaseDescription() {
      const String = this.name.toLowerCase();
      return String[0].toUpperCase() + String.slice(1);
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.accounts-modal {
  &--header {
    text-align: center;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 436px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
