import moment from 'moment';

import { STATE_VALUE } from '@/common/constants';

export const formatValues = {
  methods: {
    formatTime(date) {
      return moment(date)
        .utcOffset(60)
        .format('DD/MM/YYYY');
    },
    formatDateHourMinute(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    setState(stateCode) {
      return STATE_VALUE[stateCode];
    }
  }
};
