<template>
  <div>
    <Control :is-export="true" @add="addItem" @remove="removeItems" @edit="editItem" @export="exportData" />
    <v-data-table
      v-model="selected"
      fixed-header
      :headers="headers"
      :items="agentsList"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      }"
      :single-select="false"
      show-select
      height="600"
      class="agents-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:header.firstname="{ header }">
        <div class="d-flex justify-space-between align-center squeezeHeader">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="agents-table--sort-icon" />
        </div>
      </template>

      <template v-slot:header.lastname="{ header }">
        <div class="d-flex justify-space-between align-center squeezeHeader">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="agents-table--sort-icon" />
        </div>
      </template>

      <template v-slot:header.profileName="{ header }">
        <div class="d-flex justify-space-between align-center squeezeHeader">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="agents-table--sort-icon" />
        </div>
      </template>

      <template v-slot:header.enterpriseName="{ header }">
        <div class="d-flex justify-space-between align-center squeezeHeader">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="agents-table--sort-icon" />
        </div>
      </template>

      <template v-slot:header.badge="{ header }">
        <div class="d-flex justify-space-between align-center squeezeHeader">
          <span>{{ header.text }}</span>
          <TwoArrowSort class="agents-table--sort-icon" />
        </div>
      </template>

      <template v-slot:item.accessBeginDate="{ item }">
        <span> {{ statusText(item) }}</span>
      </template>

      <template v-slot:item.accessEndDate="{ item }">
        <span v-if="item.accessEndDate">{{ formatTime(item.accessEndDate) }}</span>
      </template>

      <template v-slot:item.actualSiteId="{ item }">
        <v-checkbox v-model="item.actualSiteId" color="#3dd598" :disabled="true"></v-checkbox>
      </template>

      <template v-slot:item.badge="{ item }">
        <!-- <v-checkbox v-if="!item.badge" v-model="item.badge" color="#3dd598" :disabled="true"></v-checkbox> -->
        <span> {{ item.badge }}</span>
      </template>

      <template v-slot:item.detail="{ item }">
        <router-link :tag="'a'" :to="{ name: 'AgentsDetails', params: { id: item.id } }">
          Détail
        </router-link>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { json2excel } from 'js2excel';

import { formatValues } from '@/mixins/formatValues.mixin';
import Control from '@/components/sessions/Control';
import TwoArrowSort from '@/components/common/icons/TwoArrowSort';

export default {
  name: 'AgentsTable',
  components: {
    Control,
    TwoArrowSort
  },
  mixins: [formatValues],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    profiles: {
      type: Array,
      default: () => []
    },
    enterprises: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      selected: [],
      date: new Date().toISOString().slice(0, 10),
      sortBy: ['lastname', 'firstname'],
      sortDesc: false
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Nom",
          divider: true,
          align: 'start',
          sortable: true,
          width: '14%',
          value: 'lastname'
        },
        {
          text: "Prénom",
          divider: true,
          align: 'start',
          sortable: true,
          width: '12%',
          value: 'firstname'
        },
        {
          text: "Entreprise",
          divider: true,
          align: 'start',
          sortable: true,
          width: '14%',
          value: 'enterpriseName'
        },
        {
          text: "Profil",
          divider: true,
          align: 'start',
          sortable: true,
          width: '17%',
          value: 'profileName'
        },
        {
          text: "Accès",
          divider: true,
          align: 'start',
          sortable: true,
          width: '15%',
          value: 'accessBeginDate'
        },
        {
          text: "Fin de validité",
          divider: true,
          align: 'start',
          sortable: true,
          width: '8%',
          value: 'accessEndDate'
        },
        {
          text: "Présent sur site",
          divider: true,
          align: 'start',
          sortable: true,
          width: '5%',
          value: 'actualSiteId'
        },
        {
          text: "Badge",
          divider: true,
          align: 'start',
          sortable: true,
          width: '10%',
          value: 'badge'
        },
        {
          text: "",
          divider: true,
          align: 'start',
          width: '5%',
          sortable: false,
          value: 'detail'
        }
      ];
    },
    agentsList() {
      return this.data.map(item => ({
        ...item
      }));
    },
    selectedUsers() {
      return this.selected || [];
    },
    selectedIds() {
      return this.selectedUsers.map(user => user.id);
    }
  },
  methods: {
    removeItems() {
      const number = this.selectedIds.length;

      // Aucun agent sélectionné, on ne fait rien
      if (!this.selectedIds || number === 0) {
        return;
      }

      // Change le message selon si singulier ou pluriel
      var text = {};
      if (number === 1) {
        text = {
          title: 'Attention',
          subTitle: 'Voulez-vous vraiment supprimer cet agent ?'
        };
      } else {
        text = {
          title: `Attention`,
          subTitle: `Voulez-vous vraiment supprimer ces ${number} agents ?`
        };
      }

      // Pop-up de confirmation
      this.$modals.open({
        name: 'RemoveItem',
        payload: {
          text: text,
          submit: () => {
            this.$emit('remove', this.selectedIds);
          }
        }
      });
    },
    editItem() {
      // No user selected
      if (!this.selectedIds || this.selectedIds.length !== 1) {
        return;
      }

      this.$modals.open({
        name: 'ChangeAgentModal',
        payload: {
          profiles: this.profiles,
          enterprises: this.enterprises,
          oldValues: {
            firstname: this.selectedUsers[0].firstname,
            lastname: this.selectedUsers[0].lastname,
            profile: this.selectedUsers[0].profileName,
            enterprise: this.selectedUsers[0].enterpriseName,
            badge: this.selectedUsers[0].badge || '',
            isLimitedAccess: this.selectedUsers[0].isLimitedAccess
          },
          submit: data => {
            this.$emit('edit', { id: this.selectedIds[0], data });
            this.selected = [];
          }
        }
      });
    },
    addItem() {
      this.$modals.open({
        name: 'ChangeAgentModal',
        payload: {
          profiles: this.profiles,
          enterprises: this.enterprises,
          oldValues: null,
          submit: data => {
            this.$emit('add', data);
          }
        }
      });
    },
    isAccessValid(date) {
      return new Date(this.date) < new Date(date);
    },
    statusText(item) {
      if (this.isAccessValid(item.accessEndDate)) {
        return 'Autorisé';
      }

      if (item.session) {
        if (item.session.result === null) {
          return 'Accueil à venir';
        }

        if (item.session.result === 0) {
          return "Accès refusé (accueil)";
        }

        if (item.session.result === 1) {
          if (item.session.Interview === null || item.session.Interview.closeInterviewDate === null) {
            return 'Entretien à venir';
          }

          if (item.session.Interview.result === 0) {
            return "Accès refusé (entretien)";
          }
        }
      }

      if (item.accessEndDate !== null) {
        return 'Expiré';
      }

      return '';
    },
    exportData() {
      const agentsList = this.data.map(item => {
        const agent = [
          ["Nom", item.lastname],
          ["Prénom", item.firstname],
          ["Entreprise", item.enterpriseName],
          ["Profil", item.profileName],
          ["Accès", item.accessBeginDate],
          ["Fin de validité", item.accessEndDate],
          ["Statut", item.accessStatus],
          ["Badge", item.badge]
        ];
        return Object.fromEntries(agent);
      });

      try {
        json2excel({
          data: agentsList,
          name: 'agents-list'
        });
      } catch (e) {
        console.error('export error');
      }
    }
  }
};
</script>

<style lang="scss">
.sessions-control-icon {
  padding: 0 4px;
}

.squeezeHeader {
  margin-left: -4px;
  margin-right: -10px;
}

.agents-table {
  .v-data-table-header__icon {
    display: none;
  }

  a {
    color: #0094ff !important;
    text-decoration: underline;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  &--sort-icon {
    min-width: 24px;
    min-height: 24px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  td:nth-child(1) {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  tr:last-child {
    td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }

  thead {
    th {
      background-color: white;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }

    th:nth-child(1) {
      border-left: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
