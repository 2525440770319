<template>
  <div class="main-header d-flex justify-end align-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div class="zone-select">
          <ZoneSelectTable v-if="showZone" :global-zone="true" />
        </div>
        <v-btn rounded v-bind="attrs" class="mr-2" v-on="on">
          <v-icon dense>
            mdi-account
          </v-icon>
          {{ user && user.email ? user.email : 'Compte' }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <div class="main-header--profile font-weight-bold" @click="changePassword">
              Changer mon mot de passe
            </div></v-list-item-title
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <div class="main-header--profile font-weight-bold" @click="logout">
              Déconnexion
            </div></v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ZoneSelectTable from '@/components/common/tables/ZoneSelectTable';

export default {
  name: 'Header',
  components: {
    ZoneSelectTable
  },
  data() {
    return {
      showZone: Boolean,
      routeZone: this.$route.name
    };
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  watch: {
    $route(newRoute) {
      this.routeZone = newRoute.name;
      this.routeShowZone(this.routeZone);
    }
  },
  mounted() {
    this.routeShowZone(this.$route.name);
  },
  methods: {
    changePassword() {
      this.$modals.open({
        name: 'ChangePasswordModal',
        payload: {
          text: {}
        }
      });
    },
    ...mapActions('auth', ['logout']),
    routeShowZone(RouteName) {
      if (RouteName === 'Accounts' || RouteName === 'Sites' || RouteName === 'Roles') {
        this.showZone = false;
      } else {
        this.showZone = true;
      }
    }
  }
};
</script>

<style lang="scss">
.zone-select {
  margin-right: 2rem;
}

.main-header {
  width: 100%;
  height: 73px;
  border-bottom: 1px solid #e4e6e8;
  margin-bottom: 20px;

  &--profile {
    padding-right: 24px;
    color: #303030;
    cursor: pointer;
  }
}
</style>
