<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="accounts-modal">
      <div class="accounts-modal--header font-weight-light">
        Mise à jour du mot de passe
      </div>
      <div class="accounts-modal--content">
        <div class="accounts-modal--content-input d-flex justify-center">
          <v-form class="accounts-modal--content-input-form">
            <v-container>
              <v-text-field v-model="email" :label="'Email'" :disabled="true" type="email" outline> </v-text-field>
              <v-text-field
                v-model="formData.password"
                :label="'Le mot de passe'"
                :rules="passwordRules"
                :disabled="type === 0"
                type="password"
                outline
              >
              </v-text-field>
              <v-text-field
                v-model="formData.password2"
                :label="'Répéter le mot de passe'"
                :disabled="type === 0"
                :rules="passwordRules2"
                type="password"
                outline
              >
              </v-text-field>
            </v-container>
          </v-form>
        </div>
      </div>
      <div class="accounts-modal--buttons d-flex justify-end">
        <v-btn class="accounts-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="accounts-modal--buttons-save" color="#38BB00" dark depressed :disabled="isDisabled" @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import BasePopupModal from '@/components/modals/base/BasePopupModal';

export default {
  name: 'ChangeAccountPasswordModal',
  components: {
    BasePopupModal
  },
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        password: '',
        password2: ''
      }
    };
  },
  computed: {
    email() {
      return this.modal.payload.user.email;
    },
    passwordRules() {
      return [
        v => v.length > 5 || 'Le mot de passe doit contenir au moins 6 caractères',
        v => v.length <= 30 || 'Le mot de passe ne doit pas dépasser les 30 caractères',
        v => !v || /^[a-zA-Z0-9]{6,30}$/.test(v) || 'Le mot de passe ne doit contenir que des chiffres ou lettres'
      ];
    },
    passwordRules2() {
      return [v => v === this.formData.password || 'Mots de passe non identiques'];
    },
    isDisabled() {
      const disabled =
        this.formData.password.length > 5 &&
        this.formData.password.length <= 30 &&
        /^[a-zA-Z0-9]{6,30}$/.test(this.formData.password) &&
        this.formData.password === this.formData.password2;
      return !disabled;
    }
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit({
          password: this.formData.password
        });
      }
      this.close();
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.accounts-modal {
  &--header {
    text-align: center;
  }

  .v-input--selection-controls__input {
    .v-icon {
      color: #3dd598 !important;
    }
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: 436px;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>
