import * as types from './mutation-types';

import { authRequests } from '@/services';
import { saveToken, clearToken, getToken } from '@/services/helpers/token';

export default {
  async login({ commit }, formData) {
    commit(types.SET_ERRORS, null);
    try {
      const { user, token, site } = await authRequests.login(formData);
      saveToken({ token });
      commit(types.SET_USER, user);
      localStorage.setItem('siteId', `${site}`);
      if (new URL(window.location).searchParams.has('redirect')) {
        window.location.href = `${new URL(window.location).searchParams.get('redirect')}`;
      } else {
        window.location.href = '/';
      }
    } catch (message) {
      commit(types.SET_ERRORS, message);
    }
  },

  // async extractRoles({ commit, state }) {
  //   const jwt = getToken().accessToken;
  //   if (jwt) {
  //     commit(types.SET_ROLES, state.roles);
  //   }
  // },

  async extractRoles({ commit }) {
    const jwt = getToken().accessToken;
    if (jwt) {
      const user = JSON.parse(atob(jwt.split('.')[1]));
      commit(types.SET_ROLES, user.roles);
      commit(types.SET_USER, { email: user.email, id: user.id });
    }
  },

  async logout({ commit }) {
    clearToken();
    commit(types.SET_USER, null);
    window.location.href = '/';
  }
};
