export default {
  addModal({ commit }, { name, payload, id } = {}) {
    commit('ADD_MODAL', {
      id,
      name,
      payload
    });
  },
  removeModal({ commit }, id) {
    if (!id) {
      throw new Error('Missed required parameter');
    }

    commit('REMOVE_MODAL_BY_ID', id);
  },
  removeAllModals({ commit }) {
    commit('REMOVE_ALL_MODALS');
  }
};
