<template>
  <div class="accounts-page d-flex align-start flex-column">
    <v-col cols="12" class="accounts-page--control d-flex flex-row">
      <v-col cols="12">
        <Control :show-lock="false" :show-add="false" :show-edit="false" :show-role="false" @remove="removeItemById" />
      </v-col>
    </v-col>
    <div class="access-page">
      <v-card>
        <v-tabs v-model="tab">
          <v-tab href="#bannedIP">IP bannies</v-tab>
          <v-tab href="#whitedIP">IP autorisées</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="bannedIP">
            <BanIPTable :listAddress="bannedList" @set-selected-id="selectedAddress = $event" />
          </v-tab-item>
          <v-tab-item value="whitedIP">
            <WhiteIPTable :listAddress="whitedList" @set-selected-id="selectedAddress = $event" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';
import { formatValues } from '@/mixins/formatValues.mixin';
import { ROUTES_NAME } from '@/common/constants';
import Control from '@/components/sessions/Control';
import BanIPTable from '@/components/common/tables/BanIPTable';
import WhiteIPTable from '@/components/common/tables/WhiteIPTable';

export default {
  name: ROUTES_NAME.banList,
  components: {
    Control,
    BanIPTable,
    WhiteIPTable
  },
  mixins: [formatValues],
  data() {
    return {
      selectedAddress: [],
      tab: null
    };
  },
  computed: {
    ...mapGetters('bannedIP', ['bannedIP', 'bannedList', 'whitedList']),
    selectedItem() {
      return this.selectedAddress[0] || {};
    },
    selectedId() {
      return get(this.selectedItem, 'id', null);
    }
  },

  mounted() {
    this.fetchBannedList();
    this.fetchWhitedList();
    this.removeItem();
  },
  methods: {
    ...mapActions('bannedIP', ['fetchBannedList', 'fetchWhitedList', 'removeItem']),

    removeItemById() {
      if (!this.selectedId) {
        return;
      }
      this.$modals.open({
        name: 'RemoveItem',
        payload: {
          text: {
            title: 'Lever le ban',
            subTitle: 'Voulez-vous vraiment supprimer cette addresse IP de la liste ?'
          },
          submit: () => {
            this.removeItem(this.selectedId);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.accounts-page {
  tr,
  td {
    height: 48px !important;
    max-height: 48px !important;
  }

  &--control {
    padding-left: 24px;
    padding-right: 24px;
  }

  &--tables {
    margin-top: -10px;
    padding-top: 0px;
  }
}

.access-page {
  padding: 0 25px;
  width: 100%;
}
</style>
