import * as types from './mutation-types';

export default {
  [types.SET_LOGS](state, logs) {
    state.logs = logs;
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  }
};
