export default {
  modals: {
    section: {
      new: {
        title: 'Nouvelle zone'
      },
      edit: {
        title: 'Modifier la zone'
      }
    },
    access: {
      new: {
        title: "Ajout d'un nouvel accès"
      },
      edit: {
        title: "Modifier l'accès"
      }
    },
    sessions: {
      new: {
        title: 'Créer une session'
      },
      edit: {
        title: "Modification d'une session"
      }
    },
    questionsTheme: {
      new: {
        title: 'Ajouter un nouveau thème'
      },
      edit: {
        title: 'Modifier le thème'
      },
    },
    accounts: {
      new: {
        title: 'Ajouter un compte'
      },
      edit: {
        title: 'Modification de compte',
      },
    },
    agents: {
      new: {
        title: "Ajout d'un agent"
      },
      edit: {
        title: "Modification de l'agent"
      }
    },
  },
  tables: {
    profile: {
      main: {
        name: "Profil d'agent",
        date: 'Crée le'
      }
    },
    form: {
      main: {
        name: 'Formulaire',
        date: 'Créé le'
      }
    },
  },
};
